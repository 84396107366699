import { atom, useAtom } from "jotai";
import { SetStoreValueAndFunction } from "../../domain/store";
import { Department } from "../../domain/attendant";
import { Message } from "./attendance.store";

const steps = [
  "SELECT_DEPARTMENT",
  "IN_QUEUE",
  "WAITING_FOR_ATTENDANT",
  "IN_CHAT",
  "RATE",
] as const;

export type Step = (typeof steps)[number];

type Question = {
  id: number;
  question: string;
  rate: number;
};

type StoreValues = {
  currentStep: Step;
  department: Department | null;
  sessionId: string | null;
  queuePosition: number | null;
  messages: Message[];
  questions: Question[];
  ticketId: number | null;
  loading: boolean;
  unviewedMessages: number;
};

const initialStoreValues: StoreValues = {
  currentStep: "SELECT_DEPARTMENT",
  department: null,
  sessionId: null,
  queuePosition: null,
  messages: [],
  questions: [],
  ticketId: null,
  loading: true,
  unviewedMessages: 0,
};

const currentStepAtom = atom<StoreValues["currentStep"]>(
  initialStoreValues.currentStep,
);
const departmentAtom = atom<StoreValues["department"]>(
  initialStoreValues.department,
);
const sessionIdAtom = atom<StoreValues["sessionId"]>(
  initialStoreValues.sessionId,
);
const queuePositionAtom = atom<StoreValues["queuePosition"]>(
  initialStoreValues.queuePosition,
);
const messagesAtom = atom<StoreValues["messages"]>(initialStoreValues.messages);
const questionsAtom = atom<StoreValues["questions"]>(
  initialStoreValues.questions,
);
const ticketIdAtom = atom<StoreValues["ticketId"]>(initialStoreValues.ticketId);
const loadingAtom = atom<StoreValues["loading"]>(initialStoreValues.loading);
const unviewedMessagesAtom = atom<StoreValues["unviewedMessages"]>(
  initialStoreValues.unviewedMessages,
);

const setCurrentStepAtom = atom(
  null,
  (_, set, newStep: SetStoreValueAndFunction<StoreValues["currentStep"]>) =>
    set(currentStepAtom, newStep),
);
const setDepartmentAtom = atom(
  null,
  (
    _,
    set,
    newDepartment: SetStoreValueAndFunction<StoreValues["department"]>,
  ) => set(departmentAtom, newDepartment),
);
const setSessionIdAtom = atom(
  null,
  (_, set, newSessionId: SetStoreValueAndFunction<StoreValues["sessionId"]>) =>
    set(sessionIdAtom, newSessionId),
);
const setQueuePositionAtom = atom(
  null,
  (
    _,
    set,
    newQueuePosition: SetStoreValueAndFunction<StoreValues["queuePosition"]>,
  ) => set(queuePositionAtom, newQueuePosition),
);
const setMessagesAtom = atom(
  null,
  (_, set, newMessages: SetStoreValueAndFunction<StoreValues["messages"]>) =>
    set(messagesAtom, newMessages),
);
const setQuestionsAtom = atom(
  null,
  (_, set, newQuestions: SetStoreValueAndFunction<StoreValues["questions"]>) =>
    set(questionsAtom, newQuestions),
);
const setTicketIdAtom = atom(
  null,
  (_, set, newTicketId: SetStoreValueAndFunction<StoreValues["ticketId"]>) =>
    set(ticketIdAtom, newTicketId),
);
const setLoadingAtom = atom(
  null,
  (_, set, newLoading: SetStoreValueAndFunction<StoreValues["loading"]>) =>
    set(loadingAtom, newLoading),
);
const setUnviewedMessagesAtom = atom(
  null,
  (
    _,
    set,
    newUnviewedMessages: SetStoreValueAndFunction<
      StoreValues["unviewedMessages"]
    >,
  ) => set(unviewedMessagesAtom, newUnviewedMessages),
);
const resetStoreAtom = atom(null, (_, set) => {
  set(currentStepAtom, initialStoreValues.currentStep);
  set(departmentAtom, initialStoreValues.department);
  set(sessionIdAtom, initialStoreValues.sessionId);
  set(queuePositionAtom, initialStoreValues.queuePosition);
  set(messagesAtom, initialStoreValues.messages);
  set(questionsAtom, initialStoreValues.questions);
  set(ticketIdAtom, initialStoreValues.ticketId);
  set(unviewedMessagesAtom, initialStoreValues.unviewedMessages);
  // set(loadingAtom, initialStoreValues.loading); disable setLoading as true after a reset
});

export function useStep() {
  const [currentStep] = useAtom(currentStepAtom);
  const [department] = useAtom(departmentAtom);
  const [sessionId] = useAtom(sessionIdAtom);
  const [queuePosition] = useAtom(queuePositionAtom);
  const [messages] = useAtom(messagesAtom);
  const [questions] = useAtom(questionsAtom);
  const [ticketId] = useAtom(ticketIdAtom);
  const [loading] = useAtom(loadingAtom);
  const [unviewedMessages] = useAtom(unviewedMessagesAtom);

  const setCurrentStep = useAtom(setCurrentStepAtom)[1];
  const setDepartment = useAtom(setDepartmentAtom)[1];
  const setSessionId = useAtom(setSessionIdAtom)[1];
  const setQueuePosition = useAtom(setQueuePositionAtom)[1];
  const setMessages = useAtom(setMessagesAtom)[1];
  const setQuestions = useAtom(setQuestionsAtom)[1];
  const setTicketId = useAtom(setTicketIdAtom)[1];
  const resetStore = useAtom(resetStoreAtom)[1];
  const setLoading = useAtom(setLoadingAtom)[1];
  const setUnviewedMessages = useAtom(setUnviewedMessagesAtom)[1];

  return {
    currentStep,
    setCurrentStep,
    department,
    setDepartment,
    sessionId,
    setSessionId,
    queuePosition,
    setQueuePosition,
    messages,
    setMessages,
    questions,
    setQuestions,
    ticketId,
    setTicketId,
    resetStore,
    loading,
    setLoading,
    unviewedMessages,
    setUnviewedMessages,
  };
}
