import { atom, useAtom } from "jotai";
import { Attendant, Department, Conversation } from "../../domain/attendant";
import { useEffect } from "react";
import { useUser } from "./user.store";
import { useAttendance } from "./attendance.store";
import { SetStoreValueAndFunction } from "../../domain/store";

export type MonitoringTab = "operators" | "attendances";

type StoreValues = {
  tab: MonitoringTab;
  attendants: Attendant[];
  filters: {
    attendant: string;
    department: Department | null;
  };
  conversations: Conversation[];
};

const initialStoreValues: StoreValues = {
  tab: "operators",
  attendants: [],
  filters: {
    attendant: "",
    department: null,
  },
  conversations: [],
};

const tabAtom = atom<StoreValues["tab"]>(initialStoreValues.tab);
const attendantsAtom = atom<StoreValues["attendants"]>(
  initialStoreValues.attendants,
);
const filtersAtom = atom<StoreValues["filters"]>(initialStoreValues.filters);
const conversationsAtom = atom<StoreValues["conversations"]>(
  initialStoreValues.conversations,
);

const setTabAtom = atom(null, (_, set, newTab: StoreValues["tab"]) =>
  set(tabAtom, newTab),
);
const setAttendantsAtom = atom(
  null,
  (_, set, newAttendants: StoreValues["attendants"]) =>
    set(attendantsAtom, newAttendants),
);
const setFilterAtom = atom(
  null,
  (_, set, key: keyof StoreValues["filters"], value: unknown) =>
    set(filtersAtom, (prev) => ({ ...prev, [key]: value })),
);
const setConversationsAtom = atom(
  null,
  (
    _,
    set,
    newConversations: SetStoreValueAndFunction<StoreValues["conversations"]>,
  ) => set(conversationsAtom, newConversations),
);

export const useMonitoring = () => {
  const [tab] = useAtom(tabAtom);
  const [attendants] = useAtom(attendantsAtom);
  const [filters] = useAtom(filtersAtom);
  const [conversations] = useAtom(conversationsAtom);

  const setTab = useAtom(setTabAtom)[1];
  const setAttendants = useAtom(setAttendantsAtom)[1];
  const setFilter = useAtom(setFilterAtom)[1];
  const setConversations = useAtom(setConversationsAtom)[1];

  return {
    tab,
    attendants,
    filters,
    setTab,
    setAttendants,
    setFilter,
    conversations,
    setConversations,
  };
};
