import { CloseIcon } from "@/assets/icons/close";
import { WarningIcon } from "@/assets/icons/warning";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useAlert } from "../../../store/alert.store";
import { motion } from 'framer-motion';

export function AlertProvider() {

  const alertContext = useAlert()

  const handleClose = () => {
    alertContext.setType(null)
    alertContext.setMessage(null)
    alertContext.setTitle(null)
  }

  if (!alertContext.message) return false

  return (
    <div>
      {
        alertContext.message != null && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className='--ct-absolute --ct-top-0 --ct-left-0 --ct-h-full --ct-w-full --ct-bg-white/5 --ct-backdrop-blur-sm'>
            <Card className='--ct-absolute --ct-left-1/2 --ct-top-1/2 ---ct-translate-x-1/2  ---ct-translate-y-1/2 --ct-flex --ct-w-2/3 --ct-items-center--ct-gap-2' style={{ border: 'none', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
              <div className='--ct-flex --ct-items-center --ct-gap-2 --ct-relative --ct-px-4 --ct-py-8 --ct-w-full'>
                <Button size='sm' className='--ct-absolute --ct-top-1 --ct-right-1 hover:--ct-bg-transparent' variant='ghost' onClick={handleClose}>
                  <CloseIcon />
                </Button>
                <div>
                  <WarningIcon />
                </div>
                <div className='--ct-flex --ct-flex-col --ct-text-center --ct-w-full --ct-prose' dangerouslySetInnerHTML={{ __html: alertContext.message }} />
              </div>
            </Card>
            </div>
          </motion.div>
        )
      }
    </div>
  )
}
