import { ScrollArea } from "@/components/ui/scroll-area";
import { useQueue } from "../../../store/queue.store";
import { QueueRow } from "./row";
import { QueueListEmpty } from "./empty";

export function QueueList() {

  const { queue } = useQueue()

  if (queue.length == 0) {
    return (
      <QueueListEmpty />
    )
  }

  return (
    <ScrollArea>
      <div className='--ct-space-y-2 --ct-px-6'>
        {
          queue.map(item => (
            <QueueRow item={item} key={item.sessionId} />
          ))
        }
      </div>
    </ScrollArea>
  )
}
