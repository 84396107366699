import { Button } from "@/components/ui/button";
import { MessageCircle } from "lucide-react";
import { motion } from 'framer-motion';
import { useStep } from "../store/step.store";
import { cn } from "@/utils/utils";
import { useCompany } from "../store/company.store";

export const ToggleChatButton = ({
  handleCardClick,
  notificationNumber
}: {
  handleCardClick: () => void
  notificationNumber: number
}) => {

  const { loading } = useStep()
  const { customerCnpj } = useCompany()

  return (
    <motion.div className='--ct-text-muted-foreground --ct-relative'>
      <Button onClick={handleCardClick} variant='ghost' size='icon' className={'--ct-rounded-full --ct-h-11 --ct-w-11 --ct-text-white hover:--ct-text-white hover:--ct-bg-transparent'}>
        <MessageCircle style={{ color: 'white', height: '18px', width: '18px' }} />
      </Button>

      {
        notificationNumber > 0 && (
          <div className='--ct-absolute --ct-bg-destructive --ct-text-white --ct-rounded-full --ct-h-5 --ct-w-5 --ct-flex --ct-items-center --ct-justify-center' style={{ bottom: '-10px', right: '1px' }}>
            !
          </div>
        )
      }
    </motion.div>
  )
}
