import { CheckIcon } from "@/assets/icons/check";
import SendIcon from "@/assets/icons/send";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useAttendance } from "@/modules/chat/application/store/attendance.store";
import { useChatFloat } from "@/modules/chat/application/store/chat-float.store";
import { useCompany } from "@/modules/chat/application/store/company.store";
import { useMonitoring } from "@/modules/chat/application/store/monitoring.store";
import { useUser } from "@/modules/chat/application/store/user.store";
import { wsProvider } from "@/modules/chat/infrastructure";
import { SendMessage } from "@/modules/chat/infrastructure/IWS";
import { useState } from "react";

export function ChatInputMessage() {

  const [message, setMessage] = useState<string>('')
  const { socket } = useChatFloat()
  const { currentConversation, attendances, setAttendances, currentFinishedAttendance, setCurrentFinishedAttendance } = useAttendance()
  const companyContext = useCompany()
  const userContext = useUser()

  const currentAttendance = attendances.find(attendance => attendance.conversation.sessionId == currentConversation?.sessionId)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!socket) return
    if (!currentConversation) return
    if (!currentAttendance) return
    if (message.length === 0) return

    const ticketId = currentAttendance.messages[0]?.ticket_code || undefined

    const payload: SendMessage['props'] = {
      company: companyContext,
      message,
      sessionId: currentConversation?.sessionId,
      user: userContext,
      ticketId
    }
    wsProvider.sendMessage(socket, payload)
    setMessage('')
  }

  const handleEndConversation = () => {

    if (currentFinishedAttendance) {
      // @ts-ignore
      setCurrentFinishedAttendance(prev => ({ ...prev, quitting: { classification: null, subject: '', type: null } }))
      return
    }

    setAttendances(prev => prev.map((at) => {
      if (at.conversation.sessionId === currentConversation?.sessionId) {
        return {
          ...at,
          quitting: {
            classification: null,
            subject: '',
            type: null
          }
        }
      }
      return at
    }))
  }

  const hasCustomerMessage = currentAttendance?.messages.some(message => !message.attendant_code)

  const integration = currentAttendance?.messages[0]?.integrationType

  const fromMeta = integration === 'whatsappMeta'
  const disableByMeta = fromMeta && !hasCustomerMessage
  const isFinishedAttendance = currentFinishedAttendance != null

  return (
    <form
      className='--ct-flex --ct-items-center --ct-gap-2 --ct-px-2'
      onSubmit={handleSubmit}
    >
      <Button type='button' onClick={handleEndConversation} className='--ct-flex'>
        <CheckIcon />
      </Button>
      <div className='--ct-flex-1 --ct-flex --ct-items-center --ct-bg-muted --ct-rounded-md'>
        <Input
          placeholder={disableByMeta ? 'Esperando resposta...' : isFinishedAttendance ? 'Atendimento já finalizado.' : 'Digite sua mensagem...'}
          className='focus:--ct-border-0 focus:--ct-border-none --ct-text-xs --ct-border-none --ct-bg-transparent'
          value={message}
          onChange={e => setMessage(e.target.value)}
          disabled={disableByMeta || isFinishedAttendance}
        />
        <Button className='--ct-bg-transparent --ct-shadow-none hover:--ct-scale-105 --ct-transition-all hover:--ct-bg-transparent' size='icon' disabled={isFinishedAttendance || disableByMeta} style={{ color: 'white !important' }}>
          <SendIcon />
        </Button>
      </div>
    </form>
  )

}
