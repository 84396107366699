import { useAttendance } from "@/modules/chat/application/store/attendance.store"
import { ConversationSidebarItem } from "./sidebar-item"
import { ScrollArea } from "@/components/ui/scroll-area"

export function ConversationSidebar() {

  const { attendances, finishedAttendances } = useAttendance()


  return (
    <div className='--ct-flex --ct-flex-col --ct-overflow-hidden --ct-min-w-10 --ct-bg-muted' style={{ height: 'calc(70dvh - 200px)', maxHeight: '400px' }}>
      <ScrollArea>
        <div className='--ct-flex --ct-flex-col --ct-gap-2 --ct-items-center --ct-p-2'>
          {
            finishedAttendances.map((finishedAttendance, idx) => (
              <ConversationSidebarItem finishedAttendance={finishedAttendance} key={finishedAttendance.ticketId + '-' + idx} />
            ))
          }
          {
            attendances.map((attendance, idx) => (
              <ConversationSidebarItem attendance={attendance} key={attendance.conversation.sessionId + '-' + idx} />
            ))
          }
        </div>
      </ScrollArea>
    </div>
  )
}
