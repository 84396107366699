import * as React from "react"
import { SVGProps } from "react"
export const MessageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g fill="hsl(var(--ct-primary))">
      <path d="M5.25 6a.75.75 0 0 0 0 1.5H12A.75.75 0 0 0 12 6H5.25ZM4.5 9.75A.75.75 0 0 1 5.25 9H12a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75ZM5.25 12a.75.75 0 0 0 0 1.5H9A.75.75 0 0 0 9 12H5.25Z" />
      <path
        fillRule="evenodd"
        d="M.75 9v7.5c0 .414.336.75.75.75H9A8.25 8.25 0 1 0 .75 9Zm1.5 0A6.75 6.75 0 1 1 9 15.75H2.25V9Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
