import { SVGProps } from "react"
export const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.97.97a.75.75 0 0 1 1.06 0L12.06 3h2.69a.75.75 0 0 1 .75.75v2.69l2.03 2.03a.75.75 0 0 1 0 1.06l-2.03 2.03v2.69a.75.75 0 0 1-.75.75h-2.69l-2.03 2.03a.75.75 0 0 1-1.06 0L6.94 15H4.25a.75.75 0 0 1-.75-.75v-2.69L1.47 9.53a.75.75 0 0 1 0-1.06L3.5 6.44V3.75A.75.75 0 0 1 4.25 3h2.69L8.97.97Zm.53 1.59L7.78 4.28a.75.75 0 0 1-.53.22H5v2.25a.75.75 0 0 1-.22.53L3.06 9l1.72 1.72c.141.14.22.331.22.53v2.25h2.25a.75.75 0 0 1 .53.22l1.72 1.72 1.72-1.72a.75.75 0 0 1 .53-.22H14v-2.25a.75.75 0 0 1 .22-.53L15.94 9l-1.72-1.72a.75.75 0 0 1-.22-.53V4.5h-2.25a.75.75 0 0 1-.53-.22L9.5 2.56Zm0 4.94a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM6.5 9a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
      clipRule="evenodd"
    />
  </svg>
)
