import { atom, useAtom } from "jotai";
import { Company } from "../../domain/company";

type StoreValues = Company & {
  customerCnpj?: string | null;
};

const initialValues: StoreValues = {
  fantasyName: null,
  cnpj: null,
  name: null,
  id: null,
  alias: null,
  customerCnpj: null,
};

const fantasyNameAtom = atom<StoreValues["fantasyName"]>(
  initialValues.fantasyName,
);
const cnpjAtom = atom<StoreValues["cnpj"]>(initialValues.cnpj);
const nameAtom = atom<StoreValues["name"]>(initialValues.name);
const idAtom = atom<StoreValues["id"]>(initialValues.id);
const aliasAtom = atom<StoreValues["alias"]>(initialValues.alias);
const customerCnpjAtom = atom<StoreValues["customerCnpj"]>(
  initialValues.customerCnpj,
);

const setFantasyNameAtom = atom(
  null,
  (_, set, newFantasyName: StoreValues["fantasyName"]) =>
    set(fantasyNameAtom, newFantasyName),
);
const setCnpjAtom = atom(null, (_, set, newCnpj: StoreValues["cnpj"]) =>
  set(cnpjAtom, newCnpj),
);
const setNameAtom = atom(null, (_, set, newName: StoreValues["name"]) =>
  set(nameAtom, newName),
);
const setIdAtom = atom(null, (_, set, newId: StoreValues["id"]) =>
  set(idAtom, newId),
);
const setAliasAtom = atom(null, (_, set, newAlias: StoreValues["alias"]) =>
  set(aliasAtom, newAlias),
);
const setCustomerCnpjAtom = atom(
  null,
  (_, set, newCustomerCnpj: StoreValues["customerCnpj"]) =>
    set(customerCnpjAtom, newCustomerCnpj),
);

export const useCompany = () => {
  const [fantasyName] = useAtom(fantasyNameAtom);
  const [cnpj] = useAtom(cnpjAtom);
  const [name] = useAtom(nameAtom);
  const [id] = useAtom(idAtom);
  const [alias] = useAtom(aliasAtom);
  const [customerCnpj] = useAtom(customerCnpjAtom);

  const setFantasyName = useAtom(setFantasyNameAtom)[1];
  const setCnpj = useAtom(setCnpjAtom)[1];
  const setName = useAtom(setNameAtom)[1];
  const setId = useAtom(setIdAtom)[1];
  const setAlias = useAtom(setAliasAtom)[1];
  const setCustomerCnpj = useAtom(setCustomerCnpjAtom)[1];

  return {
    fantasyName,
    cnpj,
    name,
    id,
    alias,
    setFantasyName,
    setCnpj,
    setName,
    setId,
    setAlias,
    customerCnpj,
    setCustomerCnpj,
  };
};
