import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Select } from "@/components/ui/select";
import { useAlert } from "@/modules/chat/application/store/alert.store";
import { FinishedAttendance, useAttendance } from "@/modules/chat/application/store/attendance.store";
import { useChatFloat } from "@/modules/chat/application/store/chat-float.store";
import { useCompany } from "@/modules/chat/application/store/company.store";
import { useUser } from "@/modules/chat/application/store/user.store";
import { Conversation } from "@/modules/chat/domain/attendant";
import { wsProvider } from "@/modules/chat/infrastructure";

export function AttendanceQuitting() {

	const { quitAttendance: { classifications, types }, attendances, setAttendances, currentConversation, currentFinishedAttendance, setCurrentFinishedAttendance, setFinishedAttendances, setCurrentConversation } = useAttendance()
	const { socket } = useChatFloat()
	const userContext = useUser()
	const companyContext = useCompany()
	const { setMessage, setType } = useAlert()

	if (!currentConversation && !currentFinishedAttendance) return false
	if (!socket) return false
	const currentAttendance = attendances.find(attendance => attendance.conversation.sessionId == currentConversation?.sessionId)
	if (!currentAttendance && !currentFinishedAttendance) return false

	const handleCancel = () => {
		if (!(currentFinishedAttendance || currentConversation)) return false

		setAttendances(prev => prev.map(attendance => {
			const current = currentConversation || currentFinishedAttendance as NonNullable<Conversation | FinishedAttendance>
			if (attendance.conversation.sessionId == current.sessionId) {
				return {
					...attendance,
					quitting: false
				}
			}
			return attendance
		}))

		if (currentFinishedAttendance) {
			setCurrentFinishedAttendance(prev => prev == null ? prev : ({ ...prev, quitting: false }))
		} else {
			setCurrentConversation(prev => prev == null ? prev : ({ ...prev, quitting: false }))
		}
	}

	const handleQuit = () => {
		if (!userContext.id || !companyContext.alias || !companyContext.id) return false

		const requireAllFields = () => {
			setMessage(`Preencha todos os campos`)
			setType('warning')
		}

		if (currentFinishedAttendance) {
			const quitting = currentFinishedAttendance.quitting
			if (!quitting) return requireAllFields()
			if (!quitting.classification) return requireAllFields()
			if (!quitting.type) return requireAllFields()
			if (quitting.subject.length == 0) return requireAllFields()


			wsProvider.emitAttendanceQuit(socket, {
				attendantId: userContext.id,
				classificationId: quitting?.classification?.code,
				companyAlias: companyContext.alias,
				companyId: companyContext.id,
				sessionId: (currentFinishedAttendance?.sessionId || ''),
				subject: quitting.subject,
				typeId: quitting.type.code
			})
			setFinishedAttendances(prev => prev.filter(attendance => attendance.sessionId != currentFinishedAttendance.sessionId))
			setCurrentFinishedAttendance(null)
		}

		if (!currentAttendance) return false

		if (!currentAttendance.quitting) return false

		const quitting = currentAttendance.quitting
		if (!quitting.classification) return requireAllFields()
		if (!quitting.type) return requireAllFields()
		if (quitting.subject.length == 0) return requireAllFields()

		wsProvider.emitAttendanceQuit(socket, {
			attendantId: userContext.id,
			classificationId: quitting.classification?.code,
			companyAlias: companyContext.alias,
			companyId: companyContext.id,
			sessionId: (currentConversation?.sessionId || ''),
			subject: quitting.subject,
			typeId: quitting.type.code
		})
		setCurrentConversation(null)
	}

	const handleType = (typeId: string | string[]) => {

		const target = Array.isArray(typeId) ? typeId[0] : typeId
		const type = types.find(type => `${type.code}` == target)
		if (!type) return false

		if (currentFinishedAttendance) {
			setCurrentFinishedAttendance(prev => {
				if (!prev) return null

				let localQuitting = prev.quitting || { subject: '', type: null, classification: null }

				return {
					...prev,
					quitting: {
						...localQuitting,
						type,
					}
				}
			})
		}
		if (!currentConversation) {
			return
		}

		setAttendances(prev => prev.map(attendance => {
			if (attendance.conversation.sessionId == currentConversation.sessionId) {

				let localQuitting = attendance.quitting || { subject: '', type: null, classification: null }
				return {
					...attendance,
					quitting: {
						...localQuitting,
						type,
					}
				}
			}
			return attendance
		}))

	}

	const handleClassification = (classificationId: string | string[]) => {
		const target = Array.isArray(classificationId) ? classificationId[0] : classificationId
		const classification = classifications.find(classification => `${classification.code}` == target)
		if (!classification) return false

		if (currentFinishedAttendance) {
			setCurrentFinishedAttendance(prev => {
				if (!prev) return null

				let localQuitting = prev.quitting || { subject: '', type: null, classification: null }

				return {
					...prev,
					quitting: {
						...localQuitting,
						classification,
					}
				}
			})
		}
		if (!currentConversation) {
			return
		}

		setAttendances(prev => prev.map(attendance => {
			if (attendance.conversation.sessionId == currentConversation.sessionId) {

				let localQuitting = attendance.quitting || { subject: '', type: null, classification: null }

				return {
					...attendance,
					quitting: {
						...localQuitting,
						classification,
					}
				}
			}
			return attendance
		}))
	}

	const handleSubject = (newSubject: string) => {

		if (currentFinishedAttendance) {
			setCurrentFinishedAttendance(prev => {
				if (!prev) return null

				let localQuitting = prev.quitting || { subject: '', type: null, classification: null }

				return {
					...prev,
					quitting: {
						...localQuitting,
						subject: newSubject,
					}
				}
			})
		}
		if (!currentConversation) {
			return
		}

		setAttendances(prev => prev.map(attendance => {
			if (attendance.conversation.sessionId == currentConversation.sessionId) {

				let localQuitting = attendance.quitting || { subject: '', type: null, classification: null }

				return {
					...attendance,
					quitting: {
						...localQuitting,
						subject: newSubject
					}
				}
			}
			return attendance
		}))
	}

	return (
		<div className='--ct-flex-1 --ct-flex --ct-flex-col' style={{ height: 'calc(70dvh - 190px)', maxHeight: '470px' }}>
			<ScrollArea className='--ct-flex --ct-flex-col --ct-px-2 --ct-py-2 --ct-gap-2'>
				<Input
					placeholder="Assunto"
					onValueChange={handleSubject}
				/>
				<div className='--ct-flex --ct-gap-2 --ct-items-center [&>div]:--ct-flex-1'>
					<Select.Container handleChange={handleType}>
						<Select.Trigger className='--ct-w-full' placeholder='Tipo' getValue={e => types.find(t => `${t.code}` == e)?.description} />
						<Select.Content>
							{
								types && types.map(t => (
									<Select.Item key={t.code} value={`${t.code}`}>
										{t.description}
									</Select.Item>
								))
							}
						</Select.Content>
					</Select.Container>
					<Select.Container handleChange={handleClassification}>
						<Select.Trigger className='--ct-w-full' placeholder='Classificação' getValue={e => classifications.find(classification => `${classification.code}` == e)?.description} />
						<Select.Content>
							{
								classifications && classifications.map(classification => (
									<Select.Item key={classification.code} value={`${classification.code}`}>
										{classification.description}
									</Select.Item>
								))
							}
						</Select.Content>
					</Select.Container>
				</div>
				<div className='--ct-flex --ct-items-center --ct-justify-center --ct-gap-2'>
					<Button variant='secondary' onClick={handleCancel}>
						Cancelar
					</Button>
					<Button onClick={handleQuit} style={{ color: 'white !important' }}>
						<span className='--ct-text-white' style={{ color: 'white !important' }}>
							Finalizar
						</span>
					</Button>
				</div>
			</ScrollArea>
		</div>
	)
}
