import { ReactNode } from "react";
import { MonitoringTab as MonitoringTabType, useMonitoring } from "../../../store/monitoring.store";
import { MonitoringTabs } from "./tabs";
import { MonitoringConversations } from "./conversations/conversations";
import { MonitoringOperators } from "./operators/operators";

export function MonitoringTab() {

  const { tab } = useMonitoring()

  const tabs: Record<MonitoringTabType, ReactNode> = {
    operators: <MonitoringOperators />,
    attendances: <MonitoringConversations />
  }

  return (
    <div className='--ct-space-y-2'>

      <MonitoringTabs />
      {
        tabs[tab]
      }

    </div>
  )
}
