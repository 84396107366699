import { ContactIcon } from "@/assets/icons/contact";
import { Avatar } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useContacts } from "../../../store/contacts.store";
import { useCustomer } from "../../../store/customer.store";

type ContactRowProps = {
  id: string
  title: string
  avatar: {
    fallBack?: string
    url?: string
  }
}

export function ContactRow({ id, title, avatar }: ContactRowProps) {

  const { setCurrentCustomer } = useContacts()
  const { customers } = useCustomer()

  const handleContact = () => {
    const customer = customers.find(customer => customer.id == id)
    if (customer) {
      setCurrentCustomer(customer)
    }
  }

  return (
    <div className='--ct-flex --ct-items-center --ct-gap-4'>
      <Avatar.Container style={{ height: '50px', width: '50px' }}>
        <Avatar.Fallback>{avatar.fallBack}</Avatar.Fallback>
      </Avatar.Container>
      <div className='--ct-flex-1 --ct-text-xs --ct-truncate' style={{ width: '245px' }}>
        <Label className='--ct-truncate'>
          {
            title
          }
        </Label>
      </div>
      <Button onClick={handleContact} variant='ghost' size='icon'>
        <ContactIcon />
      </Button>
    </div>
  )
}
