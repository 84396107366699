import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

export function ContactLoadingList() {

  return (
    <div className='--ct-flex --ct-flex-col --ct-gap-4 --ct-px-4 --ct-h-full'>

      <Input
        placeholder="Pesquisar"
        value={''}
        className='--ct-bg-muted'
        disabled
      />

      <ScrollArea>
        <div className='--ct-space-y-2 --ct-px-4'>
          {
            new Array(5).fill(0).map((_, index) => (
              <div key={index} className='--ct-flex --ct-items-center --ct-gap-4'>
                <div className='--ct-animate-pulse --ct-bg-muted' style={{ height: '50px', width: '50px', borderRadius: '8px' }} />
                <div className='--ct-flex-1--ct-truncate --ct-bg-muted --ct-animate-pulse' style={{ height: '17px' }} />
              </div>
            ))
          }
        </div>
      </ScrollArea>

    </div>
  )
}
