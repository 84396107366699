import { SVGProps } from "react"
export const HeadsetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={16}
      height={16}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="currentColor" d="M0 0h16v16H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="currentColor"
        d="M8 15.333V14h4.667v-.667H10V8h2.667v-.667c0-1.289-.456-2.389-1.367-3.3-.911-.91-2.011-1.366-3.3-1.366-1.289 0-2.389.455-3.3 1.366s-1.367 2.011-1.367 3.3V8H6v5.333H3.333c-.366 0-.68-.13-.941-.391A1.284 1.284 0 0 1 2 12V7.333c0-.822.158-1.597.475-2.325A6.129 6.129 0 0 1 3.767 3.1a6.129 6.129 0 0 1 1.908-1.292A5.764 5.764 0 0 1 8 1.333c.822 0 1.597.159 2.325.475A6.128 6.128 0 0 1 12.233 3.1c.545.544.975 1.18 1.292 1.908.317.728.475 1.503.475 2.325V14c0 .367-.13.68-.392.942a1.284 1.284 0 0 1-.941.391H8ZM3.333 12h1.334V9.333H3.333V12Zm8 0h1.334V9.333h-1.334V12Z"
      />
    </g>
  </svg>
)

export const HeadsetIconSm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={6}
    height={7}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M3 7v-.667h2.333V6H4V3.333h1.333V3c0-.644-.227-1.194-.683-1.65A2.248 2.248 0 0 0 3 .667c-.644 0-1.194.227-1.65.683A2.248 2.248 0 0 0 .667 3v.333H2V6H.667a.642.642 0 0 1-.471-.196.642.642 0 0 1-.196-.47V3c0-.411.08-.799.237-1.163.159-.363.374-.681.646-.954.273-.272.59-.487.954-.646A2.882 2.882 0 0 1 3 0c.411 0 .799.08 1.162.237.364.159.682.374.955.646.272.273.487.59.646.954C5.92 2.201 6 2.59 6 3v3.333c0 .184-.065.34-.196.471a.642.642 0 0 1-.47.196H3ZM.667 5.333h.666V4H.667v1.333Zm4 0h.666V4h-.666v1.333Z"
    />
  </svg>
)
