import { ICompany, IdentifyCompany } from "../ICompany";

export class PanelCompanyProvider implements ICompany {
  getCompanyCnpj(): string {
    return localStorage.getItem("accountantCnpjPanel")
      ? localStorage.getItem("accountantCnpjPanel") || ""
      : localStorage.getItem("documentPanel") || "";
  }

  getCustomerCnpj(): string {
    if (localStorage.getItem("isAccountantPanel") !== "true") {
      return localStorage.getItem("documentPanel") || "";
    }
    return "";
  }

  async identifyCompany(): Promise<IdentifyCompany["response"]> {
    return {
      cnpj: this.getCompanyCnpj(),
      customerCnpj: this.getCustomerCnpj(),
    };
  }
}
