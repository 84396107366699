type GetDateDiffProps = {
  startDate: string;
  currentDate?: string;
};

export const getDateDiff = ({
  startDate,
  currentDate,
}: GetDateDiffProps): string => {
  const diff = Math.floor(
    (currentDate ? Date.parse(currentDate) : new Date().getTime()) -
      Date.parse(startDate),
  );
  const times = {
    seconds: diff / 1000,
    minutes: diff / (1000 * 60),
    hour: diff / (1000 * 60 * 60),
    days: diff / (1000 * 60 * 60 * 24),
  };

  if (times.days >= 1)
    return `${Math.floor(times.days)} ${
      Math.floor(times.days) > 1 ? "dias" : "dia"
    }`;
  else if (times.hour >= 1)
    return `${Math.floor(times.hour)} ${
      Math.floor(times.hour) > 1 ? "horas" : "hora"
    } `;
  else if (times.minutes >= 1) return `${Math.floor(times.minutes)} min`;
  else return `0 min`;
};
