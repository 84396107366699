import { Avatar } from "@/components/ui/avatar";
import { Label } from "@/components/ui/label";
import { useMemo } from "react";
import { Attendance, useAttendance } from "../../../store/attendance.store";
import { cn, fallBackByName } from "@/utils/utils";
import { wsProvider } from "@/modules/chat/infrastructure";
import { useChatFloat } from "../../../store/chat-float.store";
import { useCompany } from "../../../store/company.store";
import { useUser } from "../../../store/user.store";

export type AttendanceRowProps = {
  attendance: Attendance
  isFinished?: boolean
}

export function AttendanceRow({ attendance }: AttendanceRowProps) {

  const conversation = attendance.conversation

  const { socket } = useChatFloat()
  const companyContext = useCompany()
  const userContext = useUser()
  const { attendances, setCurrentConversation, setPendingAttendances } = useAttendance()

  const avatar = useMemo(() => ({
    fallBack: fallBackByName(conversation.name),
    url: null
  }), [conversation])

  const currentAttendance = attendances.find(attendance => attendance.conversation.sessionId == conversation.sessionId)
  const unviewedMessagesCount = currentAttendance?.messages.filter(message => !message.viewed)?.length

  const handleClick = () => {
    if (!socket) return
    if (!companyContext) return
    wsProvider.startConversationByClientSide(socket, {
      companyAlias: `${companyContext.alias}`,
      companyId: `${companyContext.id}`,
      attendantId: conversation.attendant.id,
      attendantName: conversation.attendant.name,
      customerId: `${conversation.company.id}`,
      sessionId: conversation.sessionId,
      username: userContext.name || userContext.email || conversation.attendant.name
    })
    setPendingAttendances(prev => prev.filter(pending => pending.sessionId != conversation.sessionId))

    setCurrentConversation(conversation)
  }

  if (!currentAttendance) return false

  return (
    <div className='--ct-flex --ct-items-center --ct-gap-4 --ct-w-full' style={{ height: '66px' }} onClick={handleClick}>
      <div className='--ct-relative'>
        <Avatar.Container style={{ height: '50px', width: '50px' }}>
          <Avatar.Fallback>{avatar.fallBack}</Avatar.Fallback>
          <Avatar.Image src={avatar.url || 'invalid link'} />
        </Avatar.Container>
      </div>

      <div className='--ct-flex --ct-flex-col --ct-h-full --ct-justify-center --ct-w-full --ct-py-3 --ct-gap-0.5'>

        <div className='--ct-w-full --ct-flex --ct-items-center --ct-justify-between'>

          <Label className='--ct-font-bold --ct-truncate' style={{ width: '245px' }}>
            {
              conversation.name
            }
          </Label>

          <Label className='--ct-tracking-wide'>
            { }
          </Label>

        </div>

        <div className='--ct-w-full --ct-flex --ct-items-center --ct-justify-between'>

          <Label className='--ct-truncate' style={{ width: '245px' }}>
            {
              conversation.company.name
            }
          </Label>

        </div>

        {
          currentAttendance.messages.length > 0 && (
            <div className='--ct-w-full --ct-flex --ct-items-center --ct-justify-between --ct-mt-1'>

              <div className='--ct-text-xs --ct-truncate' style={{ width: '265px' }}>
                <Label className={cn((unviewedMessagesCount || 0) > 0 ? '--ct-text-primary' : '--ct-text-muted-foreground')}>
                  {currentAttendance.messages[currentAttendance.messages.length - 1].message}
                </Label>
              </div>

              {
                (unviewedMessagesCount || 0) > 0 && (
                  <div className='--ct-grid --ct-place-items-center --ct-bg-primary --ct-rounded-full --ct-text-xs --ct-text-white' style={{ width: '20px', height: '20px' }}>
                    {(unviewedMessagesCount || 0) > 9 ? '+9' : unviewedMessagesCount}
                  </div>
                )
              }

            </div>
          )
        }
      </div>
    </div>
  )
}
