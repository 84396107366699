import { crmAPI } from "@/modules/api/infrastructure";
import {
  AllowAuthentication,
  Authenticate,
  IAuthentication,
  LoadBasicUserData,
} from "../IAuthentication";
// @ts-ignore
import jwtDecode from "jwt-decode";

export class BusinessAuthenticationProvider implements IAuthentication {
  getAccessToken() {
    return "Bearer " + localStorage.getItem("cognito_access_token") || "";
  }

  allowAuthentication(): AllowAuthentication["response"] {
    return (
      !!localStorage.getItem("cognito_access_token") &&
      !!localStorage.getItem("logged_user") &&
      !!localStorage.getItem("accounting-office-registration") &&
      !!localStorage.getItem("x-access-token") &&
      localStorage.getItem("accounting-office-registration") != null &&
      localStorage.getItem("accounting-office-registration") != "null"
    );
  }

  isCustomer() {
    return !!localStorage.getItem("customer-registration");
  }

  loadBasicUserData(): LoadBasicUserData["response"] {
    const businessToken = (localStorage.getItem("x-access-token") || "").split(
      " ",
    )[1];

    const decodedBusinessToken = jwtDecode(businessToken);

    return {
      user: {
        email: decodedBusinessToken.email,
        name: decodedBusinessToken.unique_name,
        accessToken: this.getAccessToken(),
        products: [],
      },
    };
  }

  async authenticate({
    companyCnpj,
  }: Authenticate["props"]): Promise<Authenticate["response"]> {
    const errorResponse = {
      success: false,
      auth: null,
      cognitoSub: "",
      company: {
        alias: "",
        id: -1,
        sgbd: "",
      },
      email: "",
      identifiers: {
        userId: -1,
        customerContactId: -1,
        customerId: null,
        user: {
          name: null,
          contactName: null,
        },
        sellerId: null,
        customerContactDataId: null,
        customerContactDataCnpj: null,
      },
    };

    if (!this.getAccessToken()) return errorResponse;

    const customerCnpj = localStorage.getItem("customer-registration") || "";

    try {
      const crmResponse = await crmAPI.makeRequest(this.getAccessToken, {
        path: `auth?companyCnpj=${companyCnpj}&customerCnpj=${customerCnpj}`,
        headers: {
          "qyon-customer-cnpj": companyCnpj,
        },
      });
      if (crmResponse.hasError) {
        return errorResponse;
      }
      return {
        success: true,
        ...crmResponse.response,
        identifiers: {
          ...crmResponse.response.identifiers,
          userId:
            customerCnpj.length > 0
              ? null
              : crmResponse.response.identifiers.userId,
        },
      };
    } catch (error) {
      return errorResponse;
    }
  }
}
