import { useChatFloat } from "../../store/chat-float.store"
import { CloseIcon } from "@/assets/icons/close"
import { Button } from "@/components/ui/button"
import { QyonLogoIcon } from "@/components/ui/qyon-logo"

export function ChatFloatHeaderCustomer() {

  const { setOpen } = useChatFloat()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className='--ct-flex --ct-h-16 --ct-w-full --ct-gap-1'>
      <div id='chat-float-drag-handler' className='--ct-bg-primary --ct-rounded-t --ct-border-b-2 --ct-border-primary --ct-text-primary --ct-flex-1 --ct-flex --ct-items-center --ct-justify-between --ct-text-sm --ct-font-bold --ct-px-2 --ct-py-6' style={{ borderBottomWidth: '2px', borderBottomStyle: 'solid' }}>
        <QyonLogoIcon />
        <Button className='--ct-cursor-pointer --ct-shadow-none --ct-border-none' style={{ color: 'white' }} onClick={handleClose}>
          <span className='--ct-text-white' style={{ color: 'white !important' }}>
            <CloseIcon />
          </span>
        </Button>
      </div>
    </div>

  )
}
