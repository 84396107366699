import { Label } from "@/components/ui/label";
import { RoomStatus, useSettings } from "../../../store/settings.store";
import { wsProvider } from "@/modules/chat/infrastructure";
import { useUser } from "../../../store/user.store";
import { useCompany } from "../../../store/company.store";
import { crmAPI, crmSocketAPI } from "@/modules/api/infrastructure";
import { Select } from "@/components/ui/select";

export function SettingsAttendance() {

  const { status, setStatus, allDepartments, attendance: attendanceSettings, setAttendance: setAttendanceSettings } = useSettings()
  const { accessToken, id: userId } = useUser()
  const { cnpj, id: companyId } = useCompany()

  const handleDepartmentChange = (departmentsId: string | string[]) => {
    if (!cnpj || !userId || !companyId) return


    if (Array.isArray(departmentsId)) {
      const departments = allDepartments.filter(department => departmentsId.includes(department.productId))
      setAttendanceSettings(prev => ({ ...prev, departments }))
    } else {
      const department = allDepartments.find(department => department.productId === departmentsId)
      if (department) {
        setAttendanceSettings(prev => ({ ...prev, departments: [department] }))
      }
    }

    const departmentsPayload = allDepartments.map(dep => ({ ...dep, selected: departmentsId.includes(dep.productId) }))

    wsProvider.changeAttendantDepartments(crmAPI, accessToken, cnpj, {
      attendantId: userId,
      departments: departmentsPayload
    })
  }

  const handleAttendanceAmountChange = (amount: string | string[]) => {
    if (!cnpj || !userId || !companyId) return

    const attendanceAmount = parseInt(amount as string)
    setAttendanceSettings(prev => ({ ...prev, attendanceAmount }))

    wsProvider.changeAttendanceAmount(crmAPI, accessToken, cnpj, {
      attendanceAmount: attendanceAmount,
      attendantId: userId
    })
  }

  const getValue = (e: string | string[] | undefined) => {
    if (e == undefined) return 'Nenhum Departamento'
    if (Array.isArray(e)) {
      if (e.length == 0) return 'Nenhum Departamento'

      return e.length > 1 ? `${e.length} Departamentos` : `${e.length} Departamento`
    } else {
      return e
    }
  }

  const sortedDepartments = [...allDepartments].sort((a, b) => a.productDescription > b.productDescription ? 1 : -1)

  return (
    <div className='--ct-flex --ct-gap-4'>
      <div>
        <Label className='--ct-text-muted-foreground' style={{ fontSize: '10.5px' }}>Atendimentos Simultâneos</Label>
        <Select.Container value={`${attendanceSettings.attendanceAmount}`} handleChange={handleAttendanceAmountChange}>
          <Select.Trigger placeholder="Quantidade" style={{ width: '150px' }} />
          <Select.Content style={{ width: '150px' }}>
            {
              new Array(6).fill(1).map((_, index) => (
                <Select.Item value={`${index}`} key={index}>
                  {index}
                </Select.Item>
              ))
            }
          </Select.Content>
        </Select.Container>
      </div>
      <div className='--ct-flex-1'>
        <Label className='--ct-text-muted-foreground' style={{ fontSize: '10.5px' }}>Departamentos</Label>
        <Select.Container className='--ct-w-full' multiple handleChange={handleDepartmentChange} value={attendanceSettings.departments.map(d => `${d.productId}`)}>
          <Select.Trigger className='--ct-w-full' placeholder="Nenhum Departamento" getValue={getValue} />
          <Select.Content style={{ width: '248px' }}>
            {
              sortedDepartments.map(department => (
                <Select.Item value={department.productId} key={department.productId}>
                  {
                    department.productDescription
                  }
                </Select.Item>
              ))
            }
          </Select.Content>
        </Select.Container>
      </div>
    </div>
  )
}
