import { ConversationSidebar } from "./sidebar"
import { ConversationHeader } from "./header"
import { ConversationChat } from "./chat"
import { ChatInputMessage } from "./input-message"
import { useAttendance } from "@/modules/chat/application/store/attendance.store"
import { AttendanceQuitting } from "./quittng"

export function Conversation() {

  const { currentConversation, attendances, currentFinishedAttendance } = useAttendance()

  const currentAttendance = attendances.find(attendance => attendance.conversation.sessionId == currentConversation?.sessionId)

  return (
    <div className='--ct-flex-1 --ct-flex --ct-flex-col'>
      <ConversationHeader />
      {
        (currentAttendance?.quitting || currentFinishedAttendance?.quitting) ? <AttendanceQuitting /> : (
          <>
            <div className='--ct-flex --ct-flex-1 --mt-2'>
              <ConversationSidebar />
              <ConversationChat />
            </div>
            <ChatInputMessage />
          </>
        )
      }
    </div>
  )
}
