import { Avatar } from "@/components/ui/avatar";
import { Label } from "@/components/ui/label";
import { useAttendance } from "@/modules/chat/application/store/attendance.store";
import { Conversation } from "@/modules/chat/domain/attendant";
import { getDateDiff } from "@/utils/date";
import { useEffect } from "react";

type ConversationRowProps = {
  conversation: Conversation;
};

export function ConversationRow({ conversation }: ConversationRowProps) {

  const avataFallBack = conversation.name
    .split(" ")
    .map((name) => name[0])
    .join("");


  return (
    <div className="--ct-flex --ct-items-center --ct-gap-4">
      <Avatar.Container>
        <Avatar.Fallback>{avataFallBack.substring(0, 2)}</Avatar.Fallback>
      </Avatar.Container>
      <div className="--ct-flex-1 --ct-text-xs --ct-truncate --ct-flex --ct-flex-col --ct-h-full --ct-gap-1">
        <Label className="--ct-font-bold --ct-text-primary --ct-text-sm --ct-w-full --ct-truncate">
          {conversation.name}
        </Label>
        <Label className="--ct-text-xs --ct-w-full --ct-truncate">{conversation.company.name}</Label>
        <Label className="--ct-font-bold --ct-text-xs --ct-w-full --ct-truncate">Atendente: {conversation.attendant.name}</Label>
      </div>
      <Label className="--ct-text-primary --ct-font-bold" style={{ fontSize: '10px' }}>
        {getDateDiff({ startDate: conversation.startConvesationDate })}
      </Label>
    </div>
  );
}
