import * as React from "react"
import { SVGProps } from "react"
const SendIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="hsl(222, 100%, 35%)"
      d="m20.34 9.32-14-7a3 3 0 0 0-4.08 3.9l2.4 5.37a1.06 1.06 0 0 1 0 .82l-2.4 5.37A3 3 0 0 0 5 22a3.14 3.14 0 0 0 1.35-.32l14-7a3 3 0 0 0 0-5.36h-.01Zm-.89 3.57-14 7a1 1 0 0 1-1.35-1.3l2.39-5.37c.031-.072.058-.145.08-.22h6.89a1 1 0 0 0 0-2H6.57a2.002 2.002 0 0 0-.08-.22L4.1 5.41a1 1 0 0 1 1.35-1.3l14 7a1 1 0 0 1 0 1.78Z"
    />
  </svg>
)
export default SendIcon
