import { Label } from "@/components/ui/label";
import { UserStatus, useSettings } from "../../../store/settings.store";
import { Select } from "@/components/ui/select";
import { useEffect } from "react";
import { useUser } from "../../../store/user.store";
import { useCompany } from "../../../store/company.store";
import { wsProvider } from "@/modules/chat/infrastructure";
import { crmAPI, crmSocketAPI } from "@/modules/api/infrastructure";
import { OnlineStatus } from "@/modules/chat/domain/constants";

export function SettingsUserStatus() {

  const { status, setStatus, allStatus, setAllStatus } = useSettings()
  const { accessToken, id: userId } = useUser()
  const { cnpj, id: companyId } = useCompany()

  const handleChange = (newStatusId: string | string[]) => {
    if (!cnpj || !companyId || !userId) return false
    const newStatus = allStatus.find(status => `${status.id}` == newStatusId) || OnlineStatus

    setStatus({ ...status, user: { ...newStatus } })
    wsProvider.changeUserStatus(crmSocketAPI, accessToken, cnpj, {
      status: newStatus,
      attendantId: userId,
      companyId: companyId
    })
  }

  const loadAllStatus = async () => {
    if (!cnpj) return

    const allStatusResponse = await wsProvider.getAllUserStatus(crmAPI, accessToken, cnpj)
    setAllStatus(allStatusResponse.allStatus)
  }

  useEffect(() => {
    if (allStatus.length == 0) loadAllStatus()
  }, [allStatus])

  return (
    <div>
      <Label className='--ct-text-muted-foreground' style={{ fontSize: '10.5px' }}>Meu Status</Label>
      <div className='--ct-flex --ct-items-center --ct-gap-6 --ct-text-sm'>
        <Select.Container value={`${status.user?.id}` || '0'} handleChange={handleChange} className='--ct-flex-1'>
          <Select.Trigger className='--ct-w-full' placeholder={status.user?.description || "Selecione um status"} getValue={e => allStatus.find(status => `${status.id}` == e)?.description || OnlineStatus.description} />
          <Select.Content style={{ width: '414px' }}>
            <Select.Item value={`0`}>
              Disponível
            </Select.Item>
            {
              allStatus.map(status => (
                <Select.Item key={status.id} value={`${status.id}`}>
                  {status.description}
                </Select.Item>
              ))
            }
          </Select.Content>
        </Select.Container>
      </div>
    </div>
  )
}
