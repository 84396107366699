import { ScrollArea } from "@/components/ui/scroll-area";
import { useCustomer } from "../../../store/customer.store";
import { ContactRow } from "./row";
import { useState } from "react";
import { Input } from "@/components/ui/input";

export function ContactList() {

  const { customers } = useCustomer()
  const [search, setSearch] = useState<string>('')

  const filteredCustomers = customers.filter(customer => customer.name.toLowerCase().includes(search.toLowerCase()))

  return (
    <div className='--ct-flex --ct-flex-col --ct-gap-4 --ct-px-4 --ct-h-full --ct-pb-2'>

      <Input
        placeholder="Pesquisar"
        value={search}
        onValueChange={setSearch}
        className='--ct-bg-muted'
      />

      <ScrollArea>
        <div className='--ct-space-y-2 --ct-py-4'>
          {
            filteredCustomers.sort((a, b) => a.name > b.name ? 1 : -1).map(customer => (
              <ContactRow
                id={customer.id}
                title={customer.name}
                avatar={{
                  fallBack: customer.name[0],
                }}
                key={customer.id}
              />
            ))
          }
        </div>
      </ScrollArea>

    </div>
  )
}
