import * as React from "react"
import { cn, EmptyFunction } from "@/utils/utils"

type ContextValues = {
  error: boolean
  loading: boolean
}

type ContextFunctions = {
  setError: (e: ContextValues['error']) => void
  setLoading: (l: ContextValues['loading']) => void
}

type ContextProps = ContextValues & ContextFunctions

const initialContextValues: ContextProps = {
  error: true,
  setError: EmptyFunction,
  loading: false,
  setLoading: EmptyFunction
}

const Context = React.createContext<ContextProps>(initialContextValues)

const ContextProvider = ({ children }: { children: React.ReactNode }) => {

  const [error, setError] = React.useState<ContextValues['error']>(initialContextValues.error)
  const [loading, setLoading] = React.useState<ContextValues['loading']>(initialContextValues.loading)

  const contextValues = React.useMemo(() => ({
    error,
    setError,
    loading,
    setLoading
  }), [
    error,
    setError,
    loading,
    setLoading
  ])

  return (
    <Context.Provider value={contextValues}>
      {children}
    </Context.Provider>
  )
}

const useContext = () => {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('Avatar items must be used within a Avatar ( Container )')
  }
  return context

}

const Container = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, style = {}, ...props }, ref) => (
  <ContextProvider>
    <div
      className={cn('--ct-relative --ct-flex --ct-shrink-0 --ct-overflow-hidden', className)}
      style={{ borderRadius: '8px', height: '50px', width: '50px', ...style }}
      {...props}
      ref={ref}
    />
  </ContextProvider>
))
Container.displayName = 'Avatar.Container'

const Image = React.forwardRef<HTMLImageElement, React.ImgHTMLAttributes<HTMLImageElement>>(({ className, ...props }, ref) => {

  const { error, setError, loading, setLoading } = useContext()

  const handleError = () => {
    if (error) return true
    setError(true)
  }

  const handleLoad = () => {
    setLoading(false)
  }

  React.useEffect(() => {
    setError(false)
    setLoading(true)
  }, [])

  if (error) return false

  return (
    <img
      className={cn('--ct-aspect-square --ct-h-full --ct-w-full', loading && '--ct-opacity-0', className)}
      {...props}
      onError={handleError}
      onLoad={handleLoad}
      ref={ref}
    />
  )

})
Image.displayName = 'Avatar.Image'

const Fallback = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, style = {}, ...props }, ref) => {

  const { error } = useContext()

  if (!error) return false

  return (
    <div
      className={cn('--ct-flex --ct-h-full --ct-w-full --ct-items-center --ct-justify-center --ct-bg-muted --ct-text-muted-foreground --ct-uppercase', className)}
      style={{ fontSize: '22px', borderRadius: '8px', ...style }}
      {...props}
      ref={ref}
    />
  )
})
Fallback.displayName = 'Avatar.Fallback'

export const Avatar = {
  Container,
  Image,
  Fallback
}
