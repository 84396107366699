import { atom, useAtom } from "jotai";
import { Department } from "../../domain/attendant";
import { SetStoreValueAndFunction } from "../../domain/store";

export type RoomStatus = "online" | "offline";
export type UserStatus = {
  id: number;
  description: string;
  active: boolean;
};

type StoreValues = {
  status: {
    room: RoomStatus;
    user: UserStatus | null;
  };
  attendance: {
    attendanceAmount: number;
    departments: Department[];
  };
  open: boolean;
  allStatus: UserStatus[];
  allDepartments: Department[];
};

const initialValues: StoreValues = {
  status: {
    room: "offline",
    user: null,
  },
  attendance: {
    attendanceAmount: 0,
    departments: [],
  },
  open: false,
  allStatus: [],
  allDepartments: [],
};

const statusAtom = atom<StoreValues["status"]>(initialValues.status);
const attendanceAtom = atom<StoreValues["attendance"]>(
  initialValues.attendance,
);
const openAtom = atom<StoreValues["open"]>(initialValues.open);
const allStatusAtom = atom<StoreValues["allStatus"]>(initialValues.allStatus);
const allDepartmentsAtom = atom<StoreValues["allDepartments"]>(
  initialValues.allDepartments,
);

const setStatusAtom = atom(
  null,
  (_, set, newStatus: SetStoreValueAndFunction<StoreValues["status"]>) =>
    set(statusAtom, newStatus),
);
const setAttendanceStatus = atom(
  null,
  (
    _,
    set,
    newAttendance: SetStoreValueAndFunction<StoreValues["attendance"]>,
  ) => set(attendanceAtom, newAttendance),
);
const setOpenAtom = atom(
  null,
  (_, set, open: SetStoreValueAndFunction<StoreValues["open"]>) =>
    set(openAtom, open),
);
const setAllStatusAtom = atom(
  null,
  (_, set, allStatus: SetStoreValueAndFunction<StoreValues["allStatus"]>) =>
    set(allStatusAtom, allStatus),
);
const setAllDepartmentsAtom = atom(
  null,
  (
    _,
    set,
    allDepartments: SetStoreValueAndFunction<StoreValues["allDepartments"]>,
  ) => set(allDepartmentsAtom, allDepartments),
);

export const useSettings = () => {
  const [status] = useAtom(statusAtom);
  const [attendance] = useAtom(attendanceAtom);
  const [open] = useAtom(openAtom);
  const [allStatus] = useAtom(allStatusAtom);
  const [allDepartments] = useAtom(allDepartmentsAtom);

  const setStatus = useAtom(setStatusAtom)[1];
  const setAttendance = useAtom(setAttendanceStatus)[1];
  const setOpen = useAtom(setOpenAtom)[1];
  const setAllStatus = useAtom(setAllStatusAtom)[1];
  const setAllDepartments = useAtom(setAllDepartmentsAtom)[1];

  return {
    status,
    attendance,
    setStatus,
    setAttendance,
    open,
    setOpen,
    allStatus,
    setAllStatus,
    allDepartments,
    setAllDepartments,
  };
};
