import { IApi, MakeRequest } from "../IApi";

export class BasicAPI implements IApi {
  private baseUrl: string = "";

  constructor(baseUrl: string) {
    let formattedBaseUrl = baseUrl;
    if (!formattedBaseUrl.endsWith("/")) {
      formattedBaseUrl += "/";
    }
    this.baseUrl = formattedBaseUrl;
  }

  private defaultErrorResponse = {
    hasError: true,
    responseTime: -1,
  };

  async makeRequest(
    getToken: null | string | (() => string),
    { path, method, body, headers }: MakeRequest["props"],
  ): Promise<MakeRequest["response"]> {
    const token = typeof getToken === "function" ? getToken() : getToken;

    if (token) {
      headers = {
        ...headers,
        Authorization: token,
      };
    }

    let formattedPath = path;
    if (formattedPath.startsWith("/")) {
      formattedPath = formattedPath.substring(1);
    }

    try {
      const response = await fetch(`${this.baseUrl}${formattedPath}`, {
        method: method || "GET",
        body: body ? JSON.stringify(body) : undefined,
        headers,
      });

      const jsonResponse = await response.json();

      if (jsonResponse.hasError)
        return { ...this.defaultErrorResponse, response: jsonResponse };

      return {
        hasError: false,
        response: jsonResponse,
        responseTime: response.headers.get("x-response-time")
          ? parseInt(response.headers.get("x-response-time") || "0")
          : 0,
      };
    } catch (error) {
      return this.defaultErrorResponse;
    }
  }
}
