import { ICompany, IdentifyCompany } from "../ICompany";

export class BusinessCompanyProvider implements ICompany {
  constructor() {}

  getStartValidityDate(): string {
    return sessionStorage.getItem("start_validity_date") || "";
  }

  async identifyCompany(): Promise<IdentifyCompany["response"]> {
    const companyIdentifier = localStorage.getItem(
      "accounting-office-registration",
    );

    const customerIdentifier = localStorage.getItem(
      "accounting-customer-registration",
    );

    if (!companyIdentifier) return null;
    if (companyIdentifier?.length == 0) return null;

    return {
      cnpj: companyIdentifier || "",
      customerCnpj: customerIdentifier || "",
    };
  }
}
