import { SVGProps } from "react"
export const ContactIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={18}
    fill="none"
    {...props}
  >
    <g fill="#0036B3">
      <path d="M13.9 18H2.088a1.052 1.052 0 0 0-.113-.038c-.989-.201-1.71-1.05-1.71-2.039C.26 11.306.26 6.69.264 2.072c0-.68.304-1.228.838-1.645.291-.23.638-.338.99-.427h11.671c.413.14.577.427.567.858-.018.586-.004 1.172-.004 1.758v.196h.618c.511 0 .788.282.788.797 0 1.158-.014 2.32.005 3.479.01.651-.085 1.26-.544 1.771.295.343.497.722.516 1.158.023.642.004 1.285 0 1.931 0 .066-.02.127-.033.193a2.172 2.172 0 0 1-.483.947c.417.478.558 1.035.539 1.65-.014.393.005.782-.005 1.176-.014.694-.304 1.247-.853 1.673-.29.22-.633.32-.975.413ZM1.666 4.097v11.705c0 .51.281.792.792.792h9.052V8.963c0-.53.271-.807.796-.807h1.229c.501 0 .782-.281.787-.783V4.387c0-.051-.005-.103-.01-.168H3.233c-.516-.005-1.041.06-1.566-.122Zm11.24-2.69H2.317a.709.709 0 0 0-.646.613.697.697 0 0 0 .496.755c.099.028.207.033.314.033H12.906V1.406Zm.014 10.963c.272 0 .53.02.788-.004a.679.679 0 0 0 .614-.68c.005-.478.005-.961 0-1.44-.005-.3-.197-.585-.483-.637-.3-.056-.61-.042-.918-.06v2.821Zm.01 1.411v2.813h.169c.192 0 .389.01.58-.005a.685.685 0 0 0 .643-.651c.014-.497.014-.994 0-1.496a.68.68 0 0 0-.656-.656c-.244-.014-.483-.005-.736-.005Z" />
      <path d="M6.264 9.848c-1.293-.28-1.87-1.378-1.776-2.32a2.118 2.118 0 0 1 2.1-1.908c1.083 0 1.983.816 2.1 1.908.098.919-.464 2.035-1.833 2.306.164.029.272.047.384.07 1.632.3 2.855 1.75 2.864 3.4.005.458-.29.758-.754.758H3.83c-.464 0-.759-.295-.754-.759.01-1.716 1.354-3.225 3.056-3.426.042-.005.084-.02.131-.029Zm-1.64 2.799h3.923c-.164-.755-1.083-1.402-1.973-1.397-.877.005-1.791.652-1.95 1.397ZM7.29 7.734a.705.705 0 0 0-.703-.703.705.705 0 0 0-.703.703c0 .385.314.704.703.704a.708.708 0 0 0 .703-.704Z" />
    </g>
  </svg>
)
