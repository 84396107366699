import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function EmptyFunction() {}

export function fallBackByName(name: string) {
  const [firstName, lastName] = name.split(" ");
  const fallBack =
    firstName.charAt(0) + (lastName ? lastName.charAt(0) : firstName.charAt(1));
  return fallBack;
}
