import { SVGProps } from "react"

export const WhatsappIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <rect width={13} height={13} x={0.5} y={0.5} fill="#2FB300" rx={6.5} />
    <rect width={13} height={13} x={0.5} y={0.5} stroke="#fff" rx={6.5} />
    <g fill="#fff" clipPath="url(#a)">
      <path d="M6.936 3.082h.473c.02.004.044.012.065.014a3.692 3.692 0 0 1 1.927.708 3.796 3.796 0 0 1 1.546 2.477c.025.14.04.28.058.42v.464c-.004.027-.01.054-.013.082-.019.14-.03.281-.057.42-.22 1.089-.793 1.935-1.715 2.53-.778.501-1.632.68-2.543.566a3.601 3.601 0 0 1-1.278-.408.192.192 0 0 0-.165-.012c-.373.123-.748.242-1.124.363-.298.098-.597.197-.896.294.007-.037.01-.076.023-.111.206-.617.41-1.233.619-1.848a.235.235 0 0 0-.018-.21c-.543-.99-.665-2.04-.296-3.108.495-1.427 1.505-2.281 2.965-2.58.142-.029.286-.041.429-.061Zm-2.772 6.962c.389-.125.76-.241 1.127-.365a.222.222 0 0 1 .21.027c.666.408 1.39.558 2.158.437.873-.136 1.58-.57 2.108-1.29.522-.71.73-1.512.59-2.383-.189-1.205-.866-2.043-1.96-2.528-.504-.225-1.037-.285-1.582-.225a3.146 3.146 0 0 0-1.468.556c-.41.286-.741.65-.985 1.09a3.2 3.2 0 0 0-.357 2.142c.08.468.261.895.528 1.288a.137.137 0 0 1 .014.104c-.063.198-.13.396-.196.592l-.187.555Z" />
      <path d="M5.998 5.188c.172-.048.245.063.303.213.078.204.16.407.252.605a.277.277 0 0 1-.032.303c-.06.08-.124.156-.188.234-.068.08-.073.122-.018.213.285.472.666.834 1.17 1.056.225.1.225.102.38-.088.072-.086.142-.175.21-.266.053-.07.115-.086.193-.054.078.033.154.067.229.104.177.085.35.173.529.256.069.033.094.08.094.158-.004.435-.234.671-.687.801a.914.914 0 0 1-.546-.026c-.34-.108-.677-.218-.984-.407-.46-.284-.823-.674-1.136-1.115-.157-.222-.31-.445-.393-.71-.136-.434-.069-.822.247-1.156a.386.386 0 0 1 .377-.121Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M2.5 2.5h9v9h-9z" />
      </clipPath>
    </defs>
  </svg>
)
