import { ScrollArea } from "@/components/ui/scroll-area";
import { useMonitoring } from "../../../../store/monitoring.store";
import { ConversationRow } from "./row";
import { Conversation } from "@/modules/chat/domain/attendant";
import { ConversationsListEmpty } from "./empty";

export function ConversationsList() {
  const { conversations, filters } = useMonitoring();

  const filterByName = (conversation: Conversation) => {
    if (!(conversation?.name && filters.attendant)) return true
    return conversation.name.toLowerCase().includes(filters.attendant.toLowerCase())
  }

  const filterByDepartment = (conversation: Conversation) => {

    if (!filters.department) return true
    if (Array.isArray(conversation.department)) {
      return conversation.department?.some(department => department.productId === filters.department?.productId) || false
    } else {
      return conversation.department?.productId === filters.department?.productId
    }
  }

  const filteredConversations = conversations.filter(conversation => (
    filterByName(conversation) &&
    filterByDepartment(conversation)
  ))

  if (filteredConversations.length == 0) {
    return <ConversationsListEmpty />
  }

  return (
    <ScrollArea>
      <div className='--ct-space-y-2 --ct-px-6'>
        {filteredConversations.map((conversation) => (
          <ConversationRow
            key={conversation.sessionId}
            conversation={conversation}
          />
        ))}
      </div>
    </ScrollArea>
  );
}
