import { ScrollArea } from "@/components/ui/scroll-area";
import { useContacts } from "@/modules/chat/application/store/contacts.store";
import { CustomerContactsRow } from "./row";
import { Contact } from "@/modules/chat/domain/customer";
import { useState } from "react";

export function CustomerContactsList() {

  const { currentCustomer } = useContacts()
  const [loading, setLoading] = useState<boolean>(false)

  if (!currentCustomer) return false
  const contacts = currentCustomer.contacts || []

  const defaultContact: Contact = {
    email: currentCustomer.email || '',
    id: parseInt(currentCustomer.id),
    name: "Contato Principal",
    active: true,
    fantasyName: currentCustomer.fantasyName,
    isContactPrincipal: true,
    cellphone: currentCustomer.cellphone,
    tellphone: currentCustomer.tellphone
  }

  return (
    <div className='--ct-flex --ct-flex-col --ct-gap-4 --ct-px-4' style={{ height: 'calc(70dvh - 150px)', maxHeight: '450px' }}>
      <ScrollArea>
        <div className='--ct-space-y-2 --ct-px-4 --ct-py-2'>
          <CustomerContactsRow contact={defaultContact} loading={loading} setLoading={setLoading} />
          {
            contacts.sort((a, b) => (a.name || a.fantasyName || a.email) > (b.name || b.fantasyName || b.email) ? 1 : -1).map(contact =>
              <CustomerContactsRow key={contact.id} contact={contact} loading={loading} setLoading={setLoading} />
            )
          }
        </div>
      </ScrollArea>
    </div>
  )
}
