import { Select } from "@/components/ui/select";
import { useSettings } from "../../../store/settings.store";
import { Button } from "@/components/ui/button";
import { MessageSelectDepartmentIcon } from "@/assets/icons/message-select-department";
import { useStep } from "../../../store/step.store";
import { useUser } from "../../../store/user.store";
import { wsProvider } from "@/modules/chat/infrastructure";
import { useChatFloat } from "../../../store/chat-float.store";
import { useCompany } from "../../../store/company.store";
import { useAlert } from "../../../store/alert.store";

export function DepartmentSelect() {

  const { allDepartments, status } = useSettings()
  const { department, setDepartment, setLoading } = useStep()
  const userContext = useUser()
  const { socket } = useChatFloat()
  const companyContext = useCompany()
  const { setMessage, setType } = useAlert()

  const handleDepartment = (departmentId: string | string[]) => {

    let cursor = departmentId
    
    if (typeof department == 'object') {
      cursor = departmentId[0]
    }
    setDepartment(allDepartments.find(department => department.productId == cursor) || null)
  }

  const handleNext = () => {
    if (!department) {
      setType('warning')
      setMessage('Selecione um departamento para continuar')
      return
    }
    if (!socket) return
    if (!userContext.customerId) return
    if (!companyContext.id) return

    setLoading(true)
    wsProvider.emitChatStartCustomer(socket, { department, company: companyContext, user: userContext })
  }

  if (!allDepartments || allDepartments?.length == 0 || status.room == 'offline') {
    return (
      <div className='--ct-h-full --ct-flex --ct-items-center --ct-justify-center'>
        Não há atendentes disponíveis no momento.
      </div>
    )
  }

  return (
    <div className='--ct-h-full --ct-flex --ct-flex-col --ct-gap-2'>
      <div className='--ct-space-y-6 --ct-mt-2 --ct-mx-2'>
        <Select.Container handleChange={handleDepartment}>
          <Select.Trigger className='--ct-w-full' placeholder='Selecione um departamento' getValue={() => department?.productDescription} />
          <Select.Content className='--ct-w-full'>
            {
              allDepartments && allDepartments.map(department => (
                <Select.Item key={department.productId} value={`${department.productId}`}>
                  {department.productDescription}
                </Select.Item>
              ))
            }
          </Select.Content>
        </Select.Container>
        <div className='--ct-flex --ct-items-center --ct-justify-center'>
          <Button onClick={handleNext} style={{ color: 'white !important' }}>
            <MessageSelectDepartmentIcon />
            <span className='--ct-text-white' style={{ color: 'white !important' }}>
              Acessar atendimento chat
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}
