import { Label } from "@/components/ui/label";

export function ConversationsListEmpty() {
  return (
    <div className='--ct-flex-1 --ct-w-full --ct-grid --ct-place-items-center'>
      <Label>
        Nenhuma conversa encontrada.
      </Label>
    </div>
  )
}
