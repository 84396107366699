import { Loading } from "@/components/ui/loading"
import { useStep } from "../../../store/step.store"

export function InQueueCustomer() {

  const { queuePosition } = useStep()

  return (
    <div className='--ct-h-full --ct-flex --ct-items-center --ct-justify-center --ct-flex-col --ct-gap-4'>
      <Loading />
      {
        queuePosition == 0 ?
          <>Um atendente irá iniciar seu atendimento</> : (
            <>
              Sua posição na fila: {queuePosition}
            </>
          )
      }
    </div>
  )

}
