import { Card } from "@/components/ui/card"
import { useChatFloat } from "../../store/chat-float.store"
import { ReactNode, useRef } from "react"
import { motion, PanInfo, useDragControls } from 'framer-motion'
import { AlertProvider } from "./alert/provider"
import { ChatFloatHeaderCustomer } from "./header-customer"
import { Step, useStep } from "../../store/step.store"
import { DepartmentSelect } from "./department-select"
import { InQueueCustomer } from "./in-queue"
import { ConversationCustomer } from "./attendance/conversation-customer"
import { AttendanceRate } from "./attendance/rate"
import { Loading } from "@/components/ui/loading"

export function ChatFloatCustomer() {

  const dragContainerRef = useRef(null)
  const dragControls = useDragControls()
  const { open } = useChatFloat()
  const { currentStep, loading } = useStep()

  const screens: Record<Step, ReactNode> = {
    IN_QUEUE: <InQueueCustomer />,
    SELECT_DEPARTMENT: <DepartmentSelect />,
    IN_CHAT: <ConversationCustomer />,
    WAITING_FOR_ATTENDANT: <div>esperando o atendente começar</div>,
    RATE: <AttendanceRate />
  }

  const handleDragStart = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {

    // @ts-ignore
    if (event.target.getAttribute('id') != 'chat-float-drag-handler') {

      // @ts-ignore
      dragControls.componentControls.forEach(entry => {
        entry.stop(event, info)
      })
    }
  }

  const handleDrag = () => {
    const container = document.getElementById('chat-float-drag-container')
    if (container) {
      const rect = container.getBoundingClientRect()
      if (rect.left < 0) {
      }
      if (rect.right < 0) {

      }
    }
  }

  return (
    <>
      <div className='--ct-absolute --ct-left-0 --ct-top-0 --ct-h-[100dvh] --ct-w-[100dvw] --ct-pointer-events-none ---ct-z-50' ref={dragContainerRef} />
      {
        open && (
          <motion.div
            className='--ct-absolute --ct-z-50 --ct-mt-10 --ct-mr-5'
            style={{ borderRadius: '8px', marginLeft: '-420px' }}
            dragControls={dragControls}
            drag
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            dragMomentum={false}
            onDrag={handleDrag}
            onDragStart={handleDragStart}
            dragConstraints={dragContainerRef}
            id='chat-float-drag-container'
          >
            <Card className='--ct-flex --ct-flex-col --ct-border-2 --ct-border-muted --ct-relative' style={{ minWidth: '400px', width: '30dvw', maxWidth: '450px', height: '70dvh', maxHeight: '662px' }}>
              <ChatFloatHeaderCustomer />
              <div className='--ct-h-1 --ct-flex-1 --ct-relative'>
                {
                  screens[currentStep]
                }
                {
                  loading && (
                    <div className='--ct-h-full --ct-bg-white --ct-top-0 --ct-w-full --ct-flex --ct-items-center --ct-justify-center --ct-flex-col --ct-gap-4 --ct-absolute'>
                      <Loading />
                    </div>
                  )
                }
              </div>
              <AlertProvider />
            </Card>
          </motion.div>
        )
      }
    </>
  )
}
