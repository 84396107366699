import { type Socket } from "socket.io-client"
import { useEffect, useRef } from "react"
import { io } from "socket.io-client"
import { useCompany } from "./store/company.store"
import { useUser } from "./store/user.store"
import { wsProvider } from "../infrastructure"
import { useMonitoring } from "./store/monitoring.store"
import { useChatFloat } from "./store/chat-float.store"
import { crmAPI, crmSocketAPI } from "@/modules/api/infrastructure"
import { Attendance, FinishedAttendance, useAttendance } from "./store/attendance.store"
import { useQueue } from "./store/queue.store"
import { useSettings } from "./store/settings.store"
import { CRMSocketProvider } from "../infrastructure/implementations/CRMSocketProvider"
import { Attendant } from "../domain/attendant"
import { useCustomer } from "./store/customer.store"

export function SocketProvider() {

  const socket = useRef<Socket>(null)
  const companyContext = useCompany()
  const userContext = useUser()
  const monitorContext = useMonitoring()
  const chatFloatContext = useChatFloat()
  const attendanceContext = useAttendance()
  const queueContext = useQueue()
  const settingsContext = useSettings()
  const { customers } = useCustomer()

  const initializeSocket = () => {
    const localSocket = io(import.meta.env.VITE_SOCKET_API, { transports: ['websocket'], reconnection: true, path: '/chat/socket.io'})
    localSocket.on('connect', () => {
      wsProvider.emitLoginAttendant(localSocket, { company: companyContext, user: userContext })
    })

    // @ts-ignore
    socket.current = localSocket
    chatFloatContext.setSocket(localSocket)
  }

  const initializeMonitor = () => {
    if (!socket.current) return

    const handleConnect = async () => {
      const monitorData = await wsProvider.getDataMonitor(crmSocketAPI, { companyId: `${companyContext.id}` })

      monitorContext.setConversations(monitorData.currentConversations)
      monitorContext.setAttendants(monitorData.attendants)
    }

    socket.current.once('connect', handleConnect)

    wsProvider.subscribeChatMonitor(socket.current, { companyId: companyContext.id }, ({ attendants, currentConversations }) => {
      attendants && monitorContext.setAttendants(attendants)
      currentConversations && monitorContext.setConversations(currentConversations)
    })

  }

  const initializeQueue = async () => {
    const companyId = companyContext.id || -1

    settingsContext.allDepartments.forEach(department => {
      socket.current && wsProvider.unsubscribeQueueConnect(socket.current, { companyId, productId: department.productId });
    })

    queueContext.setQueue([])

    await wsProvider.getQueue(crmSocketAPI, userContext.accessToken, companyContext.cnpj || '', { companyId, attendantId: `${userContext.id}` }).then(queue => {
      queueContext.setQueue(queue.queue)
    })

    settingsContext.attendance.departments.forEach(department => {
      socket.current && wsProvider.subscribeQueueConnect(socket.current, { companyId, productId: department.productId }, response => {
        if (response.action === 'newQueue') {
          queueContext.setQueue(prev => [...prev, response])
        }
        if (response.action === 'removeQueue') {
          queueContext.setQueue(prev => prev.filter(item => item.sessionId != response.sessionId))
        }
      })
    })
  }

  const initializeSettings = () => {
    const { cnpj: companyCnpj, id: companyId } = companyContext

    if (!companyCnpj || !companyId) return
    if (!userContext.id) return

    wsProvider.getQueue(crmSocketAPI, userContext.accessToken, companyCnpj, { companyId, attendantId: `${userContext.id}` }).then(queue => {
      queueContext.setQueue(queue.queue)
    })

    wsProvider.getUserStatus(crmAPI, userContext.accessToken, companyCnpj, {
      attendantId: userContext.id,
    }).then(statusResponse => {
      settingsContext.setStatus(prev => ({ ...prev, user: statusResponse.status }))
      settingsContext.setAttendance(prev => ({ ...prev, attendanceAmount: statusResponse.attendanceAmount }))
    })

    wsProvider.getRoomStatus(crmAPI, userContext.accessToken, companyCnpj).then(roomStatusResponse => {
      settingsContext.setStatus(prev => ({ ...prev, room: roomStatusResponse.roomStatus }))
    })

    wsProvider.getAllDepartments(crmAPI, userContext.accessToken, companyCnpj, { attendantId: userContext.id }).then(departmentsResponse => {
      settingsContext.setAllDepartments(departmentsResponse.departments)

      const selectedDepartments = departmentsResponse.departments.filter(department => departmentsResponse.selectedDepartments.includes(department.productId))

      settingsContext.setAttendance(prev => ({ ...prev, departments: selectedDepartments }))
    })

    wsProvider.getQuitAttendanceTypes(crmSocketAPI, userContext.accessToken, companyCnpj, { companyId }).then(quitAttendanceResponse => {
      attendanceContext.setQuitAttendance(prev => ({ ...prev, types: quitAttendanceResponse.types }))
    })

    wsProvider.getQuitAttendanceClassifications(crmSocketAPI, userContext.accessToken, companyCnpj, { companyId }).then(quitAttendanceResponse => {
      attendanceContext.setQuitAttendance(prev => ({ ...prev, classifications: quitAttendanceResponse.classifications }))
    })
  }

  const subscribeToAttendanceMessages = (attendance: Attendance) => {

    const sessionId = attendance.conversation.sessionId

    socket.current && wsProvider.unsubscribeChatMessages(socket.current, { sessionId: sessionId })
    socket.current && wsProvider.subscribeChatMessages(socket.current, { sessionId: sessionId }, (data) => {
      attendanceContext.setAttendances(prev => prev.map(cursor => {
        if (cursor.conversation.sessionId === sessionId) {
          return { ...cursor, messages: [...cursor.messages, { ...data.message, viewed: Boolean(data.message.messageIsCustomer) }] }
        }

        return cursor
      }))
    })
  }

  const subscribeToAttendanceFinish = (attendance?: Attendance, finishedAttendance?: FinishedAttendance) => {
    const sessionId = attendance?.conversation.sessionId || finishedAttendance?.sessionId

    if (!sessionId) return

    socket.current && wsProvider.unsubscribeChatDisconnect(socket.current, { sessionId })
    socket.current && wsProvider.subscribeChatDisconnect(socket.current, { sessionId }, (response) => {
      attendanceContext.setPendingAttendances(prev => prev.filter(cursor => cursor.sessionId !== sessionId))

      if (response.emitter === 'attendant') {
        attendanceContext.setAttendances(prev => prev.filter(cursor => {
          if (cursor.conversation.sessionId == sessionId) {

            attendanceContext.setCurrentFinishedAttendance(prev => prev?.sessionId === sessionId ? null : prev)
            attendanceContext.setCurrentConversation(prev => prev?.sessionId === sessionId ? null : prev)

            return false
          }
          return true
        }))
        attendanceContext.setFinishedAttendances(prev => prev.filter(cursor => cursor.sessionId !== sessionId))
        socket.current && wsProvider.unsubscribeChatDisconnect(socket.current, { sessionId })
        socket.current && wsProvider.unsubscribeChatMessages(socket.current, { sessionId })
        return
      }

      attendanceContext.setAttendances(prev => {

        const updatedAttendance = prev.find(cursor => cursor.conversation.sessionId === sessionId)

        if (!updatedAttendance) return prev.filter(cursor => cursor.conversation.sessionId !== sessionId)

        const finishedAttendance: FinishedAttendance = {
          contactName: updatedAttendance.conversation.name || '',
          cpfcnpj: customers.find(customer => customer.id === updatedAttendance.conversation.company.id)?.cnpj || '',
          customerId: updatedAttendance.conversation.company.id || -1,
          customerName: updatedAttendance.conversation.company.name || '',
          integrationType: 'chat',
          messages: updatedAttendance.messages || [],
          sessionId: sessionId,
          ticketId: -1,
          username: updatedAttendance.conversation.name,
          quitting: false
        }

        attendanceContext.setFinishedAttendances(prev => [...prev, finishedAttendance])
        attendanceContext.setCurrentConversation(prev => {

          if (prev?.sessionId === sessionId) {
            attendanceContext.setCurrentFinishedAttendance(finishedAttendance)

            return null
          }
          return prev
        })

        return prev.filter(cursor => cursor.conversation.sessionId !== sessionId)
      })

    })
  }

  const initializeAttendance = async () => {
    if (!(userContext.attendant && companyContext.id && userContext.id)) return
    const attendances = await wsProvider.getConversations(crmSocketAPI, userContext.accessToken, companyContext.cnpj || '', { companyId: companyContext.id || '', attendant: userContext })

    socket.current && companyContext.id && wsProvider.subscribeRoomOnOff(socket.current, {companyId: companyContext.id}, response => {
      if (response.online) {
        settingsContext.setStatus(prev => ({...prev, room: 'online'}))
      } else {
        settingsContext.setStatus(prev => ({...prev, room: 'offline'}))
      }
    })

    const finishedAttendances = await wsProvider.getFinishedAttendances(crmSocketAPI, userContext.accessToken, companyContext.cnpj || '', { companyId: companyContext.id || '', attendantId: userContext.id || '' })
    finishedAttendances.attendances.forEach(attendance => {
      attendanceContext.setFinishedAttendances(prev => [...prev, attendance])
      subscribeToAttendanceFinish(undefined, attendance)
      loadFinishedAttendanceMessages(attendance)
    })

    const formattedAttendances: Attendance[] = userContext.attendant ? attendances.attendances.map(attendance => ({
      attendant: userContext.attendant as Attendant,
      conversation: attendance,
      messages: [],
      statusAttendance: 1,
      timeOff: '',
    })) : []

    attendanceContext.setAttendances(formattedAttendances)

    formattedAttendances.forEach(attendance => loadAttendanceMessages(attendance, true))
    formattedAttendances.forEach(subscribeToAttendanceMessages)
    formattedAttendances.forEach(attendance => subscribeToAttendanceFinish(attendance))

    socket.current && wsProvider.subscribeStartConversation(socket.current, { companyId: companyContext.id, userId: userContext.id }, response => {
      if (response.action !== 'newAttendance') return

      if (response.typeDistribuit === null) {
        attendanceContext.setPendingAttendances(prev => [...prev, { sessionId: response.sessionId, typeDistribuit: null }])
      }

      const attendanceObject: Attendance = {
        attendant: userContext.attendant as Attendant,
        conversation: response,
        messages: [],
        timeOff: '',
        loading: true,
        quitting: false,
        statusAttendance: 1
      }

      attendanceContext.setAttendances(prev => [...prev, attendanceObject])

      socket.current && wsProvider.subscribeAttendanceStartCustomer(socket.current, { sessionId: response.sessionId }, () => {
        loadAttendanceMessages(attendanceObject, true)
      })

      subscribeToAttendanceMessages(attendanceObject)
      subscribeToAttendanceFinish(attendanceObject)

    })

  }

  const loadFinishedAttendanceMessages = async (attendance: FinishedAttendance) => {
    const messages = await wsProvider.getConversationMessages(crmSocketAPI, { id: attendance.sessionId })

    attendanceContext.setFinishedAttendances(prev => prev.map(cursor => {
      if (cursor.sessionId === attendance.sessionId) {
        return { ...cursor, messages: messages.messages }
      }
      return cursor
    }))
  }

  const loadAttendanceMessages = async (attendance: Attendance, force?: boolean) => {
    const messages = await wsProvider.getConversationMessages(crmSocketAPI, { id: attendance.conversation.sessionId })

    if (force && messages.messages.length == 0) {
      setTimeout(() => {
        loadAttendanceMessages(attendance, force)
      }, 1000)
      return
    }

    attendanceContext.setAttendances(prev => prev.map(cursor => {
      if (cursor.conversation.sessionId === attendance.conversation.sessionId) {
        return { ...cursor, messages: messages.messages.map(message => ({ ...message, viewed: true })) }
      }
      return cursor
    }))

  }

  useEffect(() => {
    initializeQueue()
  }, [settingsContext.attendance.departments])

  useEffect(() => {
    if (!userContext.email) return

    !socket.current && initializeSocket()
    initializeSettings()
    initializeMonitor()
    initializeAttendance()
  }, [userContext.id])

  useEffect(() => {
    if (!attendanceContext.contactTarget) return

    const target = attendanceContext.contactTarget
    const attendance = attendanceContext.attendances.find(attendance => attendance.conversation.name === target)
    if (!attendance) return

    const { conversation } = attendance
    attendanceContext.setCurrentConversation(attendance?.conversation)
    attendanceContext.setContactTarget(null)

    socket.current && wsProvider.startConversationByClientSide(socket.current, {
      companyAlias: `${companyContext.alias}`,
      companyId: `${companyContext.id}`,
      attendantId: conversation.attendant.id,
      attendantName: conversation.attendant.name,
      customerId: `${conversation.company.id}`,
      sessionId: conversation.sessionId,
      username: userContext.name || userContext.email || conversation.attendant.name,
      typeDistribuit: 'callCustomerToAttendance'
    })
    attendanceContext.setPendingAttendances(prev => prev.filter(pending => pending.sessionId != conversation.sessionId))
    attendanceContext.setCurrentFinishedAttendance(null)

  }, [attendanceContext.contactTarget, attendanceContext.attendances])

  useEffect(() => {
    if (!attendanceContext.conversationTarget) return

    const target = attendanceContext.conversationTarget
    const attendance = attendanceContext.attendances.find(attendance => attendance.conversation.sessionId === target)
    if (!attendance) return

    const { conversation } = attendance
    attendanceContext.setCurrentConversation(attendance?.conversation)
    attendanceContext.setConversationTarget(null)

    socket.current && wsProvider.startConversationByClientSide(socket.current, {
      companyAlias: `${companyContext.alias}`,
      companyId: `${companyContext.id}`,
      attendantId: conversation.attendant.id,
      attendantName: conversation.attendant.name,
      customerId: `${conversation.company.id}`,
      sessionId: conversation.sessionId,
      username: userContext.name || userContext.email || conversation.attendant.name
    })
    attendanceContext.setPendingAttendances(prev => prev.filter(pending => pending.sessionId != conversation.sessionId))
    attendanceContext.setCurrentFinishedAttendance(null)

  }, [attendanceContext.conversationTarget, attendanceContext.attendances])


  return false

}
