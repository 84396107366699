import { atom, useAtom } from "jotai";
import {
  Attendant,
  Conversation,
  Department,
  MaybeArray,
} from "../../domain/attendant";
import { SetStoreValueAndFunction } from "../../domain/store";
import { useEffect } from "react";

export type IntegrationType = "chat";

type Integration = "whatsappMeta";

export type Message = {
  attendant_code: string;
  integrationWppNumberId: string;
  insertMessage: boolean;
  ticket_code: number;
  company_code: number;
  tokenIntegration: string | null;
  message: string;
  time: string; // date format
  message_id: string;
  integrationType: Integration;
  messageId?: string;
  session_id: string;
  username: string;
  message_uid: string;
  messageIsCustomer: 1 | 0;
};

export type WithViewed<T> = T & { viewed: boolean };

export type Attendance = {
  attendant: Attendant;
  statusAttendance: number;
  timeOff: string; // date format
  messages: WithViewed<Message>[];
  conversation: Conversation;
  loading?: boolean;
  quitting?:
    | {
        type: QuitAttendanceType | null;
        classification: QuitAttendanceClassification | null;
        subject: string;
      }
    | false;
};

export type FinishedAttendance = {
  sessionId: string;
  messages: Message[];
  contactName: string;
  cpfcnpj: string;
  customerName: string;
  customerId: string | number;
  integrationType: IntegrationType;
  ticketId: number;
  username: string;
  quitting?:
    | {
        type: QuitAttendanceType | null;
        classification: QuitAttendanceClassification | null;
        subject: string;
      }
    | false;
};

export type QuitAttendanceType = {
  code: number;
  description: string;
};

export type QuitAttendanceClassification = {
  code: number;
  description: string;
};

type StoreValues = {
  attendances: Attendance[];
  finishedAttendances: FinishedAttendance[];
  currentFinishedAttendance: FinishedAttendance | null;
  currentConversation: Conversation | null;
  pendingAttendances: {
    sessionId: string;
    typeDistribuit: null | "callCustomerToAttendance";
  }[];
  conversationTarget: Conversation["sessionId"] | null;
  contactTarget: string | null;
  quitAttendance: {
    types: QuitAttendanceType[];
    classifications: QuitAttendanceClassification[];
  };
  subscribedAttendances: Attendance["conversation"]["sessionId"][];
};

const initialStoreValues: StoreValues = {
  attendances: [],
  finishedAttendances: [],
  currentFinishedAttendance: null,
  currentConversation: null,
  pendingAttendances: [],
  conversationTarget: null,
  contactTarget: null,
  quitAttendance: {
    types: [],
    classifications: [],
  },
  subscribedAttendances: [],
};

const attendancesAtom = atom<StoreValues["attendances"]>(
  initialStoreValues.attendances,
);
const currentConversationAtom = atom<StoreValues["currentConversation"]>(
  initialStoreValues.currentConversation,
);
const pendingAttendancesAtom = atom<StoreValues["pendingAttendances"]>(
  initialStoreValues.pendingAttendances,
);
const conversationTargetAtom = atom<StoreValues["conversationTarget"]>(
  initialStoreValues.conversationTarget,
);
const quitAttendanceAtom = atom<StoreValues["quitAttendance"]>(
  initialStoreValues.quitAttendance,
);
const finishedAttendancesAtom = atom<StoreValues["finishedAttendances"]>(
  initialStoreValues.finishedAttendances,
);
const currentFinishedAttendanceAtom = atom<
  StoreValues["currentFinishedAttendance"]
>(initialStoreValues.currentFinishedAttendance);
const contactTargetAtom = atom<StoreValues["contactTarget"]>(
  initialStoreValues.contactTarget,
);
const subscribedAttendancesAtom = atom<StoreValues["subscribedAttendances"]>(
  initialStoreValues.subscribedAttendances,
);

const setAttendancesAtom = atom(
  null,
  (
    _,
    set,
    newAttendances: SetStoreValueAndFunction<StoreValues["attendances"]>,
  ) => set(attendancesAtom, newAttendances),
);
const setCurrentConversationAtom = atom(
  null,
  (
    _,
    set,
    newConversation: SetStoreValueAndFunction<
      StoreValues["currentConversation"]
    >,
  ) => set(currentConversationAtom, newConversation),
);
const setPendingAttendancesAtom = atom(
  null,
  (
    _,
    set,
    newpendingAttendances: SetStoreValueAndFunction<
      StoreValues["pendingAttendances"]
    >,
  ) => set(pendingAttendancesAtom, newpendingAttendances),
);
const setConversationTargetAtom = atom(
  null,
  (
    _,
    set,
    newConversationTarget: SetStoreValueAndFunction<
      StoreValues["conversationTarget"]
    >,
  ) => set(conversationTargetAtom, newConversationTarget),
);
const setQuitAttendanceAtom = atom(
  null,
  (
    _,
    set,
    newQuitAttendance: SetStoreValueAndFunction<StoreValues["quitAttendance"]>,
  ) => set(quitAttendanceAtom, newQuitAttendance),
);
const setFinishedAttendancesAtom = atom(
  null,
  (
    _,
    set,
    newFinishedAttendances: SetStoreValueAndFunction<
      StoreValues["finishedAttendances"]
    >,
  ) => set(finishedAttendancesAtom, newFinishedAttendances),
);
const setCurrentFinishedAttendanceAtom = atom(
  null,
  (
    _,
    set,
    newCurrentFinishedAttendance: SetStoreValueAndFunction<
      StoreValues["currentFinishedAttendance"]
    >,
  ) => set(currentFinishedAttendanceAtom, newCurrentFinishedAttendance),
);
const setContactTargetAtom = atom(
  null,
  (
    _,
    set,
    newContactTarget: SetStoreValueAndFunction<StoreValues["contactTarget"]>,
  ) => set(contactTargetAtom, newContactTarget),
);
const setSubscribeAttendancesAtom = atom(
  null,
  (
    _,
    set,
    newSubscribedAttendances: SetStoreValueAndFunction<
      StoreValues["subscribedAttendances"]
    >,
  ) => set(subscribedAttendancesAtom, newSubscribedAttendances),
);

export const useAttendance = () => {
  const [attendances] = useAtom(attendancesAtom);
  const [currentConversation] = useAtom(currentConversationAtom);
  const [pendingAttendances] = useAtom(pendingAttendancesAtom);
  const [conversationTarget] = useAtom(conversationTargetAtom);
  const [quitAttendance] = useAtom(quitAttendanceAtom);
  const [finishedAttendances] = useAtom(finishedAttendancesAtom);
  const [currentFinishedAttendance] = useAtom(currentFinishedAttendanceAtom);
  const [contactTarget] = useAtom(contactTargetAtom);
  const [subscribedAttendances] = useAtom(subscribedAttendancesAtom);

  const setAttendances = useAtom(setAttendancesAtom)[1];
  const setCurrentConversation = useAtom(setCurrentConversationAtom)[1];
  const setPendingAttendances = useAtom(setPendingAttendancesAtom)[1];
  const setConversationTarget = useAtom(setConversationTargetAtom)[1];
  const setQuitAttendance = useAtom(setQuitAttendanceAtom)[1];
  const setFinishedAttendances = useAtom(setFinishedAttendancesAtom)[1];
  const setCurrentFinishedAttendance = useAtom(
    setCurrentFinishedAttendanceAtom,
  )[1];
  const setContactTarget = useAtom(setContactTargetAtom)[1];
  const setSubscribedAttendances = useAtom(setSubscribeAttendancesAtom)[1];

  return {
    attendances,
    setAttendances,
    currentConversation,
    setCurrentConversation,
    pendingAttendances,
    setPendingAttendances,
    conversationTarget,
    setConversationTarget,
    quitAttendance,
    setQuitAttendance,
    finishedAttendances,
    setFinishedAttendances,
    currentFinishedAttendance,
    setCurrentFinishedAttendance,
    contactTarget,
    setContactTarget,
    subscribedAttendances,
    setSubscribedAttendances,
  };
};
