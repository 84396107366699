import { Label } from "@/components/ui/label"
import { Button } from "@/components/ui/button"
import { useChatFloat } from "../../store/chat-float.store"
import { SettingsIcon } from "@/assets/icons/settings"
import { CloseIcon } from "@/assets/icons/close"
import { useSettings } from "../../store/settings.store"
import { SetStoreValueAndFunction } from "@/modules/chat/domain/store"
export function ChatFloatHeader() {

  const { setOpen } = useChatFloat()
  const { setOpen: setSettingsOpen } = useSettings()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className='--ct-flex --ct-h-12 --ct-w-full --ct-gap-1'>
      <Label id='chat-float-drag-handler' className='--ct-bg-muted --ct-border-b-2 --ct-border-primary --ct-text-primary --ct-flex-1 --ct-flex --ct-items-center --ct-justify-center --ct-text-sm --ct-font-bold' style={{ borderBottomWidth: '2px', borderBottomStyle: 'solid' }}>
        Meus Clientes
      </Label>
      <div className='--ct-flex --ct-items-center --ct-gap-1 --ct-mr-1 --ct-text-black'>
        <Button onClick={() => setSettingsOpen(prev => !prev)} variant='ghost' size='icon' className='--ct-text-primary'>
          <SettingsIcon />
        </Button>
        <Button variant='ghost' size='icon' className='--ct-text-current' onClick={handleClose}>
          <CloseIcon />
        </Button>
      </div>
    </div>

  )
}

export const ChatHeader = ({
  title,
  setSettingsOpen,
  handleClose
}: {
  title: string,
  setSettingsOpen: (e: SetStoreValueAndFunction<boolean>) => void,
  handleClose: () => void
}) => {
  return (
    <div className='--ct-flex --ct-h-12 --ct-w-full --ct-gap-1'>
      <Label id='chat-float-drag-handler' className='--ct-bg-muted --ct-border-b-2 --ct-border-primary --ct-text-primary --ct-flex-1 --ct-flex --ct-items-center --ct-justify-center --ct-text-sm --ct-font-bold' style={{ borderBottomWidth: '2px', borderBottomStyle: 'solid' }}>
        {title}
      </Label>
      <div className='--ct-flex --ct-items-center --ct-gap-1 --ct-mr-1 --ct-text-black'>
        <Button onClick={() => setSettingsOpen(prev => !prev)} variant='ghost' size='icon' className='--ct-text-primary'>
          <SettingsIcon />
        </Button>
        <Button variant='ghost' size='icon' className='--ct-text-current' onClick={handleClose}>
          <CloseIcon />
        </Button>
      </div>
    </div>
  )
}
