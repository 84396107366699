import { cn } from "@/utils/utils"
import * as React from "react"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value: string) => void
}

const inputStyle = {
  ':focus': {
    borderStyle: 'solid'
  },
  borderBottomWidth: '2px'
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, onChange, style = {}, onValueChange, ...props }, ref) => {

    const onChangeLocal = (...props: [event: React.ChangeEvent<HTMLInputElement>]) => {
      onValueChange && onValueChange(props[0].target.value)
      onChange && onChange(...props)
    }

    return (
      <input
        type={type}
        className={cn(
          "--ct-flex --ct-h-10 --ct-w-full focus-visible:--ct-border-primary --ct-border-input --ct-bg-background --ct-px-3 --ct-py-2 --ct-text-sm file:--ct-border-0 file:--ct-bg-transparent file:--ct-text-sm file:--ct-font-medium placeholder:--ct-text-muted-foreground focus-visible:--ct-outline-none disabled:--ct-cursor-not-allowed disabled:--ct-opacity-50",
          className
        )}
        style={{ ...inputStyle, ...style }}
        ref={ref}
        {...props}
        onChange={(...props) => onChangeLocal(...props)}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
