import { SVGProps } from "react"
export const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.258 6.213C4.673 5.04 6.73 3.774 9.078 3.774c2.347 0 4.405 1.266 5.82 2.439a16.344 16.344 0 0 1 2.161 2.175 9.123 9.123 0 0 1 .158.2l.01.013.003.004v.001l-.604.452.605.452-.001.002-.003.004-.01.013a7.01 7.01 0 0 1-.158.2 16.339 16.339 0 0 1-2.162 2.175c-1.414 1.173-3.472 2.438-5.82 2.438-2.347 0-4.404-1.265-5.819-2.438A16.334 16.334 0 0 1 1.096 9.73a9.377 9.377 0 0 1-.158-.2l-.01-.013-.002-.004-.001-.001.604-.453-.605-.451.002-.002.003-.004.01-.013a5.385 5.385 0 0 1 .157-.2 16.34 16.34 0 0 1 2.162-2.175ZM1.53 9.058l-.605-.451c-.2.267-.2.635 0 .903l.605-.452Zm.976 0a14.826 14.826 0 0 0 1.717 1.683c1.317 1.093 3.034 2.092 4.856 2.092s3.538-1 4.856-2.091a14.827 14.827 0 0 0 1.717-1.684l-.179-.202a14.83 14.83 0 0 0-1.538-1.481C12.616 6.283 10.9 5.284 9.078 5.284s-3.539.999-4.856 2.091a14.829 14.829 0 0 0-1.717 1.683Zm14.121 0 .605.452c.2-.268.2-.635 0-.903l-.605.451Zm-10.19 0a2.642 2.642 0 1 1 5.284 0 2.642 2.642 0 0 1-5.284 0Zm2.642-1.132a1.132 1.132 0 1 0 0 2.265 1.132 1.132 0 0 0 0-2.265Z"
      clipRule="evenodd"
    />
  </svg>
)
