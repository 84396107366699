import { Customer } from "../../domain/customer";
import { atom, useAtom } from "jotai";
import { SetStoreValueAndFunction } from "../../domain/store";

type StoreValues = {
  customers: Customer[];
  loading: boolean;
};

const initialValues: StoreValues = {
  customers: [],
  loading: true,
};

const customersAtom = atom<StoreValues["customers"]>(initialValues.customers);
const loadingAtom = atom<StoreValues["loading"]>(initialValues.loading);

const setCustomerAtom = atom(
  null,
  (_, set, newCustomers: SetStoreValueAndFunction<StoreValues["customers"]>) =>
    set(customersAtom, newCustomers),
);
const setLoadingAtom = atom(
  null,
  (_, set, newLoading: SetStoreValueAndFunction<StoreValues["loading"]>) =>
    set(loadingAtom, newLoading),
);

export const useCustomer = () => {
  const [customers] = useAtom(customersAtom);
  const [loading] = useAtom(loadingAtom);

  const setCustomers = useAtom(setCustomerAtom)[1];
  const setLoading = useAtom(setLoadingAtom)[1];

  return {
    customers,
    setCustomers,
    loading,
    setLoading,
  };
};
