import { Label } from "@/components/ui/label"
import { cn } from "@/utils/utils"
import purify from 'dompurify'

type ChatMessageBubbleProps = {
  content: string
  isMine: boolean
  createdAt: Date
  senderName: string
  reverse?: boolean
}

export function ChatMessageBubble({ content, reverse, isMine, createdAt, senderName }: ChatMessageBubbleProps) {

  const bubbleContainerStyle = { maxWidth: '241px' }
  if (isMine) {
    Object.assign(bubbleContainerStyle, { backgroundColor: '#F0F3FA' })
  }

  const cleanContent = purify.sanitize(content, { ALLOWED_TAGS: ['i', 'b'] })

  return (
    <div className={
      cn(
        '--ct-w-full --ct-flex',
        reverse ? !isMine && '--ct-justify-end' : isMine && '--ct-justify-end'
      )
    }>
      <div
        className={
          cn(
            '--ct-px-4 --ct-py-2 --ct-rounded-xl',
            !isMine && '--ct-bg-slate-200',
            isMine && '--ct-text-primary',
          )
        }
        style={bubbleContainerStyle}
      >

        <div className='--ct-flex --ct-items-center --ct-gap-2'>
          <Label
            className={
              cn(
                '--ct-text-black',
                isMine && '--ct-text-primary --ct-text-xs'
              )
            }
            style={{ fontSize: '10px' }}
          >
            {
              createdAt.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
            }
          </Label>
          {
            <Label
              className='--ct-text-xs'
              style={{ fontSize: '10px' }}>
              {senderName}
            </Label>
          }
        </div>

        <div className='--ct-text-xs --ct-text-wrap --ct-w-full --ct-max-w-[300px] --ct-mt-1 --ct-break-words' dangerouslySetInnerHTML={{ __html: cleanContent }} />

      </div>
    </div >
  )
}
