import { SettingsAttendance } from "./attendance";
import { SettingsHeader } from "./header";
import { SettingsRoomStatus } from "./room-status";
import { SettingsUserStatus } from "./user-status";
import { version } from '../../../../../../../package.json'

export function Settings() {
  return (
    <div className='--ct-space-y-2' style={{ height: '510px' }}>
      <SettingsHeader />
      <div className='--ct-flex --ct-flex-col --ct-gap-4 --ct-px-4'>
        <SettingsRoomStatus />
        <SettingsUserStatus />
        <SettingsAttendance />
      </div>
      <div className='--ct-absolute --ct-right-2 --ct-bottom-2 --ct-text-xs --ct-text-muted-foreground'>
        v{version}
      </div>
    </div>
  )
}
