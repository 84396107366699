import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/utils/utils"

const buttonVariants = cva(
  "--ct-flex --ct-gap-2 --ct-items-center --ct-justify-center --ct-whitespace-nowrap --ct-rounded-md --ct-text-base --ct-font-medium --ct-transition-colors focus-visible:--ct-outline-none focus-visible:--ct-ring-1 focus-visible:--ct-ring-ring disabled:--ct-pointer-events-none disabled:--ct-opacity-50",
  {
    variants: {
      variant: {
        default:
          "--ct-bg-primary --ct-text-primary-foreground --ct-shadow hover:--ct-bg-primary-hover disabled:--ct-bg-secondary disabled:--ct-text-secondary-foreground",
        outline:
          "--ct-border --ct-border-primary --ct-text-primary --ct-shadow-sm hover:--ct-text-primary-hover hover:--ct-bg-accent disabled:--ct-text-secondary-foreground disabled:--ct-bg-secondary disabled:--ct-border-secondary",
        secondary:
          "--ct-bg-secondary --ct-text-secondary-foreground --ct-shadow-sm",
        ghost:
          "hover:--ct-bg-accent hover:--ct-text-primary-hover disabled:--ct-text-secondary-foreground",
        link:
          "--ct-text-primary --ct-underline-offset-4 hover:--ct-underline",
        destructive:
          "--ct-bg-destructive --ct-text-destructive-foreground --ct-shadow-sm hover:--ct-bg-destructive/90",
      },
      size: {
        default: "--ct-h-9 --ct-px-4 --ct-py-2",
        sm: "--ct-h-8 --ct-rounded-md --ct-px-3 --ct-text-xs",
        lg: "--ct-h-10 --ct-rounded-md --ct-px-8",
        icon: "--ct-h-9 --ct-w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, style = {}, ...props }, ref) => {
    return (
      <button
        className={cn(buttonVariants({ variant, size }), className)}
        style={{ borderStyle: variant === 'outline' ? 'solid' : 'none', ...style }}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
