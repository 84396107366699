import { BackIcon } from "@/assets/icons/back"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { useContacts } from "@/modules/chat/application/store/contacts.store"

export function CustomerContactsHeader() {

  const { currentCustomer, setCurrentCustomer } = useContacts()

  const handleClearCustomer = () => {
    setCurrentCustomer(null)
  }

  return (
    <div className='--ct-h-10 --ct-px-2 --ct-font-bold --ct-bg-primary --ct-text-white --ct-text-sm --ct-py-3 --ct-flex --ct-justify-center --ct-items-center --ct-gap-4'>
      <Label className='--ct-truncate'>
        {
          currentCustomer?.name
        }
      </Label>
      <div className='--ct-cursor-pointer' onClick={handleClearCustomer}>
        <BackIcon />
      </div>
    </div>
  )
}
