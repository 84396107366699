import { Label } from "@/components/ui/label";
import { RoomStatus, useSettings } from "../../../store/settings.store";
import { wsProvider } from "@/modules/chat/infrastructure";
import { useUser } from "../../../store/user.store";
import { useCompany } from "../../../store/company.store";
import { crmAPI, crmSocketAPI } from "@/modules/api/infrastructure";

export function SettingsRoomStatus() {

  const { status, setStatus } = useSettings()
  const { accessToken, id: userId } = useUser()
  const { cnpj, id: companyId } = useCompany()

  const handleClick = (roomStatus: RoomStatus) => {
    if (!cnpj || !userId || !companyId) return

    setStatus({ ...status, room: roomStatus })

    wsProvider.changeRoomStatus(crmSocketAPI, accessToken, cnpj, {
      attendantId: userId,
      companyId,
      status: roomStatus
    })
  }

  return (
    <div>
      <Label className='--ct-text-muted-foreground' style={{ fontSize: '10.5px' }}>Status Sala</Label>
      <div className='--ct-flex --ct-items-center --ct-gap-6 --ct-text-sm'>
        <div className='--ct-flex --ct-items-center --ct-gap-1' onClick={() => handleClick('online')} >
          <input type='radio' checked={status.room === 'online'} onChange={() => { }} />
          <Label style={{ marginTop: '2px' }}>Online</Label>
        </div>
        <div className='--ct-flex --ct-items-center --ct-gap-1' onClick={() => handleClick('offline')}>
          <input type='radio' checked={status.room === 'offline'} onChange={() => { }} />
          <Label style={{ marginTop: '2px' }}>Offline</Label>
        </div>
      </div>
    </div>
  )
}
