import { ENVIRONMENT } from "../domain/environments";
import { ICompany } from "./ICompany";
import { IAuthentication } from "./IAuthentication";
import { BusinessAuthenticationProvider } from "./implementations/BusinessAuthenticationProvider";
import { BusinessCompanyProvider } from "./implementations/BusinessCompanyProvider";
import { PanelCompanyProvider } from "./implementations/PanelCompanyProvider";
import { PanelAuthenticationProvider } from "./implementations/PanelAuthenticationProvider";
import { CRMCustomerProvider } from "./implementations/CRMCustomerProvider";
import { ICustomer } from "./ICustomer";
import { CRMSocketProvider } from "./implementations/CRMSocketProvider";
import { IWS } from "./IWS";

const businessAuthenticationProvider = new BusinessAuthenticationProvider();
const panelAuthenticationProvider = new PanelAuthenticationProvider();

export const authenticationProviders: Record<ENVIRONMENT, IAuthentication> = {
  ACCOUNTANT: businessAuthenticationProvider,
  GF: panelAuthenticationProvider,
  PANEL: panelAuthenticationProvider,
  PAYROLL: businessAuthenticationProvider,
  TAX: businessAuthenticationProvider,
  XML: businessAuthenticationProvider,
};

const businessCompanyProvider = new BusinessCompanyProvider();
const panelCompanyProvider = new PanelCompanyProvider();

export const companyProviders: Record<ENVIRONMENT, ICompany> = {
  ACCOUNTANT: businessCompanyProvider,
  GF: panelCompanyProvider,
  PANEL: panelCompanyProvider,
  PAYROLL: businessCompanyProvider,
  TAX: businessCompanyProvider,
  XML: businessCompanyProvider,
};

const crmCustomerProvider = new CRMCustomerProvider();

export const customerProviders: Record<ENVIRONMENT, ICustomer> = {
  ACCOUNTANT: crmCustomerProvider,
  GF: crmCustomerProvider,
  PANEL: crmCustomerProvider,
  PAYROLL: crmCustomerProvider,
  TAX: crmCustomerProvider,
  XML: crmCustomerProvider,
};

export const wsProvider: IWS = new CRMSocketProvider();
