import { HeadsetIconSm } from "@/assets/icons/headset";
import { WhatsappIcon } from "@/assets/icons/whatsapp";
import { Avatar } from "@/components/ui/avatar";
import { Attendance, FinishedAttendance, useAttendance } from "@/modules/chat/application/store/attendance.store";
import { useChatFloat } from "@/modules/chat/application/store/chat-float.store";
import { useCompany } from "@/modules/chat/application/store/company.store";
import { useUser } from "@/modules/chat/application/store/user.store";
import { wsProvider } from "@/modules/chat/infrastructure";
import { cn, fallBackByName } from "@/utils/utils";

type ConversationSidebarItemProps = {
  attendance?: Attendance
  finishedAttendance?: FinishedAttendance
}

export function ConversationSidebarItem({ attendance, finishedAttendance }: ConversationSidebarItemProps) {

  const { socket } = useChatFloat()
  const companyContext = useCompany()
  const userContext = useUser()
  const unviewedMessagesCount = attendance?.messages.filter(message => !message.viewed)?.length
  const { setCurrentConversation, currentConversation, setCurrentFinishedAttendance, currentFinishedAttendance, setPendingAttendances } = useAttendance()

  const handleConversation = () => {
    setCurrentFinishedAttendance(null)
    if (!finishedAttendance && attendance) {
      if (!socket) return
      if (!companyContext) return
      const conversation = attendance.conversation
      attendance.messages.length === 0 && wsProvider.startConversationByClientSide(socket, {
        companyAlias: `${companyContext.alias}`,
        companyId: `${companyContext.id}`,
        attendantId: conversation.attendant.id,
        attendantName: conversation.attendant.name,
        customerId: `${conversation.company.id}`,
        sessionId: conversation.sessionId,
        username: userContext.name || userContext.email || conversation.attendant.name
      })
      setPendingAttendances(prev => prev.filter(pending => pending.sessionId != conversation.sessionId))

      setCurrentConversation(conversation)
    } else {
      setCurrentConversation(null)
      setCurrentFinishedAttendance(finishedAttendance || null)
    }
  }

  const enableWhatsapp = attendance?.messages[0]?.integrationType == 'whatsappMeta' && !finishedAttendance

  const inCurrent = (currentConversation?.sessionId == attendance?.conversation.sessionId) || (currentFinishedAttendance ? currentFinishedAttendance?.ticketId == finishedAttendance?.ticketId : false)

  return (
    <Avatar.Container className='--ct-border-2 --ct-overflow-visible --ct-cursor-pointer' onClick={handleConversation} style={{ borderColor: inCurrent ? 'hsl(var(--ct-primary))' : '#CCCCCC', borderStyle: 'solid' }}>
      <Avatar.Fallback style={{ color: inCurrent ? 'hsl(var(--ct-primary))' : '#CCCCCC' }}>{fallBackByName(attendance?.conversation.name || finishedAttendance?.username || '')}</Avatar.Fallback>
      {
        enableWhatsapp && (
          <div className='--ct-absolute ---ct-left-1 ---ct-bottom-1 '>
            <WhatsappIcon />
          </div>
        )
      }
      {
        finishedAttendance && (
          <div className='--ct-absolute ---ct-left-1 ---ct-bottom-1 --ct-bg-red-500 --ct-h-4 --ct-w-4 --ct-rounded-full --ct-grid --ct-place-items-center'>
            <HeadsetIconSm className='--ct-scale-125' />
          </div>
        )
      }
      {unviewedMessagesCount != undefined && <span
        className={cn(
          '--ct-absolute ---ct-right-1 ---ct-bottom-1',
          '--ct-h-3 --ct-w-3 --ct-rounded-full',
          '--ct-text-white',
          '--ct-grid --ct-place-items-center'
        )}
        style={{ fontSize: '8px', backgroundColor: inCurrent ? 'hsl(var(--ct-primary))' : '#979797' }}>{unviewedMessagesCount > 9 ? '+9' : unviewedMessagesCount}</span>}
    </Avatar.Container>
  )
}
