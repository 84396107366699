import { cn } from '@/utils/utils'
import React, { HTMLAttributes } from 'react'

const Loading = React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div className={
    cn(
      '--ct-inline-block --ct-h-8 --ct-w-8 --ct-animate-spin --ct-rounded-full --ct-border-4 --ct-border-solid --ct-border-primary --ct-border-e-transparent --ct-align-[-0.125em] --ct-text-surface --ct-motion-reduce:animate-[spin_1.5s_linear_infinite] --ct-dark:text-white',
      className
    )}
    {...props}
    ref={ref}
  >
    <span className='!--ct-absolute !--ct--m-px !--ct-h-px !--ct-w-px !--ct-overflow-hidden !--ct-whitespace-nowrap !--ct-border-0 !--ct-p-0 !--ct-[clip:rect(0,0,0,0)]'>
      ...
    </span>
  </div>
))
Loading.displayName = 'Loading'

export {
  Loading
}
