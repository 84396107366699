import { SVGProps } from "react"
export const ContactsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.155 1.833c0-.368.298-.666.667-.666h10.666c.369 0 .667.298.667.666v13.334a.667.667 0 0 1-.667.666H2.822a.667.667 0 0 1-.667-.666V12.5a.667.667 0 1 1 1.333 0v2h9.334v-12H3.488v2a.667.667 0 0 1-1.333 0V1.833Zm6 3.667a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333Zm-2 .667a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM1.488 6.5c0-.368.299-.667.667-.667h1.333a.667.667 0 0 1 0 1.334H2.155a.667.667 0 0 1-.667-.667Zm0 2c0-.368.299-.667.667-.667h1.333a.667.667 0 0 1 0 1.334H2.155a.667.667 0 0 1-.667-.667Zm3.334 3.667a3.333 3.333 0 1 1 6.666 0 .667.667 0 0 1-1.333 0 2 2 0 1 0-4 0 .667.667 0 0 1-1.333 0ZM1.488 10.5c0-.368.299-.667.667-.667h1.333a.667.667 0 0 1 0 1.334H2.155a.667.667 0 0 1-.667-.667Z"
      clipRule="evenodd"
    />
  </svg>
)
