import { atom, useAtom } from "jotai";
import { SetStoreValueAndFunction } from "../../domain/store";

export type QueueItem = {
  customerName: string;
  sessionId: string;
  username: string;
  productName: string;
  createdAt: string;
};

type StoreValues = {
  queue: QueueItem[];
};

const initialValues: StoreValues = {
  queue: [],
};

const queueAtom = atom<StoreValues["queue"]>(initialValues.queue);

const setQueueAtom = atom(
  null,
  (_, set, newQueue: SetStoreValueAndFunction<StoreValues["queue"]>) =>
    set(queueAtom, newQueue),
);

export function useQueue() {
  const [queue] = useAtom(queueAtom);
  const setQueue = useAtom(setQueueAtom)[1];

  return {
    queue,
    setQueue,
  };
}
