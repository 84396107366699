import { ScrollArea } from "@/components/ui/scroll-area"
import { OperatorRow } from "./row"
import { Attendant } from "@/modules/chat/domain/attendant"
import { useMonitoring } from "@/modules/chat/application/store/monitoring.store"

export function OperatorsList() {

  const { filters, attendants } = useMonitoring()

  const filterByName = (attendant: Attendant) => {
    if (!(attendant?.attendant?.name && filters.attendant)) return true
    return attendant.attendant.name.toLowerCase().includes(filters.attendant.toLowerCase())
  }

  const filterByDepartment = (attendant: Attendant) => {

    if (!filters.department) return true
    if (Array.isArray(attendant.departments)) {
      return attendant.departments?.some(department => department.productId === filters.department?.productId) || false
    } else {
      return attendant.departments?.productId === filters.department?.productId
    }
  }

  const filteredAttendants = attendants.filter(attendant => (
    filterByName(attendant) &&
    filterByDepartment(attendant)
  ))

  return (
    <ScrollArea>
      <div className='--ct-space-y-2 --ct-px-6'>
        {
          filteredAttendants.map(attendant => (
            <OperatorRow key={attendant.attendant.id} attendant={attendant} />
          ))
        }
      </div>
    </ScrollArea>
  )
}
