import { CheckIcon } from "@/assets/icons/check";
import SendIcon from "@/assets/icons/send";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useAttendance } from "@/modules/chat/application/store/attendance.store";
import { useChatFloat } from "@/modules/chat/application/store/chat-float.store";
import { useCompany } from "@/modules/chat/application/store/company.store";
import { useMonitoring } from "@/modules/chat/application/store/monitoring.store";
import { useStep } from "@/modules/chat/application/store/step.store";
import { useUser } from "@/modules/chat/application/store/user.store";
import { wsProvider } from "@/modules/chat/infrastructure";
import { SendMessage, SendMessageCustomer } from "@/modules/chat/infrastructure/IWS";
import { useState } from "react";

export function ChatInputMessageCustomer() {

  const [message, setMessage] = useState<string>('')
  const { alias, id } = useCompany()
  const { sessionId } = useStep()
  const userContext = useUser()
  const { socket } = useChatFloat()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!alias) return
    if (!sessionId) return
    if (!socket) return

    const payload: SendMessageCustomer['props'] = {
      companyAlias: alias,
      companyId: id,
      message,
      sessionId: sessionId,
      user: userContext,
    }

    // sendMessageCustomer
    wsProvider.sendMessageCustomer(socket, payload)
    setMessage('')
  }

  return (
    <form
      className='--ct-flex --ct-items-center --ct-gap-2 --ct-px-2'
      onSubmit={handleSubmit}
    >
      <div className='--ct-flex-1 --ct-flex --ct-items-center --ct-bg-muted --ct-rounded-md'>
        <Input
          placeholder="Digite sua mensagem..."
          className='focus:--ct-border-0 focus:--ct-border-none --ct-text-xs --ct-border-none --ct-bg-transparent'
          value={message}
          onChange={e => setMessage(e.target.value)}
        // disabled={currentFinishedAttendance != null}
        />
        <Button className='--ct-bg-transparent --ct-shadow-none hover:--ct-scale-105 --ct-transition-all hover:--ct-bg-transparent' size='icon' style={{ color: 'white !important' }}>
          <SendIcon />
        </Button>
      </div>
    </form>
  )

}
