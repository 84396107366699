import { SVGProps } from "react"

export const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={33}
    fill="none"
    {...props}
  >
    <path
      fill="#F09F00"
      fillRule="evenodd"
      d="M16.504 2.69a2.511 2.511 0 0 0-2.176 1.26L1.688 25.457c-1.003 1.706.19 3.902 2.175 3.902h25.28c1.986 0 3.181-2.197 2.176-3.902L18.682 3.952l-.001-.002a2.51 2.51 0 0 0-2.177-1.26Zm12.391 23.97L16.503 5.576 4.113 26.66h24.782Z"
      clipRule="evenodd"
    />
    <path
      fill="#F09F00"
      d="M14.662 23.399a1.842 1.842 0 0 1 3.547-.706 1.844 1.844 0 1 1-3.547.706Zm.18-11.07a1.67 1.67 0 1 1 3.32 0l-.646 6.465a1.018 1.018 0 0 1-2.028 0l-.645-6.466Z"
    />
  </svg>
)
