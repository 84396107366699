import { SVGProps } from "react"
export const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.97 2.47a.75.75 0 0 1 1.06 0L9.5 7.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L10.56 9l5.47 5.47a.75.75 0 1 1-1.06 1.06L9.5 10.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L8.44 9 2.97 3.53a.75.75 0 0 1 0-1.06Z"
      clipRule="evenodd"
    />
  </svg>
)
