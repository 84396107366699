import { Input } from "@/components/ui/input";
import { Select } from "@/components/ui/select";
import { useMemo } from "react";
import { useMonitoring } from "@/modules/chat/application/store/monitoring.store";
import { Department } from "@/modules/chat/domain/attendant";

export function OperatorsFilters() {

  const { filters, setFilter, attendants } = useMonitoring();

  const allDepartments = useMemo(() => {
    const departments: Department[] = []
    const attendantsWithDepartment = attendants.filter(attendant => Boolean(attendant.departments))

    attendantsWithDepartment.map(attendant => {
      if (Array.isArray(attendant.departments)) {

        attendant.departments?.map(department => {
          if (departments.some(dep => dep.productId === department.productId)) return
          departments.push(department)
        })
      } else {

        if (!attendant.departments) return
        const department = attendant.departments as Department
        if (departments.some(dep => dep.productId === department.productId)) return
        departments.push(department)

      }
    })

    return departments
  }, [attendants])

  const handleDepartment = (productId: string | string[]) => {
    setFilter('department', allDepartments.find(dep => dep.productId === productId) || null)
  }

  return (
    <div className='--ct-flex --ct-justify-center --ct-items-center --ct-gap-4'>
      <Input
        placeholder='Atendente'
        onValueChange={value => setFilter('attendant', value)}
        value={filters.attendant}
      />
      <Select.Container handleChange={handleDepartment}>
        <Select.Trigger enableTooltip placeholder="Departamento" getValue={v => allDepartments.find(dep => dep.productId === v)?.productDescription || 'Todos'} style={{ width: '150px' }} />
        <Select.Content>
          <Select.Item value='-1'>
            Todos
          </Select.Item>
          {
            allDepartments.map(department => (
              <Select.Item key={department.productId} value={department.productId}>
                {department.productDescription}
              </Select.Item>
            ))
          }
        </Select.Content>
      </Select.Container>
    </div>
  )
}
