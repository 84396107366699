import { useStep } from "@/modules/chat/application/store/step.store"
import { ChatMessageBubble } from "../conversation/message-bubble"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useEffect, useRef, useState } from "react"
import { Loading } from "@/components/ui/loading"
import { wsProvider } from "@/modules/chat/infrastructure"
import { useChatFloat } from "@/modules/chat/application/store/chat-float.store"
import { Button } from "@/components/ui/button"
import { ChatInputMessageCustomer } from "./input-message-customer"
import { BackIcon } from "@/assets/icons/back"
import { CloseIcon } from "@/assets/icons/close"
import { useCompany } from "@/modules/chat/application/store/company.store"

export function ConversationCustomer() {

  const { messages, sessionId, department, setTicketId, setCurrentStep } = useStep()
  const { socket } = useChatFloat()
  const { id: companyId, alias: companyAlias } = useCompany()

  const messagesContainerRef = useRef<HTMLDivElement>(null)
  const [confirmToFinish, setConfirmToFinish] = useState<boolean>(false)

  useEffect(() => {
    if (!socket) return
    if (!sessionId) return

    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }

    wsProvider.subscribeAttendantFinishAttendanceCustomer(socket, { sessionId }, ({ ticketId }) => {
      setTicketId(ticketId)
      setCurrentStep('RATE')
    }, true)

  }, [sessionId])

  const firstMessageFromAttendant = messages.find(message => !!message.attendant_code)

  const confirmFinish = () => {

    if (!socket) return
    if (!companyAlias) return
    if (!companyId) return
    if (!sessionId) return

    const attendantMessage = messages.find(message => !!message.attendant_code)
    if (!attendantMessage) return

    wsProvider.emitFinishAttendanceCustomer(socket, {
      attendantCode: attendantMessage.attendant_code,
      companyAlias: companyAlias,
      companyId: `${companyId}`,
      sessionId: sessionId
    })
  }

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages])

  if (confirmToFinish) {
    return (
      <div className='--ct-flex-1 --ct-flex --ct-flex-col --ct-space-y-2'>
        <div className='--ct-flex --ct-flex-col --ct-items-center --ct-gap-2'>
          <div className='--ct-w-full --ct-flex --ct-justify-between --ct-items-center --ct-bg-primary/10 --ct-py-4 --ct-px-6 --ct-text-primary --ct-text-sm'>
            <div>Encerramento</div>
          </div>
        </div>
        <div className='--ct-flex --ct-flex-col --ct-flex-1 --ct-py-1 ' style={{ height: 'calc(70dvh - 1000px)', maxHeight: '460px', borderBottomStyle: 'solid' }}>
          <ScrollArea>
            <div className='--ct-flex --ct-flex-col --ct-items-center --ct-gap-4'>
              <div className='--ct-text-sm'>
                Tem terteza que deseja encerrar o atendimento?
              </div>
              <div className='--ct-flex --ct-items-center --ct-gap-6'>
                <Button variant='secondary' onClick={() => setConfirmToFinish(false)}>
                  <BackIcon />
                  Voltar
                </Button>
                <Button onClick={confirmFinish}>
                  <span className='--ct-text-white --ct-flex --ct-items-center --ct-gap-2' style={{ color: 'white !important' }}>
                    <CloseIcon />
                    Encerrar
                  </span>
                </Button>
              </div>
            </div>
          </ScrollArea>
        </div>
      </div>
    )
  }

  return (
    <div className='--ct-flex-1 --ct-flex --ct-flex-col --ct-space-y-2 --ct-h-full'>
      <div className='--ct-flex --ct-flex-col --ct-items-center --ct-gap-2'>
        <div className='--ct-w-full --ct-flex --ct-justify-between --ct-items-center --ct-bg-primary/10 --ct-py-4 --ct-px-6 --ct-text-primary --ct-text-sm'>
          <div>
            <div className='--ct-font-bold'>{firstMessageFromAttendant?.username}</div>
            <div>{department?.productDescription}</div>
          </div>
          <Button size='sm' onClick={() => setConfirmToFinish(true)}>
            <span className='--ct-text-white' style={{ color: 'white !important' }}>
              Encerrar
            </span>
          </Button>
        </div>
      </div>
      <div className='--ct-flex --ct-flex-col --ct-flex-1 --ct-py-1 ' style={{ maxHeight: '460px', borderBottomStyle: 'solid' }}>
        <ScrollArea ref={messagesContainerRef} id='scroll-chat' style={{ height: 'calc(70dvh - 210px)', maxHeight: '460px' }}>
          {
            messages.length == 0 && (
              <div className='--ct-h-full --ct-grid --ct-place-items-center'>
                <Loading />
              </div>
            )
          }
          <div className='--ct-flex --ct-flex-col --ct-gap-2 --ct-px-2'>
            {
              messages.map((message) => (
                <ChatMessageBubble
                  key={message.message_uid || message.message_id || message.messageId}
                  content={message.message}
                  createdAt={(new Date(message.time))}
                  isMine={Boolean(message.messageIsCustomer || !!message.attendant_code)}
                  senderName={message.username}
                  reverse
                />
              ))
            }
          </div>
        </ScrollArea>
      </div>
      <ChatInputMessageCustomer />
    </div>
  )
}
