import { OperatorsList } from "./list";
import { OperatorsFilters } from "./filters";


export function MonitoringOperators() {
  return (
    <div className='--ct-flex --ct-flex-col --ct-gap-4 --ct-px-4' style={{ height: 'calc(70dvh - 190px)', maxHeight: '510px' }}>
      <OperatorsFilters />
      <OperatorsList />
    </div>
  )
}
