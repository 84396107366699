import { cn } from "@/utils/utils"
import * as React from "react"

export interface ScrollAreaProps extends React.HTMLAttributes<HTMLDivElement> {

}

const ScrollArea = React.forwardRef<HTMLDivElement, ScrollAreaProps>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn('--ct-scroll-smooth --ct-h-full --ct-overflow-y-scroll --ct-scrollbar --ct-scrollbar-thumb-gray-400 --ct-scrollbar-w-2 --ct-scrollbar-thumb-rounded-lg', className)}
      {...props}
      ref={ref}
    />
  )
})

ScrollArea.displayName = 'ScrollArea'

export { ScrollArea }
