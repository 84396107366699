import { useChatFloat } from "../store/chat-float.store";
import { ChatFloat } from "./chat-float";
import { useUser } from "../store/user.store";
import { useAttendance } from "../store/attendance.store";
import { useQueue } from "../store/queue.store";
import { ToggleChatButton } from "./toggle-chat-button";
import { useCompany } from "../store/company.store";
import { ChatFloatCustomer } from "./chat-float/index-customer";
import { useStep } from "../store/step.store";

export function ChatContainer() {

  const { toggleOpen } = useChatFloat()
  const { email, id } = useUser()
  const { pendingAttendances, attendances } = useAttendance()
  const { queue } = useQueue()
  const { unviewedMessages: unviewedMessagesCustomer } = useStep()

  const handleCardClick = () => {
    toggleOpen()
  }

  const unviewedMessages = attendances.reduce((acc, cur) => {
    return acc + cur.messages.filter(message => !message.viewed).length
  }, 0)

  const isCustomer = id == null

  const notificationNumber = isCustomer ? unviewedMessagesCustomer : pendingAttendances.length + unviewedMessages + queue?.length


  return (
    <>
      <div>
        <ToggleChatButton handleCardClick={handleCardClick} notificationNumber={notificationNumber} />
      </div>
      {
        email && (
          isCustomer ? <ChatFloatCustomer /> : <ChatFloat />
        )
      }
    </>
  )
}
