import { useCustomer } from "@/modules/chat/application/store/customer.store"
import { CustomerContactsHeader } from "./header"
import { CustomerContactsList } from "./list"
import { useEffect, useState } from "react"
import { useContacts } from "@/modules/chat/application/store/contacts.store"
import { useChatFloat } from "@/modules/chat/application/store/chat-float.store"
import { customerProviders } from "@/modules/chat/infrastructure"
import { useUser } from "@/modules/chat/application/store/user.store"
import { useCompany } from "@/modules/chat/application/store/company.store"
import { Label } from "@/components/ui/label"
import { CustomerContactsLoadingList } from "./loading-list"

export function CustomerContacts() {

  const { currentCustomer, setCurrentCustomer } = useContacts()
  const { setCustomers } = useCustomer()
  const { environment } = useChatFloat()
  const companyContext = useCompany()
  const userContext = useUser()
  const [loading, setLoading] = useState<boolean>(true)

  if (!environment) return false

  const customerProvider = customerProviders[environment]

  const loadContacts = async () => {
    if (!companyContext.cnpj) return
    if (!currentCustomer) return

    const contacts = await customerProvider.getCustomerContacts(userContext.accessToken, companyContext.cnpj, { customerId: currentCustomer?.id })
    if (contacts.contacts.length) {
      setCurrentCustomer({ ...currentCustomer, contacts: contacts.contacts })
      setCustomers(prev => prev.map(customer => customer.id === currentCustomer.id ? { ...customer, contacts: contacts.contacts } : customer))
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!currentCustomer?.contacts?.length) {
      loadContacts()
      return
    } else {
      setLoading(false)
    }
  }, [currentCustomer])

  return (
    <div className='--ct-flex --ct-flex-col' style={{ height: 'calc(70dvh - 152px)', maxHeight: '510px' }}>
      <CustomerContactsHeader />
      {
        loading ? <CustomerContactsLoadingList /> : <CustomerContactsList />
      }
    </div>
  )
}
