import { crmAPI } from "@/modules/api/infrastructure";
import {
  AllowAuthentication,
  Authenticate,
  IAuthentication,
  LoadBasicUserData,
} from "../IAuthentication";

export class PanelAuthenticationProvider implements IAuthentication {
  getAccessToken() {
    return "Bearer " + localStorage.getItem("tokenCognitoPanel");
  }

  loadBasicUserData(): LoadBasicUserData["response"] {
    const email = localStorage.getItem("emailPanel") || "";

    const name =
      localStorage.getItem("firstNamePanel") +
        " " +
        localStorage.getItem("lastNamePanel") || "";

    return {
      user: {
        email,
        name,
        accessToken: this.getAccessToken(),
        products: [],
      },
    };
  }

  isCustomer() {
    return localStorage.getItem("isAccountantPanel") != "true";
  }

  allowAuthentication(): AllowAuthentication["response"] {
    return (
      !!localStorage.getItem("emailPanel") &&
      !!localStorage.getItem("firstNamePanel") &&
      !!localStorage.getItem("lastNamePanel") &&
      !!localStorage.getItem("documentPanel")
    );
  }

  async authenticate({
    companyCnpj,
  }: Authenticate["props"]): Promise<Authenticate["response"]> {
    const userLoggedId =
      JSON.parse(localStorage.getItem("userLogged") || "{}")?.id || -1;

    const errorResponse = {
      success: false,
      auth: null,
      cognitoSub: "",
      company: {
        alias: "",
        id: -1,
        sgbd: "",
      },
      email: "",
      identifiers: {
        userId: null,
        customerContactId: -1,
        customerId: null,
        user: {
          name: null,
          contactName: null,
        },
        sellerId: null,
        customerContactDataId: null,
        customerContactDataCnpj: null,
      },
    };

    if (userLoggedId === -1) {
      return errorResponse;
    }

    const customerCnpj = localStorage.getItem("documentPanel") || "";

    // COMPANY | CUSTOMER | CUSTOMER-USER
    const crmResponse = await crmAPI.makeRequest(this.getAccessToken, {
      path: `auth?companyCnpj=${companyCnpj}${customerCnpj.length > 0 ? "&customerCnpj=" + customerCnpj : ""}`,
      headers: {
        "qyon-customer-cnpj": companyCnpj,
      },
    });

    if (crmResponse.hasError) {
      return errorResponse;
    }
    return {
      success: true,
      ...crmResponse.response,
      identifiers: {
        ...crmResponse.response.identifiers,
        userId: this.isCustomer()
          ? null
          : crmResponse.response.identifiers.userId,
      },
    };
  }
}
