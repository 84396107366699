import { ConversationsFilters } from "./filters";
import { ConversationsList } from "./list";

export function MonitoringConversations() {
  return (
    <div className='--ct-flex --ct-flex-col --ct-gap-4 --ct-px-4' style={{ height: 'calc(70dvh - 190px)', maxHeight: '510px' }}>
      <ConversationsFilters />
      <ConversationsList />
    </div>
  );
}
