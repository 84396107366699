import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/utils/utils"

const badgeVariants = cva(
  "--ct-cursor-default --ct-inline-flex --ct-items-center --ct-border --ct-py-2 --ct-text-xs --ct-font-semibold --ct-transition-colors focus:--ct-outline-none focus:--ct-ring-2 focus:--ct-ring-ring focus:--ct-ring-offset-2 --ct-flex --ct-items-center",
  {
    variants: {
      variant: {
        default:
          "--ct-border-transparent --ct-bg-primary --ct-text-primary-foreground",
        secondary:
          "--ct-border-transparent --ct-bg-secondary hover:bg-secondary/80",
        destructive:
          "--ct-border-transparent --ct-bg-destructive --ct-text-destructive-foreground hover:--ct-bg-destructive/80",
        outline: "--ct-text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
  VariantProps<typeof badgeVariants> { }

function Badge({ className, variant, style = {}, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} style={{ borderRadius: '6px', paddingLeft: '10px', paddingRight: '10px', gap: '3px', borderStyle: 'solid', ...style }} {...props} />
  )
}

export { Badge, badgeVariants }
