import * as React from "react"
import { SVGProps } from "react"
export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="#fff"
    {...props}
  >
    <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
      <path fill="#fff" d="M1 12.5c0-6.075 4.925-11 11-11s11 4.925 11 11-4.925 11-11 11-11-4.925-11-11Zm11-9a9 9 0 1 0 0 18 9 9 0 0 0 0-18Z" />
      <path fill="#fff" d="M17.735 8.667c.22.222.22.58-.002.801l-7.402 7.367a.567.567 0 0 1-.8 0l-3.364-3.349a.567.567 0 0 1 .8-.803l2.964 2.95 7.003-6.968c.221-.22.58-.22.801.002Z" />
      <path fill="#fff" d="M16.58 8.31a1.067 1.067 0 0 1 1.506 1.513l-7.402 7.366a1.067 1.067 0 0 1-1.505 0l-3.365-3.348a1.067 1.067 0 1 1 1.505-1.512l2.612 2.6 6.65-6.618Zm.8.71a.067.067 0 0 0-.094 0l-7.002 6.968a.5.5 0 0 1-.705 0l-2.965-2.95a.067.067 0 1 0-.094.094l3.364 3.349a.067.067 0 0 0 .094 0l7.402-7.367a.067.067 0 0 0 0-.094Z" />
    </g>
  </svg>
)
