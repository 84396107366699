import { Tabs } from "@/components/ui/tabs";
import { MonitoringTab, useMonitoring } from "../../../store/monitoring.store";

export function MonitoringTabs() {

  const { tab, setTab } = useMonitoring()

  return (
    <Tabs.Container defaultTab={tab} handleChange={e => setTab(e as MonitoringTab)}>
      <Tabs.List className='--ct-w-full --ct-rounded-none --ct-flex --ct-p-0'>
        <Tabs.Item value='operators' className='--ct-flex-1 --ct-rounded-none'>Operadores Conectados</Tabs.Item>
        <Tabs.Item value='attendances' className='--ct-flex-1 --ct-rounded-none'>Atendimentos no Chat</Tabs.Item>
      </Tabs.List>
    </Tabs.Container>
  )
}
