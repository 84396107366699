import { useContacts } from "../../../store/contacts.store";
import { useCustomer } from "../../../store/customer.store";
import { CustomerContacts } from "./customer-contacts";
import { ContactList } from "./list";
import { ContactLoadingList } from "./loading-list";

export function Contacts() {

  const { currentCustomer } = useContacts()
  const { loading: isLoadingCustomers } = useCustomer()

  return (
    <div className='--ct-h-full'>
      {
        currentCustomer ? <CustomerContacts /> : isLoadingCustomers ? < ContactLoadingList /> : <ContactList />
      }
    </div>
  )
}
