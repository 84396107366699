import { MessageIcon } from "@/assets/icons/message"
import { WhatsappIcon } from "@/assets/icons/whatsapp"
import { Avatar } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { crmSocketAPI } from "@/modules/api/infrastructure"
import { useAlert } from "@/modules/chat/application/store/alert.store"
import { useAttendance } from "@/modules/chat/application/store/attendance.store"
import { useChatFloat } from "@/modules/chat/application/store/chat-float.store"
import { useCompany } from "@/modules/chat/application/store/company.store"
import { useContacts } from "@/modules/chat/application/store/contacts.store"
import { useMonitoring } from "@/modules/chat/application/store/monitoring.store"
import { useUser } from "@/modules/chat/application/store/user.store"
import { Contact } from "@/modules/chat/domain/customer"
import { wsProvider } from "@/modules/chat/infrastructure"
import { fallBackByName } from "@/utils/utils"
import formatPhone from 'libphonenumber-js'
import { useState } from "react"

type CustomerContactsRowProps = {
  contact: Contact
  loading: boolean
  setLoading: (e: boolean) => void
}

export function CustomerContactsRow({ contact, loading, setLoading }: CustomerContactsRowProps) {

  const userContext = useUser()
  const companyContext = useCompany()
  const { currentCustomer } = useContacts()
  const { setContactTarget } = useAttendance()
  const { setTab } = useChatFloat()
  const { setMessage, setType } = useAlert()

  const handleContact = async () => {

    if (!companyContext.cnpj) return
    if (!userContext.id) return
    if (!companyContext.alias) return
    if (!companyContext.id) return
    if (!currentCustomer?.id) return
    setLoading(true)

    const response = await wsProvider.getContactAttendances(
      crmSocketAPI,
      userContext.accessToken,
      companyContext.cnpj,
      {
        companyId: companyContext.id,
        email: contact.email,
        cellphone: contact.cellphone || undefined
      }
    )

    if (response.attendances.length > 0) {
      setMessage(`Este contato já está<br/>sendo atendido por: <b>${response.attendances[0].attendantName}</b>`)
      setType('warning')
      setLoading(false)
      return false
    }

    setContactTarget(contact.fantasyName || contact.name || contact.email);
    setTab('attendance')

    await wsProvider.startConversationByContact(
      crmSocketAPI,
      userContext.accessToken,
      companyContext.cnpj,
      {
        customerContact: contact,
        attendantCode: userContext.id,
        companyAlias: companyContext.alias,
        companyId: companyContext.id,
        customerCompanyId: currentCustomer?.id,
        origin: 'painel'
      }
    )
  }

  const phone = contact.cellphone || contact.tellphone || false

  const enableWhatsapp = !!contact.cellphone

  return (
    <div className='--ct-flex --ct-items-center --ct-gap-4' key={contact.id}>
      <Avatar.Container style={{ height: '50px', width: '50px' }} className='--ct-overflow-visible'>
        <Avatar.Fallback>
          {
            fallBackByName(contact.name)
          }
          {
            enableWhatsapp && (
              <div className='--ct-absolute --ct-left-0 --ct-bottom-0'>
                <WhatsappIcon />
              </div>
            )
          }
        </Avatar.Fallback>
      </Avatar.Container>
      <div className='--ct-flex-1 --ct-text-xs --ct-flex --ct-flex-col --ct-gap-1' style={{ width: '245px' }}>
        <Label className='--ct-font-bold --ct-truncate'>
          {
            contact.name
          }
        </Label>
        {
          phone && (
            <Label className='--ct-text-xs --ct-text-muted-foreground --ct-truncate'>
              {
                formatPhone(phone, 'BR')?.formatNational()
              }
            </Label>
          )
        }
      </div>
      <Button onClick={handleContact} variant='ghost' size='icon' className='--ct-text-primary' disabled={loading}>
        <MessageIcon />
      </Button>
    </div>
  )
}
