import { GetToken } from "@/modules/api/infrastructure/IApi";
import {
  GetAttendantProducts,
  GetCustomerContacts,
  GetCustomers,
  ICustomer,
} from "../ICustomer";
import { crmAPI } from "@/modules/api/infrastructure";
import { CompanyCnpj } from "../ICompany";

export class CRMCustomerProvider implements ICustomer {
  async getCustomers(
    token: GetToken,
    companyCnpj: CompanyCnpj,
  ): Promise<GetCustomers["response"]> {
    const response = await crmAPI.makeRequest(token, {
      path: "customer",
      headers: {
        "qyon-customer-cnpj": companyCnpj,
      },
    });

    return {
      customers: response.response.items || [],
    };
  }

  async getCustomerContacts(
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { customerId }: GetCustomerContacts["props"],
  ): Promise<GetCustomerContacts["response"]> {
    const response = await crmAPI.makeRequest(token, {
      path: "customer/contact/?customerId=" + customerId,
      headers: {
        "qyon-customer-cnpj": companyCnpj,
      },
    });

    return { contacts: response.response.items || [] };
  }

  async getAttendantProducts(
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { attendantId }: GetAttendantProducts["props"],
  ): Promise<GetAttendantProducts["response"]> {
    const response = await crmAPI.makeRequest(token, {
      path: "product?userId=" + attendantId,
      headers: {
        "qyon-customer-cnpj": companyCnpj,
      },
    });

    return {
      products: response.response.items || [],
    };
  }
}
