import { SVGProps } from "react"
export const QueueIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.508 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-1.667a1.667 1.667 0 1 0 0 3.334 1.667 1.667 0 0 0 0-3.334Zm0 5.334c1.328 0 2.536.405 3.424.977.445.287.826.625 1.102.993.272.364.474.8.474 1.268 0 .503-.233.906-.576 1.198-.324.273-.745.45-1.18.572-.876.245-2.034.325-3.244.325-1.21 0-2.369-.08-3.244-.325-.436-.122-.857-.299-1.18-.572-.344-.291-.576-.695-.576-1.198 0-.468.202-.904.473-1.267.277-.37.657-.708 1.102-.993.888-.574 2.096-.978 3.425-.978Zm0 1.333c-1.065 0-2.024.328-2.704.765-.34.219-.593.455-.756.672-.166.222-.207.384-.207.468 0 .049.01.1.105.18.115.098.33.209.677.306.693.194 1.702.276 2.885.276 1.182 0 2.191-.082 2.884-.276.348-.097.562-.208.678-.306.095-.08.104-.131.104-.18 0-.084-.04-.246-.207-.468a2.924 2.924 0 0 0-.756-.672c-.68-.437-1.639-.765-2.703-.765Zm5.667-1.333c.88 0 1.678.29 2.266.7.548.382 1.067.985 1.067 1.68 0 .392-.169.716-.425.951-.239.219-.54.352-.83.44-.58.175-1.326.229-2.079.229h-.135c.087-.232.136-.487.136-.762 0-.15-.015-.294-.04-.434l-.032-.138h.072c.72 0 1.306-.054 1.692-.171a.958.958 0 0 0 .303-.137c-.019-.079-.123-.307-.492-.564a2.674 2.674 0 0 0-2.22-.364 5.005 5.005 0 0 0-1.123-.986c.57-.292 1.2-.444 1.84-.444Zm-2-2.667a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-.667a.667.667 0 1 0 0 1.334.667.667 0 0 0 0-1.334Z"
      clipRule="evenodd"
    />
  </svg>
)
