import { SVGProps } from "react"

export const QyonLogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={77}
    height={28}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M46.264.95C40.53.956 35.888 5.72 35.897 11.587c.008 5.867 4.663 10.616 10.396 10.608 5.734-.009 10.376-4.771 10.368-10.639C56.653 5.691 51.998.941 46.264.95Zm.023 16.413c-3.126.004-5.662-2.585-5.666-5.782-.005-3.198 2.525-5.794 5.65-5.799 3.125-.004 5.662 2.585 5.666 5.782.005 3.198-2.525 5.794-5.65 5.799ZM20.764 11.61C20.756 5.741 16.101.992 10.368 1 4.634 1.009-.008 5.772 0 11.639c.008 5.868 4.663 10.617 10.397 10.609a10.147 10.147 0 0 0 4.07-.855l4.09 5.658 3.852-2.916-4.094-5.66a10.726 10.726 0 0 0 2.45-6.866Zm-16.04.022C4.72 8.435 7.25 5.84 10.374 5.834c3.125-.004 5.662 2.585 5.667 5.782.004 3.198-2.526 5.794-5.65 5.799-3.126.004-5.663-2.585-5.667-5.783ZM31.867 1.677l-3.547 4.91-3.56-4.9-5.948.01 7.19 9.941.013 9.792 4.69-.006-.014-9.855 7.123-9.9-5.947.008ZM72.24 1.619l.017 11.87-3.993-5.46-4.561-6.39-4.732.006.001 1.222v.344l.008 5.39v.117l.018 12.664 4.732-.006-.016-11.524v-.09l1.634 2.377 6.92 9.224.002.002 2.504-.005L77 21.357v-.002l-.002-1.383-.025-18.36-4.732.007Z"
    />
  </svg>
)
