import { cn, EmptyFunction } from "@/utils/utils";
import * as React from "react";
import { Button, ButtonProps } from "./button";
import { CheckIcon, ChevronDown } from "lucide-react";
import { Card } from "./card";
import { Tooltip } from "./tooltip";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";

type ContextValues = {
  open: boolean,
  generatedId: string,
  value?: string | string[],
  placeholder?: string
  handleChange?: (e: NonNullable<ContextValues['value']>) => void
  multiple?: boolean
}

type ContextFunctions = {
  setOpen: (e: ContextValues['open']) => void
  setValue: (e: ContextValues['value']) => void
}

type ContextRefs = {
  contentRef: React.RefObject<HTMLDivElement> | null
  triggerRef: React.RefObject<HTMLButtonElement> | null
}

type ContextProps = ContextValues & ContextFunctions & ContextRefs

const initialContext: ContextProps = {
  open: false,
  setOpen: EmptyFunction,
  generatedId: '',
  value: undefined,
  setValue: EmptyFunction,
  contentRef: null,
  triggerRef: null,
  multiple: false
}

const Context = React.createContext<ContextProps>(initialContext)

const ContextProvider = ({ children, handleChange, value: propValue, multiple }: { children?: React.ReactNode, handleChange?: ContextValues['handleChange'], value?: ContextValues['value'], multiple?: boolean }) => {

  const [open, setOpen] = React.useState<ContextValues['open']>(initialContext.open)
  const [generatedId, setGeneratedId] = React.useState<ContextValues['generatedId']>('')
  const [value, setValue] = React.useState<ContextValues['value']>(propValue || initialContext.value)
  const contentRef = React.useRef(null)
  const triggerRef = React.useRef(null)

  React.useEffect(() => {
    const randomUUID = crypto.randomUUID()
    setGeneratedId(randomUUID)
  }, [])

  React.useEffect(() => {
    setValue(propValue)
  }, [propValue])

  useOnClickOutside([contentRef, triggerRef], () => setOpen(false))

  const contextValue = React.useMemo(() => ({
    open,
    setOpen,
    generatedId,
    value,
    setValue,
    handleChange,
    contentRef,
    triggerRef,
    multiple
  }), [
    open,
    setOpen,
    generatedId,
    value,
    setValue,
    handleChange,
    contentRef,
    triggerRef,
    multiple
  ])

  return (
    <Context.Provider value={contextValue}>
      {children}
    </Context.Provider>
  )

}

const useContext = () => {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error('Select items must be used within a Select ( Container )')
  }
  return context

}

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: ContextValues['value']
  placeholder?: ContextValues['placeholder']
  handleChange?: ContextValues['handleChange']
  multiple?: boolean
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(({ children, value, multiple = false, handleChange, ...props }, ref) => {

  return (
    <ContextProvider handleChange={handleChange} multiple={multiple} value={value}>
      <div className='--ct-relative' {...props} ref={ref}>
        {children}
      </div>
    </ContextProvider>
  )
})
Container.displayName = 'Select.Container'

interface TriggerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean
  placeholder?: ContextValues['placeholder']
  children?: React.ReactElement<ButtonProps>[] | React.ReactElement<ButtonProps>
  getValue?: (v: ContextValues['value']) => string | null | undefined
  enableTooltip?: boolean
}

const Trigger = (({
  placeholder = 'Selecione um item',
  className,
  asChild = false,
  getValue,
  children,
  enableTooltip = false,
  style = {},
  ...props
}: TriggerProps) => {

  const { setOpen, open, value, triggerRef } = useContext()

  const handleOpen = () => {
    setOpen(!open)
  }

  if (asChild && children) {

    if (React.Children.count(children) > 1) {
      throw new Error('Select.Trigger asChild can only have one child')
    }

    if (React.Children.only(children).type !== Button) {
      throw new Error('Select.Trigger asChild can only have Button as child')
    }

    return React.cloneElement(React.Children.only(children), {
      ...props,
      onClick: () => setOpen(!open),
    })
  }

  const str = value ? getValue ? getValue(value) : value : placeholder

  const content = (
    <Button
      className={
        cn(
          '--ct-justify-start --ct-text-sm --ct-font-normal --ct-rounded-none --ct-text-muted-foreground hover:--ct-text-muted-foreground --ct-border-input hover:--ct-bg-transparent --ct-px-0 --ct-gap-2',
          open && '--ct-border-primary',
          className
        )
      }
      variant='ghost'
      onClick={handleOpen} {...props}
      style={{ borderBottomWidth: '2px', borderBottomStyle: 'solid', height: '40px', ...style }}
      ref={triggerRef}>
      <span className='--ct-flex-1 --ct-text-start --ct-truncate'>
        {
          str
        }
      </span>
      <span
        className={
          cn(
            '--ct-transition-all --ct-rotate-0',
            open && '--ct-rotate-180'
          )
        }>
        <ChevronDown className='--ct-size-4' />
      </span>
    </Button>
  )

  if (!enableTooltip) {
    return content
  }

  return (
    <Tooltip.Container>
      <Tooltip.Trigger>
        {content}
      </Tooltip.Trigger>
      <Tooltip.Content>
        {str}
      </Tooltip.Content>
    </Tooltip.Container>
  )

})


interface ContentProps extends React.HTMLAttributes<HTMLDivElement> {

}

const Content = (({ children, className, ...props }: ContentProps) => {

  const { open, generatedId, contentRef } = useContext()

  if (!open) return false

  return (
    <Card id={generatedId} className={
      cn('--ct-absolute --ct-min-w-fit --ct-p-2 --ct-flex --ct-flex-col --ct-gap-2 --ct-shadow-md --ct-border-input --ct-z-50', className)
    } {...props} ref={contentRef}>
      {children}
    </Card>
  )
})

interface ItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  value: string
}

const Item = React.forwardRef<HTMLButtonElement, ItemProps>(({ value, children, className, ...props }, ref) => {

  const { setValue, handleChange, value: currentValue, setOpen, multiple } = useContext()

  const handleClick = () => {

    let newValue: ContextValues['value'] = value

    if (multiple) {

      if (Array.isArray(currentValue)) {
        if (currentValue.some(v => v == value)) {
          newValue = [...currentValue].filter(v => v != value)
        } else {
          newValue = [...currentValue, value]
        }
      } else {
        newValue = [value]
      }

    }

    setValue(newValue)
    handleChange && handleChange(newValue)
    if (!multiple) {
      setOpen(false)
    }
  }

  const isSelected = Array.isArray(currentValue) ? currentValue.some(c => c == value) ? true : false : currentValue == value

  return (
    <Button ref={ref} onClick={handleClick} variant='ghost' className={
      cn(
        '--ct-text-muted-foreground hover:--ct-bg-transparent --ct-text-sm --ct-font-normal hover:--ct-bg-slate-100',
        className
      )
    }
      style={{ justifyContent: 'flex-start' }}
      {...props}
    >
      {
        isSelected && <CheckIcon className='--ct-h-4 --ch-w-4' />
      }
      <div className={cn(isSelected && '---ct-ml-2')}>
        {children}
      </div>
    </Button >
  )
})
Item.displayName = 'Select.Item'

export const Select = {
  Container,
  Trigger,
  Content,
  Item
}
