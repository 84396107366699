import { Avatar } from "@/components/ui/avatar"
import { QueueItem } from "../../../store/queue.store"
import { Label } from "@/components/ui/label"
import { Button } from "@/components/ui/button"
import { MessageIcon } from "@/assets/icons/message"
import { fallBackByName } from "@/utils/utils"
import { getDateDiff } from "@/utils/date"
import { useUser } from "../../../store/user.store"
import { useCompany } from "../../../store/company.store"
import { useChatFloat } from "../../../store/chat-float.store"
import { useAttendance } from "../../../store/attendance.store"
import { useCustomer } from "../../../store/customer.store"
import { wsProvider } from "@/modules/chat/infrastructure"
import { crmSocketAPI } from "@/modules/api/infrastructure"

type QueueRowProps = {
  item: QueueItem
}

export function QueueRow({ item }: QueueRowProps) {

  const userContext = useUser()
  const companyContext = useCompany()
  const { setTab } = useChatFloat();
  const { setConversationTarget, setPendingAttendances } = useAttendance()
  const { customers } = useCustomer();
  const { socket } = useChatFloat()

  const handleQueue = async () => {
    if (!userContext) return
    if (!companyContext) return
    if (!companyContext.cnpj) return
    if (!userContext.id) return
    if (!userContext.name) return
    if (!socket) return

    const customer = customers.find(customer => customer.name === item.customerName)

    if (!customer) return

    setTab('attendance')
    await wsProvider.startConversationByQueue(crmSocketAPI, userContext.accessToken, companyContext.cnpj, {
      attendantId: userContext.id || 0,
      attendantName: userContext.name || userContext.email || '',
      companyId: companyContext.id || 0,
      sessionId: item.sessionId
    })

    setPendingAttendances(prev => prev.filter(pending => pending.sessionId != item.sessionId))

    setConversationTarget(item.sessionId)

  }

  return (
    <div className='--ct-flex --ct-items-center --ct-gap-4'>
      <Avatar.Container style={{ height: '50px', width: '50px' }}>
        <Avatar.Fallback>{fallBackByName(item.username || item.customerName)}</Avatar.Fallback>
      </Avatar.Container>
      <div className='--ct-flex-1 --ct-text-xs --ct-flex --ct-flex-col --ct-gap-1'>
        <Label className='--ct-font-bold --ct-truncate' style={{ width: '210px' }}>
          {
            item.username
          }
        </Label>
        <Label className='--ct-text-muted-foreground --ct-text-xs --ct-truncate' style={{ width: '210px' }}>
          {
            item.customerName
          }
        </Label>
        <Label className='--ct-text-muted-foreground --ct-truncate' style={{ fontSize: '10px', width: '210px' }}>
          Há {getDateDiff({ startDate: item.createdAt })}
        </Label>
      </div>
      <Button onClick={handleQueue} variant='ghost' size='icon' className='--ct-text-primary --ct-px-2'>
        <MessageIcon />
      </Button>
    </div>
  )
}
