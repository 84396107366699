import { SettingsIcon } from "@/assets/icons/settings";

export function SettingsHeader() {
  return (
    <div className='--ct-bg-primary --ct-text-white --ct-text-sm --ct-flex --ct-items-center --ct-gap-4 --ct-px-6 --ct-py-3 --ct-font-bold --ct-tracking-wide'>
      <SettingsIcon />
      Configurações
    </div>
  )
}
