import { atom, useAtom } from "jotai";
import { Customer } from "../../domain/customer";
import { SetStoreValueAndFunction } from "../../domain/store";

type StoreValues = {
  currentCustomer: Customer | null;
};

const initialStoreValues: StoreValues = {
  currentCustomer: null,
};

const currentCustomerAtom = atom<StoreValues["currentCustomer"]>(
  initialStoreValues.currentCustomer,
);

const setCurrentCustomerAtom = atom(
  null,
  (
    _,
    set,
    newCustomer: SetStoreValueAndFunction<StoreValues["currentCustomer"]>,
  ) => set(currentCustomerAtom, newCustomer),
);

export function useContacts() {
  const [currentCustomer] = useAtom(currentCustomerAtom);
  const setCurrentCustomer = useAtom(setCurrentCustomerAtom)[1];

  return {
    currentCustomer,
    setCurrentCustomer,
  };
}
