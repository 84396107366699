
import { ReactNode, useEffect, useState } from "react"
import { authenticationProviders, companyProviders, customerProviders } from "../infrastructure"
import { ENVIRONMENT } from "../domain/environments"
import { useUser } from "./store/user.store"
import { useCompany } from "./store/company.store"
import { useCustomer } from "./store/customer.store"
import { SocketProvider } from "./socket"
import "../../../index.css"
import { useChatFloat } from "./store/chat-float.store"
import { ChatContainer } from "./components/container"
import { SocketCustomerProvider } from "./socket-customer"

type ChatProviderProps = {
  environment: ENVIRONMENT
  children?: ReactNode
}

export function ChatProvider({ children, environment }: ChatProviderProps) {

  const authenticationProvider = authenticationProviders[environment]
  const companyProvider = companyProviders[environment]
  const customerProvider = customerProviders[environment]
  const chatFloatContext = useChatFloat()

  const userContext = useUser()
  const companyContext = useCompany()
  const customerContext = useCustomer()

  const [loading, setLoading] = useState<boolean>(true)

  const startAuthentication = async () => {
    let allowAuthentication = authenticationProvider.allowAuthentication()
    while (!allowAuthentication) {
      await new Promise(resolve => setTimeout(resolve, 1000))
      allowAuthentication = authenticationProvider.allowAuthentication()
    }


    const { user } = authenticationProvider.loadBasicUserData()

    userContext.setAccessToken(user.accessToken)

    const companyData = await companyProvider.identifyCompany()

    if (!companyData) return
    companyContext.setCnpj(companyData?.cnpj)

    const authenticateData = await authenticationProvider.authenticate({
      companyCnpj: companyData?.cnpj,
    })

    if ((!authenticateData.success)) return

    companyContext.setAlias(authenticateData.company.alias)
    companyContext.setId(authenticateData.company.id)
    userContext.setId(authenticateData.identifiers.userId)

    if (authenticationProvider.isCustomer()) {
      userContext.setName(authenticateData.identifiers.user.contactName || authenticateData.identifiers.user.name || '')
    } else {
      userContext.setName(authenticateData.identifiers.user.name || authenticateData.identifiers.user.contactName || '')
    }

    userContext.setEmail(authenticateData.email)
    if (!authenticationProvider.isCustomer()) {
      companyContext.setCustomerCnpj(companyData?.customerCnpj.length > 0 ? companyData?.customerCnpj : null)
    } else {
      // CUSTOMER
      companyContext.setCustomerCnpj(authenticateData.identifiers.customerContactDataCnpj)
    }

    if (authenticationProvider.isCustomer()) {
      userContext.setCustomerContactId(authenticateData.identifiers.customerContactId)
      userContext.setCustomerId(authenticateData.identifiers.customerId || authenticateData.identifiers.customerContactDataId)
    }

    if (!authenticationProvider.isCustomer()) {
      const userProducts = await customerProvider.getAttendantProducts(user.accessToken, companyData.cnpj, { attendantId: `${authenticateData.identifiers.userId}` })
      userContext.setProducts(userProducts.products)
      userContext.setCustomerContactId(authenticateData.identifiers.customerContactId)
    }

    const customers = await customerProvider.getCustomers(user.accessToken, companyData.cnpj)
    customerContext.setCustomers(customers.customers)
    customerContext.setLoading(false)

    if (authenticationProvider.isCustomer()) {
      setLoading(false)
      return
    }

    if (authenticateData.identifiers.customerContactId) {
      const contacts = await customerProvider.getCustomerContacts(user.accessToken, companyData.cnpj, { customerId: authenticateData.identifiers.customerId || '' })
      const contactData = contacts.contacts.find(contact => contact.id === authenticateData.identifiers.customerContactId)
      if (contactData && contactData.cellphone) {
        userContext.setCellphone(contactData.cellphone)
      }
    } else {
      const customerData = customers.customers.find(customer => customer.cnpj === companyData.customerCnpj);
      if (customerData && customerData.cellphone) {
        userContext.setCellphone(customerData.cellphone)
      }
    }

    if (!authenticationProvider.isCustomer()) {
      userContext.setAttendant({
        attendant: {
          id: (authenticateData.identifiers.userId as number),
          name: authenticateData.identifiers.user.contactName || authenticateData.identifiers.user.name || '',
          status: 'online'
        },
        customUserId: `${authenticateData.company.id}-1-${authenticateData.identifiers.userId}`,
        qtdAttendances: 0,
        statusAttendance: 1,
        timeOff: '',
        departments: []
      })
    }

    setLoading(false)
  }

  useEffect(() => {
    chatFloatContext.setEnvironment(environment)
    startAuthentication()
  }, [environment])

  return (
    <div className='--ct-chat-container'>
      {children}
      <ChatContainer />
      {
        loading ? false : authenticationProvider.isCustomer() ? <SocketCustomerProvider /> : <SocketProvider />
      }
    </div>
  )
}
