import { Attendant } from "../../domain/attendant";
import { SetStoreValueAndFunction } from "../../domain/store";
import { User } from "../../domain/user";
import { atom, useAtom } from "jotai";

type StoreValues = User & {
  accessToken: string | null;
  customerContactId: number | null;
  attendant: Attendant | null;
};

const initialValues: StoreValues = {
  name: null,
  email: null,
  id: null,
  accessToken: null,
  products: [],
  customerContactId: null,
  attendant: null,
};

const nameAtom = atom<StoreValues["name"]>(initialValues.name);
const emailAtom = atom<StoreValues["email"]>(initialValues.email);
const idAtom = atom<StoreValues["id"]>(initialValues.id);
const accessTokenAtom = atom<StoreValues["accessToken"]>(
  initialValues.accessToken,
);
const productsAtom = atom<StoreValues["products"]>(initialValues.products);
const customerContactIdAtom = atom<StoreValues["customerContactId"]>(
  initialValues.customerContactId,
);
const customerIdAtom = atom<StoreValues["customerId"]>(
  initialValues.customerId,
);
const cellphoneAtom = atom<StoreValues["cellphone"]>(initialValues.cellphone);
const attendantAtom = atom<StoreValues["attendant"]>(initialValues.attendant);

const setNameAtom = atom(null, (_, set, newName: StoreValues["name"]) =>
  set(nameAtom, newName),
);
const setEmailAtom = atom(null, (_, set, newEmail: StoreValues["email"]) =>
  set(emailAtom, newEmail),
);
const setIdAtom = atom(null, (_, set, newId: StoreValues["id"]) =>
  set(idAtom, newId),
);
const setAccessTokenAtom = atom(
  null,
  (_, set, newAccessToken: StoreValues["accessToken"]) =>
    set(accessTokenAtom, newAccessToken),
);
const setProductsAtom = atom(
  null,
  (_, set, newProducts: SetStoreValueAndFunction<StoreValues["products"]>) =>
    set(productsAtom, newProducts),
);
const setCustomerContactIdAtom = atom(
  null,
  (_, set, newCustomerContactId: StoreValues["customerContactId"]) =>
    set(customerContactIdAtom, newCustomerContactId),
);
const setCustomerIdAtom = atom(
  null,
  (_, set, newCustomerId: StoreValues["customerId"]) =>
    set(customerIdAtom, newCustomerId),
);
const setCellphoneAtom = atom(
  null,
  (_, set, newCellphone: StoreValues["cellphone"]) =>
    set(cellphoneAtom, newCellphone),
);
const setAttendantAtom = atom(
  null,
  (_, set, newAttendant: StoreValues["attendant"]) =>
    set(attendantAtom, newAttendant),
);

export const useUser = () => {
  const [name] = useAtom(nameAtom);
  const [email] = useAtom(emailAtom);
  const [id] = useAtom(idAtom);
  const [accessToken] = useAtom(accessTokenAtom);
  const [products] = useAtom(productsAtom);
  const [customerContactId] = useAtom(customerContactIdAtom);
  const [customerId] = useAtom(customerIdAtom);
  const [cellphone] = useAtom(cellphoneAtom);
  const [attendant] = useAtom(attendantAtom);

  const setName = useAtom(setNameAtom)[1];
  const setEmail = useAtom(setEmailAtom)[1];
  const setId = useAtom(setIdAtom)[1];
  const setAccessToken = useAtom(setAccessTokenAtom)[1];
  const setProducts = useAtom(setProductsAtom)[1];
  const setCustomerContactId = useAtom(setCustomerContactIdAtom)[1];
  const setCustomerId = useAtom(setCustomerIdAtom)[1];
  const setCellphone = useAtom(setCellphoneAtom)[1];
  const setAttendant = useAtom(setAttendantAtom)[1];

  return {
    name,
    email,
    id,
    accessToken,
    setName,
    setEmail,
    setId,
    setAccessToken,
    products,
    setProducts,
    customerContactId,
    setCustomerContactId,
    customerId,
    setCustomerId,
    cellphone,
    setCellphone,
    attendant,
    setAttendant,
  };
};
