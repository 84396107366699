import { cn } from '@/utils/utils'
import * as React from 'react'

const Container = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div className={cn('--ct-relative', className)} {...props} ref={ref} />
))
Container.displayName = 'Tooltip.Container'

const Trigger = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div className={cn('--ct-peer/tooltip', className)} {...props} ref={ref} />
))
Trigger.displayName = 'Tooltip.Trigger'

const Content = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div className={cn('--ct-transition-all --ct-overflow-hidden --ct-text-sm --ct-size-0 peer-hover/tooltip:--ct-px-4 peer-hover/tooltip:--ct-py-2 peer-hover/tooltip:--ct-size-auto --ct-absolute ---ct-top-10 ---ct-translate-x-1/2 --ct-text-muted-foreground --ct-left-1/2 --ct-bg-slate-100 --ct-rounded-lg hover:--ct-cursor-default --ct-shadow-lg --ct-w-fit --ct-text-nowrap', className)} {...props} ref={ref} />
))
Content.displayName = 'Tooltip.Content'

export const Tooltip = {
  Container,
  Trigger,
  Content
}
