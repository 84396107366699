import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { Socket } from "socket.io-client";
import { ENVIRONMENT } from "../../domain/environments";

export type Tab = "attendance" | "monitoring" | "queue" | "contacts";

type StoreValues = {
  open: boolean;
  tab: Tab;
  socket: Socket | null;
  environment: ENVIRONMENT | null;
};

const initialValues: StoreValues = {
  open: false,
  tab: "attendance",
  socket: null,
  environment: null,
};

const openAtom = atom<StoreValues["open"]>(initialValues.open);
const tabAtom = atom<StoreValues["tab"]>(initialValues.tab);
const socketAtom = atom<StoreValues["socket"]>(initialValues.socket);
const environmentAtom = atom<StoreValues["environment"]>(
  initialValues.environment,
);

const setOpenAtom = atom(null, (_, set, newOpen: StoreValues["open"]) =>
  set(openAtom, newOpen),
);
const setTabAtom = atom(null, (_, set, newTab: StoreValues["tab"]) =>
  set(tabAtom, newTab),
);
const setSocketAtom = atom(null, (_, set, newSocket: StoreValues["socket"]) =>
  set(socketAtom, newSocket),
);
const setEnvironmentAtom = atom(
  null,
  (_, set, newEnvironment: StoreValues["environment"]) =>
    set(environmentAtom, newEnvironment),
);

export const useChatFloat = () => {
  const [open] = useAtom(openAtom);
  const [tab] = useAtom(tabAtom);
  const [socket] = useAtom(socketAtom);
  const [environment] = useAtom(environmentAtom);

  const setOpen = useAtom(setOpenAtom)[1];
  const setTab = useAtom(setTabAtom)[1];
  const setSocket = useAtom(setSocketAtom)[1];
  const setEnvironment = useAtom(setEnvironmentAtom)[1];

  const toggleOpen = () => setOpen(!open);

  return {
    open,
    tab,
    setOpen,
    toggleOpen,
    setTab,
    socket,
    setSocket,
    environment,
    setEnvironment,
  };
};
