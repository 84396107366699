import * as React from "react"
import { SVGProps } from "react"
export const BackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.167 2.47a.75.75 0 0 1 0 1.06L4.06 4.637h6.691a5.557 5.557 0 1 1 0 11.113H4.5a.75.75 0 0 1 0-1.5h6.252a4.057 4.057 0 0 0 4.054-4.215l.75-.03-.75.03a4.057 4.057 0 0 0-4.054-3.899H4.061l1.106 1.107a.75.75 0 1 1-1.06 1.06L1.72 5.917a.75.75 0 0 1 0-1.06L4.106 2.47a.75.75 0 0 1 1.06 0Z"
      clipRule="evenodd"
    />
  </svg>
)
