import * as React from "react"
import { EmptyFunction, cn } from "@/utils/utils"

type Tab = string

type ContextValues = {
  currentTab: Tab | null
}

type ContextFunctions = {
  setCurrentTab: (e: ContextValues['currentTab']) => void
  handleChange?: (e: ContextValues['currentTab']) => void
}

type ContextProps = ContextValues & ContextFunctions

const initialContextValues: ContextProps = {
  currentTab: null,
  setCurrentTab: EmptyFunction,
  handleChange: EmptyFunction
}

const Context = React.createContext<ContextProps>(initialContextValues)


const ContextProvider = ({ children, defaultTab, handleChange }: {
  children: React.ReactNode,
  defaultTab?: ContextValues['currentTab'],
  handleChange?: (e: ContextValues['currentTab']) => void
}) => {

  const [currentTab, setCurrentTab] = React.useState<ContextValues['currentTab']>(defaultTab || initialContextValues.currentTab)

  const contextValues = React.useMemo(() => ({
    setCurrentTab,
    currentTab,
    handleChange
  }), [
    setCurrentTab,
    currentTab,
    handleChange
  ])

  return (
    <Context.Provider value={contextValues}>
      {children}
    </Context.Provider>
  )
}

const useContext = () => {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('Tab items must be used within a Tab ( Container )')
  }
  return context

}


interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  defaultTab?: Tab
  handleChange: (e: ContextValues['currentTab']) => void
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(({ children, defaultTab, handleChange }, ref) => {

  return (
    <ContextProvider defaultTab={defaultTab} handleChange={handleChange}>
      <div ref={ref}>
        {children}
      </div>
    </ContextProvider>
  )
})
Container.displayName = 'Tabs.Container'

const List = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ children, style = {}, className, ...props }, ref) => (
  <div
    className={cn('--ct-flex --ct-items-center', className)}
    style={{ borderBottomWidth: '1px', ...style }}
    {...props}
    ref={ref}
  >
    {children}
  </div>
))
List.displayName = 'Tabs.List'

interface ListProps extends React.HTMLAttributes<HTMLDivElement> {
  value: Tab
}

const Item = React.forwardRef<HTMLDivElement, ListProps>(({ children, value, style = {}, className, ...props }, ref) => {

  const { handleChange, setCurrentTab, currentTab } = useContext()

  const handleClick = () => {
    setCurrentTab(value)
    handleChange && handleChange(value)
  }

  const inTab = currentTab === value

  const localStyle = {}

  if (inTab) {
    Object.assign(localStyle, {
      color: 'hsl(var(--ct-primary))',
      borderBottomStyle: 'solid',
      borderBottomWidth: '2px'
    })
  }

  Object.assign(localStyle, style)

  return (
    <div onClick={handleClick} className={cn('--ct-cursor-pointer --ct-transition-all --ct-h-9 --ct-flex --ct-items-center --ct-justify-center --ct-flex-1 --ct-text-xs --ct-text-muted-foreground', inTab && '--ct-font-bold --ct-border-b-primary --ct-bg-muted')} style={localStyle} {...props} ref={ref}>
      {children}
    </div>
  )
})
Item.displayName = 'Tabs.Item'

export const Tabs = {
  Container,
  List,
  Item
}
