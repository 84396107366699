import { Card } from "@/components/ui/card"
import { Tab, useChatFloat } from "../../store/chat-float.store"
import { ChatFloatHeader } from "./header"
import { ChatFloatTabs } from "./tabs"
import { ReactNode, useRef, useState } from "react"
import { AttendanceList } from "./attendance/list"
import { MonitoringTab } from "./monitoring"
import { motion, PanInfo, useDragControls } from 'framer-motion'
import { Contacts } from "./contact"
import { QueueTab } from "./queue"
import { useSettings } from "../../store/settings.store"
import { Settings } from "./settings"
import { AlertProvider } from "./alert/provider"
import { cn } from "@/utils/utils"

export function ChatFloat() {

  const dragContainerRef = useRef(null)
  const dragControls = useDragControls()
  const { open: openSettings } = useSettings()

  const [dragging, setDragging] = useState<boolean>(false)

  const { open, tab } = useChatFloat()
  const tabs: Record<Tab, ReactNode> = {
    attendance: <AttendanceList />,
    contacts: <Contacts />,
    monitoring: <MonitoringTab />,
    queue: <QueueTab />
  }

  const handleDragStart = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {

    // @ts-ignore
    if (event.target.getAttribute('id') == 'chat-float-drag-handler') {
      setDragging(true)
    }

    // @ts-ignore
    if (event.target.getAttribute('id') != 'chat-float-drag-handler') {

      // @ts-ignore
      dragControls.componentControls.forEach(entry => {
        entry.stop(event, info)
      })
    }
  }

  const handleDrag = () => {
    const container = document.getElementById('chat-float-drag-container')
    if (container) {
      const rect = container.getBoundingClientRect()
      if (rect.left < 0) {
      }
      if (rect.right < 0) {

      }
    }
  }

  const handleDragEnd = () => {
    setDragging(false)
  }

  return (
    <>
      <div className='--ct-absolute --ct-left-0 --ct-top-0 --ct-h-[100dvh] --ct-w-[100dvw] --ct-pointer-events-none ---ct-z-50' ref={dragContainerRef} />
      {
        open && (
          <motion.div
            className={
              '--ct-absolute --ct-z-50 --ct-mt-10 --ct-mr-5'
            }
            style={{ borderRadius: '8px', marginLeft: '-420px' }}
            dragControls={dragControls}
            drag
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            dragMomentum={false}
            onDrag={handleDrag}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            dragConstraints={dragContainerRef}
            id='chat-float-drag-container'
          >
            <Card className={
              cn(
                '--ct-space-y-2 --ct-flex --ct-flex-col --ct-border-2 --ct-border-muted --ct-relative --ct-transition-opacity',
                dragging ? '--ct-opacity-55' : '--ct-opacity-100'
              )
            } style={{ minWidth: '400px', width: '30dvw', maxWidth: '450px', height: '70dvh', maxHeight: '600px' }}>
              <ChatFloatHeader />
              <ChatFloatTabs />
              <div className='--ct-h-1 --ct-flex-1'>
                {
                  !openSettings ? tabs[tab] : <Settings />
                }
              </div>
              <AlertProvider />
            </Card>
          </motion.div>
        )
      }
    </>
  )
}
