import { ContactsIcon } from "@/assets/icons/contacts";
import { EyeIcon } from "@/assets/icons/eye";
import { HeadsetIcon } from "@/assets/icons/headset";
import { QueueIcon } from "@/assets/icons/queue";
import { Badge } from "@/components/ui/badge";
import { Tab, useChatFloat } from "../../store/chat-float.store";
import { ReactNode } from "react";
import { useQueue } from "../../store/queue.store";
import { Label } from "@/components/ui/label";
import { useSettings } from "../../store/settings.store";

type Tabs = { label: string, type: Tab, icon?: ReactNode, counter?: number }[]

export function ChatFloatTabs() {

  const { queue } = useQueue()

  const tabs: Tabs = [
    {
      label: 'Atendimento',
      type: 'attendance',
      icon: <HeadsetIcon />
    },
    {
      label: 'Monitorar',
      type: 'monitoring',
      icon: <EyeIcon />
    },
    {
      label: 'Contatos',
      type: 'contacts',
      icon: <ContactsIcon />
    },
    {
      label: 'Fila',
      type: 'queue',
      icon: <QueueIcon />,
      counter: queue.length
    }
  ]

  return (
    <div className='--ct-flex --ct-w-full --ct-max-w-full --ct-px-2 --ct-justify-center'>
      <div className='--ct-flex --ct-items-center --ct-justify-center --ct-gap-2'>
        {
          tabs.map(tab => (
            <TabBadge {...tab} key={tab.label} />
          ))
        }
      </div>
    </div>
  )
}

function TabBadge({ label, icon, type, counter = 0 }: Tabs[number]) {

  const { tab, setTab } = useChatFloat()
  const { open: openSettings, setOpen: setOpenSettings } = useSettings()

  const handleTab = () => {
    setOpenSettings(false)
    setTab(type)
  }

  return (
    <Badge className='--ct-cursor-pointer --ct-relative' variant={(tab === type && !openSettings) ? 'default' : 'secondary'} onClick={handleTab}>
      <span className='--ct-fill-current'>
        {icon}
      </span>
      {label}
      {
        (counter > 0) && (
          <div className='--ct-absolute --ct-top-0 --ct-right-0 --ct-bg-destructive --ct-rounded-full --ct-text-white --ct-grid --ct-place-items-center' style={{ height: '12px', width: '12px' }}>
            <Label style={{ fontSize: '8px' }}>
              {
                counter
              }
            </Label>
          </div>
        )
      }
    </Badge>
  )
}
