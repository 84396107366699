import { useEffect, useRef, useState } from "react"
import { io, Socket } from "socket.io-client"
import { useCompany } from "./store/company.store"
import { useUser } from "./store/user.store"
import { wsProvider } from "../infrastructure"
import { useChatFloat } from "./store/chat-float.store"
import { useSettings } from "./store/settings.store"
import { crmSocketAPI } from "@/modules/api/infrastructure"
import { useStep } from "./store/step.store"
import { Department } from "../domain/attendant"
import { useAlert } from "./store/alert.store"

export function SocketCustomerProvider() {

  const socket = useRef<Socket>(null)
  const companyContext = useCompany()
  const userContext = useUser()
  const { setSocket, setOpen, open } = useChatFloat()
  const { setAllDepartments, setStatus } = useSettings()
  const alertContext = useAlert()
  const { setCurrentStep, setSessionId, setMessages, setDepartment, department, setQueuePosition, setLoading, setUnviewedMessages, messages } = useStep()

  const initializeChat = () => {
    if (!socket.current) return false

    wsProvider.suscribeCustomerConnectResponse(socket.current, (response) => {
      const room = response[0]
      if (!room) return
      const departments = room.products.map(product => ({ productDescription: product.description, productId: product.code }))
      setAllDepartments(departments)
      checkAttendances(departments);
    })

    companyContext.id && wsProvider.subscribeRoomStatus(socket.current, {companyId: companyContext.id}, response => {
      const room = response[0]
      if (!room) return

      const departments = room.products.map(product => ({ productDescription: product.description, productId: product.code }))
      setAllDepartments(departments)
      checkAttendances(departments);
      if (room.online) {
        setStatus(prev => {
          return ({...prev, room: 'online'})
        })
      } else {
        setStatus(prev => {
          return ({...prev, room: 'offline'})
        })
      }
    })

    companyContext.id && wsProvider.subscribeRoomOnOff(socket.current, {companyId: companyContext.id}, response => {
      if (response.online) {
        setStatus(prev => ({...prev, room: 'online'}))
      } else {
        setStatus(prev => ({...prev, room: 'offline'}))
      }
    })


    wsProvider.emitLoginCustomer(socket.current, { company: companyContext, user: userContext })
  }

  const checkAttendances = async (departments: Department[]) => {
    if (!companyContext.cnpj) return false
    if (!companyContext.id) return false
    if (!userContext.email) return false

    const attendances = await wsProvider.getContactAttendances(crmSocketAPI, userContext.accessToken, companyContext.cnpj, { companyId: companyContext.id, email: userContext.email, cellphone: userContext.cellphone })
    if (attendances.attendances.length > 0) {

      const currentAttendance = attendances.attendances[0]
      const attendanceStatus = await wsProvider.getAttendanceStatus(crmSocketAPI, { sessionId: currentAttendance.sessionId })

      if (attendanceStatus.stage == 'queue' && attendanceStatus.queueData) {

        setCurrentStep('IN_QUEUE')
        setOpen(true)
        setSessionId(currentAttendance.sessionId)
        setQueuePosition(attendanceStatus.queueData?.position)
        const department = departments.find(department => department.productId == attendanceStatus.productId)
        if (!department) return

        setDepartment(department)

        socket.current && wsProvider.subscribeAttendanceStartCustomer(socket.current, { sessionId: currentAttendance.sessionId }, async () => {
          setCurrentStep('IN_CHAT')
          const messages = await wsProvider.getConversationMessages(crmSocketAPI, { id: currentAttendance.sessionId })
          setMessages(messages.messages)
          setOpen(true)

          socket.current && wsProvider.unsubscribeChatMessages(socket.current, { sessionId: currentAttendance.sessionId })
          socket.current && wsProvider.subscribeChatMessages(socket.current, { sessionId: currentAttendance.sessionId }, ({ message }) => {
            setMessages(prev => [...prev, message])
          })
        }, true)

        socket.current && wsProvider.subscribeQueueCustomer(socket.current, { sessionId: currentAttendance.sessionId }, response => {
          setLoading(false)
          setQueuePosition(response.queuePosition)
          setCurrentStep('IN_QUEUE')
        })

      } else {
        setCurrentStep('IN_CHAT')
        setOpen(true)
        setSessionId(currentAttendance.sessionId)

        const department = departments.find(department => department.productId == currentAttendance.productId)

        department && setDepartment(department)


        const messages = await wsProvider.getConversationMessages(crmSocketAPI, { id: currentAttendance.sessionId })
        setMessages(messages.messages)

        socket.current && wsProvider.unsubscribeChatMessages(socket.current, { sessionId: currentAttendance.sessionId })
        socket.current && wsProvider.subscribeChatMessages(socket.current, { sessionId: currentAttendance.sessionId }, ({ message }) => {
          setMessages(prev => [...prev, message])
        })
      }
    }
    setLoading(false)
  }

  const prepareSocket = () => {

    const localSocket = io(import.meta.env.VITE_SOCKET_API, { transports: ['websocket'], reconnection: true, path: '/chat/socket.io' })
    localSocket.on('connect', () => {
      initializeChat();
    })

    // @ts-ignore
    socket.current = localSocket
    setSocket(localSocket)

    window.addEventListener('unload', function () {
      (async () => {
        socket.current?.disconnect()
      })()
    })
  }

  useEffect(() => {
    if (socket.current) return

    prepareSocket()

  }, [companyContext.id, userContext.id])

  const enterQueue = (customerSessionId: string) => {
    if (!department) return
    if (!socket.current) return
    if (!customerSessionId) return

    wsProvider.subscribeQueueChangesCustomer(socket.current, { company: companyContext, department, sessionId: customerSessionId }, response => {
      setQueuePosition(response.queuePosition)
      if (response.queuePosition == 0) {
        socket.current && wsProvider.unsubscribeQueueChangesCustomer(socket.current, { company: companyContext, department, sessionId: customerSessionId })
      }
    })

    wsProvider.emitQueueCustomer(socket.current, { department, company: companyContext, user: userContext, sessionId: customerSessionId })
    setLoading(true)
  }

  useEffect(() => {
    if (!socket.current) return
    if (!userContext.customerId) return
    if (!companyContext.id) return

    const chatStartParams = { companyId: companyContext.id, contactId: userContext.customerContactId, customerId: userContext.customerId }

    wsProvider.subscribeActiveChatStartCustomer(socket.current, chatStartParams, response => {
      setSessionId(response.sessionId)
      setOpen(true)
      setLoading(true)
      socket.current && wsProvider.subscribeAttendanceStartCustomer(socket.current, { sessionId: response.sessionId }, async () => {
        setCurrentStep('IN_CHAT')
        const messages = await wsProvider.getConversationMessages(crmSocketAPI, { id: response.sessionId })

        setMessages(messages.messages)
        setOpen(true)
        setLoading(false)

        socket.current && wsProvider.unsubscribeChatMessages(socket.current, { sessionId: response.sessionId })
        socket.current && wsProvider.subscribeChatMessages(socket.current, { sessionId: response.sessionId }, ({ message }) => {
          setMessages(prev => [...prev, message])
        })
      }, true)

    })

    wsProvider.subscribeChatStartCustomer(socket.current, chatStartParams, response => {

      if (response.hasError) {
        setLoading(false)
        alertContext.setType('error')
        alertContext.setMessage(response.message)
        return
      }

      setSessionId(response.sessionId)
      enterQueue(response.sessionId)
      response.productId && setDepartment({ productDescription: response.productDescription, productId: `${response.productId}` })
      socket.current && wsProvider.subscribeAttendanceStartCustomer(socket.current, { sessionId: response.sessionId }, async () => {
        setCurrentStep('IN_CHAT')
        const messages = await wsProvider.getConversationMessages(crmSocketAPI, { id: response.sessionId })
        setMessages(messages.messages)
        setOpen(true)

        socket.current && wsProvider.unsubscribeChatMessages(socket.current, { sessionId: response.sessionId })
        socket.current && wsProvider.subscribeChatMessages(socket.current, { sessionId: response.sessionId }, ({ message }) => {
          setMessages(prev => [...prev, message])
        })
        setLoading(false)
      })

      socket.current && wsProvider.subscribeQueueCustomer(socket.current, { sessionId: response.sessionId }, response => {
        setLoading(false)
        setQueuePosition(response.queuePosition)
        setCurrentStep('IN_QUEUE')
      })
    },)

    return () => {
      if (!companyContext.id) return
      if (!userContext.customerId) return

      socket.current && wsProvider.unsubscribeChatStartCustomer(socket.current, chatStartParams)
      socket.current && wsProvider.unsubscribeActiveChatStartCustomer(socket.current, chatStartParams)
    }
  }, [socket, userContext.customerId, companyContext.id, department])

  useEffect(() => {
    if ((!open) && messages.length > 0) {
      setUnviewedMessages(prev => prev + 1)
    }
  }, [messages])

  useEffect(() => {
    if (open) {
      setUnviewedMessages(0)
    }
  }, [open])

  return false
}
