import { Attendance, useAttendance } from "@/modules/chat/application/store/attendance.store"
import { ChatMessageBubble } from "./message-bubble"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useEffect, useRef, useState } from "react"
import { Label } from "@/components/ui/label"
import { Loading } from "@/components/ui/loading"
import { Button } from "@/components/ui/button"
import { cn } from "@/utils/utils"

export function ConversationChat() {

  const { currentConversation, attendances, setAttendances, currentFinishedAttendance, setCurrentFinishedAttendance } = useAttendance()

  const currentAttendance = attendances.find(attendance => attendance.conversation.sessionId === currentConversation?.sessionId)
  const [previousMessageCount, setPreviousMessageCount] = useState<number>(0)

  const messagesContainerRef = useRef<HTMLDivElement>(null)

  const setAsViewed = (attendance: Attendance) => {
    setAttendances(prev => prev.map(prevAttendance => {
      if (prevAttendance.conversation.sessionId === attendance.conversation.sessionId) {
        return {
          ...prevAttendance,
          messages: prevAttendance.messages.map(message => ({ ...message, viewed: true }))
        }
      }
      return prevAttendance
    }
    ))
  }

  useEffect(() => {
    if (currentAttendance) {
      const hasUnviewedMessages = currentAttendance.messages.some(message => !message.viewed)
      hasUnviewedMessages && setAsViewed(currentAttendance)
    }
  }, [currentAttendance])

  useEffect(() => {
    if (!currentAttendance) return
    const newMessageCount = currentAttendance.messages.length

    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }

    if (previousMessageCount < newMessageCount) {

      setPreviousMessageCount(newMessageCount)

    }
  }, [currentAttendance])

  const handleEndConversation = () => {
    if (currentFinishedAttendance) {
      // @ts-ignore
      setCurrentFinishedAttendance(prev => ({ ...prev, quitting: { classification: null, subject: '', type: null } }))
      return
    }
  }


  if (currentFinishedAttendance) {
    return (
      <div className='--ct-flex --ct-flex-col --ct-flex-1 --ct-py-1 --ct-border-b-4 --ct-border-muted ' style={{ height: 'calc(70dvh - 200px)', maxHeight: '400px', borderBottomStyle: 'solid' }}>
        <ScrollArea ref={messagesContainerRef} id='scroll-chat'>
          {
            currentFinishedAttendance.messages.length == 0 && (
              <div className='--ct-h-full --ct-grid --ct-place-items-center'>
                <Loading />
              </div>
            )
          }
          <div className='--ct-flex --ct-flex-col --ct-gap-2 --ct-px-2'>
            {
              currentFinishedAttendance.messages.map((message) => (
                <ChatMessageBubble
                  key={message.message_uid || message.message_id || message.messageId}
                  content={message.message}
                  createdAt={(new Date(message.time))}
                  isMine={Boolean(message.messageIsCustomer || !!message.attendant_code)}
                  senderName={message.username}
                />
              ))
            }
            <Label className='--ct-text-center --ct-my-2 --ct-text-xs'>
              Esta conversa foi encerrada pelo cliente.
            </Label>
            <div className='--ct-w-full --ct-grid --ct-place-items-center --ct-mb-2'>
              <div className={
                cn(
                  "--ct-flex --ct-gap-2 --ct-items-center --ct-justify-center --ct-whitespace-nowrap --ct-rounded-md --ct-text-base --ct-font-medium --ct-transition-colors focus-visible:--ct-outline-none focus-visible:--ct-ring-1 focus-visible:--ct-ring-ring disabled:--ct-pointer-events-none disabled:--ct-opacity-50",
                  "--ct-bg-primary --ct-text-primary-foreground --ct-shadow hover:--ct-bg-primary-hover disabled:--ct-bg-secondary disabled:--ct-text-secondary-foreground --ct-text-sm --ct-px-4 --ct-py-1 --ct-cursor-pointer"
                )
              } onClick={handleEndConversation} style={{ color: 'white !important', backgroundColor: 'hsl(var(--ct-primary))' }}>
                Encerrar Atendimento
              </div>
            </div>
          </div>
        </ScrollArea>
      </div>
    )
  }

  if (!currentAttendance) return false

  return (
    <div className='--ct-flex --ct-flex-col --ct-flex-1 --ct-py-1 --ct-border-b-4 --ct-border-muted ' style={{ height: 'calc(70dvh - 200px)', maxHeight: '400px', borderBottomStyle: 'solid' }}>
      <ScrollArea ref={messagesContainerRef} id='scroll-chat'>
        {
          currentAttendance.messages.length == 0 && (
            <div className='--ct-h-full --ct-grid --ct-place-items-center'>
              <Loading />
            </div>
          )
        }
        <div className='--ct-flex --ct-flex-col --ct-gap-2 --ct-px-2'>
          {
            currentAttendance.messages.map((message) => (
              <ChatMessageBubble
                key={message.message_uid || message.message_id || message.messageId}
                content={message.message}
                createdAt={(new Date(message.time))}
                isMine={Boolean(message.messageIsCustomer || !!message.attendant_code)}
                senderName={message.username}
              />
            ))
          }
        </div>
      </ScrollArea>
    </div>
  )
}
