import { StarEmpty } from "@/assets/icons/start-empty"
import { Label } from "@/components/ui/label"
import { crmSocketAPI } from "@/modules/api/infrastructure"
import { useCompany } from "@/modules/chat/application/store/company.store"
import { useStep } from "@/modules/chat/application/store/step.store"
import { useUser } from "@/modules/chat/application/store/user.store"
import { wsProvider } from "@/modules/chat/infrastructure"
import { Star } from "lucide-react"
import { useEffect, useState } from "react"
import { motion } from 'framer-motion';
import { cn } from "@/utils/utils"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Button } from "@/components/ui/button"
import { RateAttendance } from "@/modules/chat/infrastructure/IWS"

export function AttendanceRate() {

  const { accessToken } = useUser()
  const { id, cnpj } = useCompany()
  const { resetStore, setLoading } = useStep()
  const [starHover, setStarHover] = useState<Record<number, number>>({})
  const { setQuestions, questions, ticketId } = useStep()
  const [answers, setAnswers] = useState<{ id: number; rate: number }[]>([])
  const [observation, setObservation] = useState<string>('')

  const loadAttendanceRates = async () => {
    if (!cnpj) return
    if (!id) return
    if (!ticketId) return

    const rates = await wsProvider.getAttendanceRates(crmSocketAPI, accessToken, cnpj, {
      companyId: id,
      ticketId
    })

    setQuestions(rates.questions.map(q => ({ ...q, rate: 0 })))

    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    cnpj && loadAttendanceRates()
  }, [cnpj])

  const handleHoverStar = (questionId: number, index: number) => {
    setStarHover(prev => ({ ...prev, [questionId]: index }))
  }

  const handleHoverStarEnd = (questionId: number) => {
    setStarHover(prev => ({ ...prev, [questionId]: 0 }))
  }

  const handleStar = (questionId: number, rate: number) => {
    if (!questions) return
    setAnswers(prev => {
      const index = prev.findIndex(a => a.id === questionId)
      if (index === -1) {
        return [...prev, { id: questionId, rate }]
      }
      prev[index].rate = prev[index].rate === rate ? 0 : rate
      return [...prev]
    })
  }

  const getStarByQuestionId = (questionId: number) => {
    if (!answers) return 0
    const answer = answers.find(a => a.id === questionId)
    return answer ? answer.rate : 0
  }

  const getStarHoverByQuestionId = (questionId: number) => {
    return starHover[questionId] || 0
  }

  const submitRate = async () => {
    if (!ticketId) return
    if (!id) return
    if (!cnpj) return

    const payload: RateAttendance['props'] = {
      companyId: id,
      observation,
      questions: answers,
      ticketId: ticketId
    }

    await wsProvider.rateAttendance(crmSocketAPI, accessToken, cnpj, payload)

    resetStore()
  }

  return (
    <ScrollArea>
      <div className='--ct-h-full --ct-px-6 --ct-py-4 --ct-flex --ct-flex-col --ct-items-center --ct-gap-4'>
        <div className='--ct-text-primary'>
          Avalie o atendimento recebido no ticket <span className='--ct-font-bold'>{ticketId}</span>
        </div>
        <div className='--ct-space-y-2 [&>div]:--ct-space-y-2'>
          {
            questions.map(question => (
              <div key={question.id}>
                <div className='--ct-w-[220px] --ct-text-center'>{question.question}</div>
                <div className='--ct-flex --ct-items-center --ct-justify-center'>
                  {
                    new Array(5).fill(0).map((_, index) => (
                      <motion.div
                        key={question.id + '-' + index}
                        onHoverEnd={() => handleHoverStarEnd(question.id)}
                        onClick={() => handleStar(question.id, index + 1)}
                        className='--ct-px-1'
                        onHoverStart={() => handleHoverStar(question.id, index + 1)}
                      >
                        <Star key={index} className={cn(
                          '--ct-transition-all hover:--ct-scale-125 hover:--ct-rotate-6',
                          getStarHoverByQuestionId(question.id) != 0 ? (index) < getStarHoverByQuestionId(question.id) ? '--ct-text-[#ffc700] --ct-fill-[#ffc700]' : '' : index < getStarByQuestionId(question.id) ? '--ct-text-[#ffc700] --ct-fill-[#ffc700]' : ''
                        )} />
                      </motion.div>
                    ))
                  }
                </div>
                <div className='--ct-flex --ct-items-center --ct-justify-between --ct-text-muted-foreground --ct-mx-10 --ct-py-4 [&>label]:--ct-text-xs'>
                  <Label>Ruim</Label>
                  <Label>Excelente</Label>
                </div>
              </div>
            ))
          }
        </div>
        <div className='--ct-w-full --ct-space-y-2 --ct-pt-4' style={{ borderTop: '1px solid #CFD1D4' }}>
          <div className='--ct-text-sm'>
            Conte-nos como podemos melhorar!
          </div>
          <textarea
            className='--ct-w-full --ct-p-2 --ct-h-[104px] --ct-resize-none --ct-rounded-md'
            style={{ border: '1px solid #CFD1D4' }}
            placeholder="Digite aqui"
            value={observation}
            onChange={e => setObservation(e.target.value)}
          />
        </div>
        <Button onClick={submitRate}>
          <span className='--ct-text-white' style={{ color: 'white !important' }}>
            Enviar
          </span>
        </Button>
      </div>
    </ScrollArea>
  )
}
