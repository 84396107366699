import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";

export function CustomerContactsLoadingList() {

  return (
    <ScrollArea>
      <div className='--ct-space-y-2 --ct-px-4 --ct-py-2'>
        {
          new Array(5).fill(0).map((_, index) => (
            <div className='--ct-flex --ct-items-center --ct-gap-4' key={index}>
              <div className='--ct-animate-pulse --ct-bg-muted' style={{ height: '50px', width: '50px', borderRadius: '8px' }} />
              <div className='--ct-flex-1 --ct-text-xs --ct-flex --ct-flex-col --ct-gap-1' style={{ width: '245px' }}>
                <Label className='--ct-font-bold --ct-truncate --ct-bg-muted --ct-animate-pulse --ct-h-5 --ct-w-20'>
                </Label>
                <Label className='--ct-h-3 --ct-nimate-pulse --ct-bg-muted' />
              </div>
            </div>
          ))
        }
      </div>
    </ScrollArea>
  )
}
