import { ScrollArea } from "@/components/ui/scroll-area";
import { AttendanceRow } from "./row";
import { useAttendance } from "../../../store/attendance.store";
import { Conversation as ConversationComponent } from "./conversation";
import { AttendanceListEmpty } from "./empty";
import { FinishedAttendanceRow } from "./finished-row";

export function AttendanceList() {

  const { attendances, currentConversation, finishedAttendances, currentFinishedAttendance } = useAttendance()

  // const handleDisconnect = (sessionId: string, ticketId: number, attendance: Attendance, emitter: Emitter) => {
  //   if (!socket) return

  //   setAttendances(prev => {
  //     return prev.filter(attendance => {
  //       return attendance.conversation.sessionId != sessionId
  //     })
  //   })

  //   setSubscribedAttendances(prev => prev.filter(session => session != sessionId))

  //   if (emitter === 'attendant') {
  //     wsProvider.unsubscribeChatMessages(socket, { sessionId })
  //     wsProvider.unsubscribeChatDisconnect(socket, { sessionId })
  //     setFinishedAttendances(prev => prev.filter(attendance => attendance.sessionId != sessionId))
  //     setCurrentConversation(prev => prev?.sessionId === sessionId ? null : prev)
  //     setCurrentFinishedAttendance(prev => prev?.sessionId === sessionId ? null : prev)
  //     setPendingAttendances(prev => prev.filter(attendance => attendance.sessionId != sessionId))
  //     return
  //   }

  //   setCurrentConversation(prev => {
  //     prev?.sessionId === sessionId ? setConversationTarget(attendance.conversation.sessionId) : null
  //     return prev?.sessionId === sessionId ? null : prev
  //   }
  //   )

  //   const currentCustomer = customers.find(customer => customer.id == attendance?.conversation.company.id)

  //   if (attendance) {

  //     const newFinishedAttendance: FinishedAttendance = {
  //       contactName: attendance.conversation.name,
  //       cpfcnpj: currentCustomer?.cnpj || '',
  //       customerId: attendance.conversation.company.id,
  //       customerName: attendance.conversation.company.name,
  //       integrationType: 'chat',
  //       messages: attendance.messages,
  //       sessionId,
  //       ticketId,
  //       username: attendance.conversation.name,
  //       quitting: false
  //     }

  //     setFinishedAttendances(prev => [...prev, newFinishedAttendance])
  //   }

  // }

  // const loadAttendance = async (attendance: Attendance): Promise<void> => {
  //   if (!socket) return

  //   setSubscribedAttendances(prev => {
  //     if (prev.indexOf(attendance.conversation.sessionId) == -1) return [...prev, attendance.conversation.sessionId]
  //     return prev
  //   })

  //   let messages: { messages: Message[] } = { messages: [] }

  //   let formattedMessages = messages.messages.map(formatMessage)
  //   setAttendances(prev => prev.map(attendance => ({ ...attendance, loading: attendance.conversation.sessionId === attendance.conversation.sessionId })))

  //   messages = await wsProvider.getConversationMessages(crmSocketAPI, { id: attendance.conversation.sessionId })
  //   formattedMessages = messages.messages.map(formatMessage)
  //   setAttendances(prev => prev.map(attendance => {
  //     if (attendance.conversation.sessionId === attendance.conversation.sessionId) {
  //       return { ...attendance, messages: formattedMessages }
  //     }
  //     return attendance
  //   }))


  //   wsProvider.subscribeAttendanceStartResponse(socket, { sessionId: attendance.conversation.sessionId }, (data) => {

  //     setAttendances(prev => prev.map(at => {
  //       if (at.conversation.sessionId === attendance.conversation.sessionId) {
  //         return { ...at, messages: [...at.messages, formatMessage(data.message)] }
  //       }
  //       return at
  //     }))

  //   })
  //   wsProvider.unsubscribeChatDisconnect(socket, { sessionId: attendance.conversation.sessionId })
  //   wsProvider.subscribeChatDisconnect(socket, { sessionId: attendance.conversation.sessionId }, ({ ticketId, emitter }) => handleDisconnect(attendance.conversation.sessionId, ticketId, attendance, emitter))

  //   wsProvider.unsubscribeChatMessages(socket, { sessionId: attendance.conversation.sessionId })
  //   wsProvider.subscribeChatMessages(socket, { sessionId: attendance.conversation.sessionId }, (data) => {

  //     setAttendances(prev => {

  //       const newMessage = { ...data.message, viewed: data.message.messageIsCustomer === 1 }

  //       return prev.map(attendance => {
  //         if (attendance.conversation.sessionId === attendance.conversation.sessionId) {
  //           return { ...attendance, messages: [...attendance.messages, newMessage] }
  //         }
  //         return attendance
  //       })
  //     })
  //   })
  //   return

  // }

  // const loadFinishedAttendance = async (attendance: FinishedAttendance) => {
  //   const messages = await wsProvider.getConversationMessages(crmSocketAPI, {
  //     id: attendance.sessionId
  //   })

  //   setFinishedAttendances(prev => prev.map(prevAttendance => {
  //     if (prevAttendance.sessionId === attendance.sessionId) {
  //       return { ...prevAttendance, messages: messages.messages }
  //     }
  //     return prevAttendance
  //   }))

  //   socket && wsProvider.unsubscribeChatMessages(socket, { sessionId: attendance.sessionId })

  //   socket && wsProvider.subscribeChatDisconnect(socket, { sessionId: attendance.sessionId }, (data) => {
  //     if (data.emitter === 'attendant') {
  //       setCurrentFinishedAttendance(prev => prev?.sessionId === attendance.sessionId ? null : prev)
  //     } else {
  //       setFinishedAttendances(prev => prev.filter(finished => finished.sessionId != attendance.sessionId))
  //     }
  //   })

  // }

  // useEffect(() => {
  //   const withoutMessagesAttendances = attendances.filter(attendance => {
  //     return attendance.messages.length === 0 && (!attendance.loading) && !subscribedAttendances.some(sessionId => sessionId === attendance.conversation.sessionId)
  //   })
  //   withoutMessagesAttendances.forEach(loadAttendance)
  // }, [attendances, socket, subscribedAttendances])

  // useEffect(() => {
  //   const withoutMessagesAttendances = finishedAttendances.filter(attendance => attendance.messages.length == 0)
  //   withoutMessagesAttendances.forEach(loadFinishedAttendance)
  // }, [finishedAttendances])

  return (
    <div className='--ct-flex --ct-flex-col --ct-gap-4' style={{ height: 'calc(70dvh - 200px)', maxHeight: '470px' }}>
      <div className='--ct-h-1 --ct-flex-1 --ct-flex --ct-flex-col'>
        {
          (currentConversation || currentFinishedAttendance) ? <ConversationComponent /> : attendances.length > 0 || finishedAttendances.length > 0 ? (
            <ScrollArea>
              <div className='--ct-space-y-1 --ct-px-8'>
                {
                  finishedAttendances.map((attendance, idx) => (
                    <FinishedAttendanceRow
                      key={attendance.ticketId + '-' + idx}
                      attendance={attendance}
                    />
                  ))
                }
                {
                  attendances.map((attendance, idx) => (
                    <AttendanceRow
                      key={attendance.conversation.sessionId + '-' + idx}
                      attendance={attendance}
                    />
                  ))
                }
              </div>
            </ScrollArea>
          ) : <AttendanceListEmpty />
        }
      </div>

    </div>
  )
}
