import { Avatar } from "@/components/ui/avatar";
import { Label } from "@/components/ui/label";
import { useMemo } from "react";
import { FinishedAttendance, useAttendance } from "../../../store/attendance.store";
import { fallBackByName } from "@/utils/utils";
import { useChatFloat } from "../../../store/chat-float.store";
import { useCompany } from "../../../store/company.store";
import { useUser } from "../../../store/user.store";

export type FinishedAttendanceRow = {
  attendance: FinishedAttendance
}

export function FinishedAttendanceRow({ attendance }: FinishedAttendanceRow) {

  const { socket } = useChatFloat()
  const companyContext = useCompany()
  const { setCurrentFinishedAttendance } = useAttendance()

  const avatar = useMemo(() => ({
    fallBack: fallBackByName(attendance.username),
    url: null
  }), [attendance])

  const handleClick = () => {
    if (!socket) return
    if (!companyContext) return

    setCurrentFinishedAttendance(attendance)
  }

  return (
    <div className='--ct-flex --ct-items-center --ct-gap-4 --ct-w-full' style={{ height: '66px' }} onClick={handleClick}>
      <div className='--ct-relative'>
        <Avatar.Container style={{ height: '50px', width: '50px' }}>
          <Avatar.Fallback>{avatar.fallBack}</Avatar.Fallback>
          <Avatar.Image src={avatar.url || 'invalid link'} />
        </Avatar.Container>
      </div>

      <div className='--ct-flex --ct-flex-col --ct-h-full --ct-justify-evenly --ct-w-full --ct-py-3'>

        <div className='--ct-w-full --ct-flex --ct-items-center --ct-justify-between'>

          <Label className='--ct-font-bold --ct-truncate' style={{ width: '245px' }}>
            {
              attendance.username
            }
          </Label>

          <Label className='--ct-tracking-wide'>
            { }
          </Label>

        </div>

        <div className='--ct-w-full --ct-flex --ct-items-center --ct-justify-between'>
          <div className='--ct-text-xs --ct-truncate' style={{ width: '265px' }}>
            <Label className={'--ct-text-muted-foreground'}>
              Conversa encerrada pelo cliente.
            </Label>
          </div>
        </div>
      </div>
    </div>
  )
}
