import { Socket } from "socket.io-client";
import {
  ChangeAttendanceAmount,
  ChangeAttendantDepartments,
  ChangeRoomStatus,
  ChangeUserStatus,
  EmitAttendanceQuit,
  EmitChatStartCustomer,
  EmitFinishAttendanceCustomer,
  EmitLoginAttendant,
  EmitLoginCustomer,
  EmitQueueCustomer,
  GetAllDepartments,
  GetAllUserStatus,
  GetAttendanceRates,
  GetAttendanceStatus,
  GetContactAttendances,
  GetConversationMessages,
  GetConversations,
  GetDataMonitor,
  GetFinishedConversations,
  GetQueue,
  GetQuitAttendanceClassifications,
  GetQuitAttendanceTypes,
  GetRoomStatus,
  GetUserStatus,
  IWS,
  RateAttendance,
  SendMessage,
  SendMessageCustomer,
  StartConversationByClientSide,
  StartConversationByContact,
  StartConversationByQueue,
  SubscribeActiveChatStartCustomer,
  SubscribeAttendanceStartCustomer,
  SubscribeAttendanceStartResponse,
  SubscribeAttendantFinishAttendanceCustomer,
  SubscribeChatDisconnect,
  SubscribeChatMessages,
  SubscribeChatMonitor,
  SubscribeChatStartCustomer,
  SubscribeCustomerConnectResponse,
  SubscribeQueueChangesCustomer,
  SubscribeQueueConnect,
  SubscribeQueueCustomer,
  SubscribeRoomOnOff,
  SubscribeRoomStatus,
  SubscribeStartConversation,
  UnsubscribeChatMessages,
  UnsubscribeQueueChangesCustomer,
  UnsubscribeQueueConnect,
} from "../IWS";
import { GetToken } from "@/modules/api/infrastructure/IApi";
import { CompanyCnpj } from "../ICompany";
import { FinishedAttendance } from "../../application/store/attendance.store";
import { OnlineStatus } from "../../domain/constants";

export class CRMSocketProvider implements IWS {
  emitLoginAttendant(
    socket: EmitLoginAttendant["socket"],
    { user, company }: EmitLoginAttendant["payload"],
    callBack?: EmitLoginAttendant["callBack"],
  ) {
    const payload = {
      identifiers: {
        sessionId: null,
        attendantSocketClientId: socket.id,
      },
      company_code: company.id,
      company_alias: company.alias,
      attendantCode: user.id,
      action: "login",
      hash: socket.id,
      roomCode: 1,
      attendantName: user.name || user.email,
      statusAttendant: 0,
      lastUpdate: new Date().toISOString(),
    };

    callBack &&
      socket.once(
        "/onOffAtendants/response/" + company.id + "/1/undefined",
        callBack,
      );

    socket.emit("/loginAttendant", payload);
  }

  emitLoginCustomer(
    socket: EmitLoginCustomer["socket"],
    { company, user }: EmitLoginCustomer["payload"],
  ) {
    const topic = "/connect";

    const payload = {
      company_code: company.id,
      company_alias: company.alias,
      client_code: user.id,
      hash: socket.id,
      identifiers: {
        customerSocketClientId: socket.id,
      },
    };

    socket.emit(topic, payload);
  }

  emitChatStartCustomer(
    socket: Socket,
    { company, user, department }: EmitChatStartCustomer["payload"],
  ) {
    const topic = "/chat/start";

    const payload = {
      company_code: company.id,
      company_alias: company.alias,
      email: user.email,
      username: user.name,
      contactId: user.customerContactId,
      room_code: 1,
      product_code: department.productId,
      customer_code: user.customerId,
      hash: socket.id,
      identifiers: {
        customerSocketClientId: socket.id,
        sessionId: "",
      },
    };

    socket.emit(topic, payload);
  }

  emitQueueCustomer(
    socket: Socket,
    { company, user, department, sessionId }: EmitQueueCustomer["payload"],
  ) {
    const topic = "/chat/queue";

    const payload = {
      company_alias: company.alias,
      company_code: company.id,
      contactId: user.customerContactId,
      customer_code: `${user.customerId}`,
      email: user.email,
      identifiers: {
        sessionId,
      },
      product_code: department.productId,
      room_code: 1,
      session_id: sessionId,
      username: user.name,
    };

    socket.emit(topic, payload);
  }

  subscribeQueueCustomer(
    socket: SubscribeQueueCustomer["socket"],
    props: SubscribeQueueCustomer["props"],
    callBack: SubscribeQueueCustomer["callBack"],
    once?: SubscribeQueueCustomer["once"],
  ) {
    const topic = "/enterQueue/" + props.sessionId;

    const formatData = (data: { queue_id: string; queue_position: number }) => {
      callBack({
        queueId: data.queue_id,
        queuePosition: data.queue_position + 1,
      });
    };

    if (once) {
      socket.once(topic, formatData);
    } else {
      socket.on(topic, formatData);
    }
  }

  suscribeCustomerConnectResponse(
    socket: SubscribeCustomerConnectResponse["socket"],
    callBack: SubscribeCustomerConnectResponse["callBack"],
    once?: SubscribeCustomerConnectResponse["once"],
  ) {
    const topic = "/connect/response/" + socket.id;

    if (once) {
      socket.once(topic, (data) => {
        callBack(data);
      });
    } else {
      socket.on(topic, (data) => {
        callBack(data);
      });
    }
  }

  subscribeRoomStatus(
    socket: SubscribeRoomStatus["socket"],
    { companyId }: SubscribeRoomStatus["props"],
    callBack: SubscribeRoomStatus["callBack"],
    once?: SubscribeRoomStatus["once"],
  ) {
    const topic = "/connect/response/company/" + companyId;

    if (once) {
      socket.once(topic, (data) => {
        callBack({ ...data, online: data.online == 1 });
      });
    } else {
      socket.on(topic, (data) => {
        callBack({ ...data, online: data.online == 1 });
      });
    }
  }

  subscribeRoomOnOff(
    socket: SubscribeRoomOnOff["socket"],
    { companyId }: SubscribeRoomOnOff["props"],
    callBack: SubscribeRoomOnOff["callBack"],
    once?: SubscribeRoomOnOff["once"],
  ) {
    const topic = `company/${companyId}/sala/1/status`;

    if (once) {
      socket.once(topic, (data) => {
        callBack({ ...data, online: data.online == 1 });
      });
    } else {
      socket.on(topic, (data) => {
        callBack({ ...data, online: data.online == 1 });
      });
    }
  }

  subscribeChatMonitor(
    socket: Socket,
    { companyId }: SubscribeChatMonitor["props"],
    callBack: SubscribeChatMonitor["callBack"],
    once?: SubscribeChatMonitor["once"],
  ) {
    const topic = "chat/monitor/response/company/" + companyId + "/room/1";

    if (once) {
      socket.once(topic, (data) => {
        callBack(data);
      });
    } else {
      socket.on(topic, (data) => {
        callBack(data);
      });
    }
  }

  async getConversationMessages(
    api: GetConversationMessages["api"],
    { id }: GetConversationMessages["props"],
  ) {
    const response = await api.makeRequest(null, {
      path: "/messages/" + id,
    });
    return response.response;
  }

  async subscribeChatMessages(
    socket: Socket,
    { sessionId }: SubscribeChatMessages["props"],
    callBack: SubscribeChatMessages["callBack"],
    once?: SubscribeChatMessages["once"],
  ) {
    const topic = "/chat/sala/1/" + sessionId + "/message";

    if (once) {
      socket.once(topic, (data) => callBack({ message: data }));
    } else {
      socket.on(topic, (data) => callBack({ message: data }));
    }
  }

  unsubscribeChatMessages(
    socket: UnsubscribeChatMessages["socket"],
    props: UnsubscribeChatMessages["props"],
  ) {
    socket.removeAllListeners("/chat/sala/1/" + props.sessionId + "/message");
  }

  async subscribeChatDisconnect(
    socket: Socket,
    { sessionId }: SubscribeChatDisconnect["props"],
    callBack: SubscribeChatDisconnect["callBack"],
    once?: SubscribeChatDisconnect["once"],
  ) {
    const topic = "/chat/sala/1/" + sessionId + "/disconnect";

    if (once) {
      socket.once(topic, (data) => callBack({ ...data }));
    } else {
      socket.on(topic, (data) => {
        callBack({ ...data });
      });
    }
  }

  subscribeAttendanceStartResponse(
    socket: Socket,
    { sessionId }: SubscribeAttendanceStartResponse["props"],
    callBack: SubscribeAttendanceStartResponse["callBack"],
  ) {
    const topic = "/chat/sala/1/" + sessionId + "/attendance/start/response";

    socket.once(topic, (data) => callBack({ message: data }));
  }

  unsubscribeChatDisconnect(
    socket: Socket,
    { sessionId }: SubscribeChatDisconnect["props"],
  ) {
    const topic = "/chat/sala/1/" + sessionId + "/disconnect";
    socket.removeAllListeners(topic);
  }

  subscribeStartConversation(
    socket: SubscribeStartConversation["socket"],
    { userId, companyId }: SubscribeStartConversation["props"],
    callBack: SubscribeStartConversation["callBack"],
    once?: SubscribeStartConversation["once"],
  ) {
    const topic = `/attendant/${userId}/company/${companyId}`;

    const format = (data: any) => {
      callBack({
        action: "newAttendance",
        attendant: {
          id: data.attendantCode,
          name: "",
        },
        company: {
          id: data.customer_code,
          name: data.customer,
        },
        department: data.productId
          ? {
              productDescription: data.product_name,
              productId: data.pxroductId,
            }
          : null,
        name: data.username,
        room: {
          id: 1,
        },
        sessionId: data.session_id,
        startConvesationDate: data.created_at,
        typeDistribuit: data.typeDistribuit,
      });
    };

    if (once) {
      socket.once(topic, (data) => format(data));
    } else {
      socket.on(topic, (data) => format(data));
    }
  }

  sendMessage(
    socket: SendMessage["socket"],
    { company, message, sessionId, ticketId, user }: SendMessage["props"],
  ) {
    const topic = "/chat/message";

    const generatedMessageId = `${company.id}${ticketId || "0000"}${new Date().getTime().toString()}`;

    const payload = {
      identifiers: {
        sessionId,
        customerSocketClientId: socket.id,
      },
      company_code: company.id,
      company_alias: company.alias,
      sessionId,
      room_code: 1,
      message,
      codeAttendant: user.id,
      username: user.name || user.email,
      messageFile: "",
      message_id: generatedMessageId,
    };

    socket.emit(topic, payload);
  }

  sendMessageCustomer(
    socket: SendMessageCustomer["socket"],
    {
      companyId,
      companyAlias,
      message,
      sessionId,
      user,
    }: SendMessageCustomer["props"],
  ) {
    const topic = "/chat/message";

    const generatedMessageId = `${companyId}0000${new Date().getTime().toString()}`;

    const payload = {
      codeAttendant: "",
      company_alias: companyAlias,
      company_code: companyId,
      identifiers: {
        sessionId,
        customerSocketClientId: socket.id,
      },
      message,
      messageFile: "",
      message_id: generatedMessageId,
      sessionId: sessionId,
      username: user.name || user.email,
      room_code: 1,
    };

    socket.emit(topic, payload);
  }

  subscribeAttendantFinishAttendanceCustomer(
    props: SubscribeAttendantFinishAttendanceCustomer["socket"],
    { sessionId }: SubscribeAttendantFinishAttendanceCustomer["props"],
    callBack: SubscribeAttendantFinishAttendanceCustomer["callBack"],
    once?: SubscribeAttendantFinishAttendanceCustomer["once"],
  ) {
    const topic = `/chat/sala/1/${sessionId}/disconnect`;

    const formatData = (data: any) => {
      // if (data.emitter == "attendant") {
      const { ticketId } = data;
      callBack({ ticketId });
      // }
    };

    if (once) {
      props.once(topic, formatData);
    } else {
      props.on(topic, formatData);
    }
  }

  async getDataMonitor(
    api: GetDataMonitor["api"],
    { companyId }: GetDataMonitor["props"],
  ): Promise<GetDataMonitor["response"]> {
    const response = await api
      .makeRequest(null, {
        path:
          "/chat/monitor/getDataMonitor?companyId=" + companyId + "&roomId=1",
      })
      .then((res) => res.response);

    return {
      attendants: response.attendants || [],
      currentConversations: response.currentConversations || [],
    };
  }

  async startConversationByContact(
    api: StartConversationByContact["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    payload: StartConversationByContact["props"],
  ): Promise<StartConversationByContact["response"]> {
    const response = await api.makeRequest(token, {
      path: "/attendant/force-attendance",
      method: "POST",
      body: {
        ...payload,
        typeDistribuit: "callCustomerToAttendance",
        roomId: 1,
      },
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    return response.response;
  }

  startConversationByClientSide(
    socket: StartConversationByClientSide["socket"],
    {
      attendantId,
      attendantName,
      companyAlias,
      companyId,
      sessionId,
      username,
      customerId,
      typeDistribuit = null,
    }: StartConversationByClientSide["props"],
  ) {
    const topic = "/chat/attendance/start";

    const payload = {
      attendantName,
      codeAttendant: attendantId,
      company_alias: companyAlias,
      company_code: companyId,
      customer_code: customerId,
      identifiers: {
        sessionId,
        customerSocketClientId: socket.id,
      },
      messageFile: "",
      room_code: 1,
      sessionId,
      typeDistribuit,
      username,
    };

    socket.emit(topic, payload);
  }

  subscribeQueueConnect(
    socket: SubscribeQueueConnect["socket"],
    { companyId, productId }: SubscribeQueueConnect["props"],
    callBack: SubscribeQueueConnect["callBack"],
    once?: SubscribeQueueConnect["once"],
  ) {
    const topic = "/attendant/enterQueue/" + companyId + "-1-" + productId;

    if (once) {
      socket.once(topic, (data) =>
        callBack({
          ...data,
          sessionId: data.session_id,
          customerName: data.name_customer,
          productName: data.product_Name,
        }),
      );
    } else {
      socket.on(topic, (data) =>
        callBack({
          ...data,
          sessionId: data.session_id,
          customerName: data.name_customer,
          productName: data.product_Name,
          createdAt: data.created_at,
        }),
      );
    }
  }

  unsubscribeQueueConnect(
    socket: UnsubscribeQueueConnect["socket"],
    { companyId, productId }: UnsubscribeQueueConnect["props"],
  ) {
    socket.removeAllListeners(
      "/attendant/enterQueue/" + companyId + "-1-" + productId,
    );
  }

  async getQueue(
    api: GetQueue["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { companyId, attendantId }: GetQueue["props"],
  ) {
    const response = await api.makeRequest(token, {
      path: "/queues/" + attendantId + "?room_code=1&company_code=" + companyId,
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    // const queueTarget = companyId + "-1-" + productId;
    // const queue = response.response.queue.filter(
    //   (queue: { queue_id: string }) => queue.queue_id == queueTarget,
    // );
    const queue = response.response.queue;

    return {
      queue: queue.map((item: any) => ({
        ...item,
        customerName: item.name_customer,
        queueId: item.queue_id,
        sessionId: item.session_id,
        productName: item.product_name,
        createdAt: item.created_at,
      })),
    };
  }

  async startConversationByQueue(
    api: StartConversationByQueue["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    {
      sessionId,
      attendantId,
      attendantName,
      companyId,
    }: StartConversationByQueue["props"],
  ) {
    const payload = {
      attendantName,
      attendant_code: attendantId,
      companyId,
      company_code: companyId,
      room_code: 1,
    };

    const response = await api.makeRequest(token, {
      path: "/queues/" + sessionId,
      method: "POST",
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
      body: payload,
    });

    return response.response;
  }

  async getAllUserStatus(
    api: GetAllUserStatus["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
  ): Promise<GetAllUserStatus["response"]> {
    const response = await api.makeRequest(token, {
      path: "reason",
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    return {
      allStatus: response.response.items,
    };
  }

  async changeUserStatus(
    api: ChangeUserStatus["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { status, attendantId, companyId }: ChangeUserStatus["props"],
  ) {
    api.makeRequest(token, {
      method: "POST",
      path: "/attendant/status/" + attendantId,
      body: {
        attendant_status: status.id == 0 ? 1 : 0,
        company_code: companyId,
        reason: status.id,
        room_id: 1,
      },
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });
  }

  async getUserStatus(
    api: GetUserStatus["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { attendantId }: GetUserStatus["props"],
  ): Promise<GetUserStatus["response"]> {
    const response = await api.makeRequest(token, {
      path: "chat/attendant/" + attendantId,
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    const status = response.response.status;
    const attendanceAmount = response.response.attendanceAmount;
    if (Array.isArray(status)) {
      if (status.length == 0) {
        return {
          status: OnlineStatus,
          attendanceAmount,
        };
      }
      const description = status[status.length - 1].reason.description;
      const id = status[status.length - 1].reason.id;

      return {
        status: {
          active: true,
          description,
          id,
        },
        attendanceAmount,
      };
    }

    // TODO:: VERIFICAR COMO FUNCIONA NO BACKEND
    return {
      status: {
        active: false,
        description: "",
        id: 0,
      },
      attendanceAmount,
    };
  }

  async changeRoomStatus(
    api: ChangeRoomStatus["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { attendantId, companyId, status }: ChangeRoomStatus["props"],
  ) {
    api.makeRequest(token, {
      path: "/roomStatus?company_id=" + companyId,
      method: "PUT",
      body: {
        attendant_code: attendantId,
        room_id: 1,
        status: status == "offline" ? 0 : 1,
      },
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });
  }

  async getRoomStatus(
    api: GetRoomStatus["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
  ): Promise<GetRoomStatus["response"]> {
    const response = await api.makeRequest(token, {
      path: "room/1",
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    return {
      roomStatus: response.response.online == 1 ? "online" : "offline",
    };
  }

  async getAllDepartments(
    api: GetAllDepartments["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { attendantId }: GetAllDepartments["props"],
  ): Promise<GetAllDepartments["response"]> {
    const response = await api.makeRequest(token, {
      path: "product?userId=" + attendantId,
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    return {
      departments:
        response.response.items?.map((item: Record<string, string>) => ({
          ...item,
          productDescription: item.description,
        })) || [],
      selectedDepartments: response.response.productsSelected || [],
    };
  }

  async changeAttendantDepartments(
    api: ChangeAttendantDepartments["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { attendantId, departments }: ChangeAttendantDepartments["props"],
  ) {
    const strDepartmnents = encodeURI(
      JSON.stringify(
        departments.map((department) => ({
          id: department.productId,
          description: department.productDescription,
          selected: department.selected,
        })),
      ),
    );

    api.makeRequest(token, {
      path:
        "product/chat?userId=" +
        attendantId +
        "&products=" +
        strDepartmnents +
        "&action=insert",
      method: "POST",
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });
  }

  async changeAttendanceAmount(
    api: ChangeAttendanceAmount["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { attendantId, attendanceAmount }: ChangeAttendanceAmount["props"],
  ) {
    api.makeRequest(token, {
      path:
        "user/attendanceAmount?userId=" +
        attendantId +
        "&attendanceAmount=" +
        attendanceAmount,
      method: "PUT",
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });
  }

  async getQuitAttendanceTypes(
    api: GetQuitAttendanceTypes["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { companyId }: GetQuitAttendanceTypes["props"],
  ): Promise<GetQuitAttendanceTypes["response"]> {
    const response = await api.makeRequest(token, {
      path: "/type/?company_code=" + companyId,
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    return {
      types: response.response.types,
    };
  }

  async getQuitAttendanceClassifications(
    api: GetQuitAttendanceClassifications["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { companyId }: GetQuitAttendanceClassifications["props"],
  ): Promise<GetQuitAttendanceClassifications["response"]> {
    const response = await api.makeRequest(token, {
      path: "/classification/?company_code=" + companyId,
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    return {
      classifications: response.response.classifications,
    };
  }

  emitAttendanceQuit(
    socket: EmitAttendanceQuit["socket"],
    {
      companyId,
      sessionId,
      attendantId,
      classificationId,
      companyAlias,
      subject,
      typeId,
    }: EmitAttendanceQuit["props"],
  ) {
    const payload = {
      action: "removeQueue",
      attendantCode: attendantId,
      classification: classificationId,
      company_alias: companyAlias,
      company_code: companyId,
      identifiers: {
        sessionId,
        customerSocketClientId: socket.id,
      },
      isAttendantCallingClient: null,
      quitForInactivity: null,
      room_code: 1,
      sessionId,
      subject,
      type: typeId,
      emitter: "attendant",
    };

    socket.emit("/chat/quit", payload);
  }

  async getFinishedAttendances(
    api: GetFinishedConversations["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { attendantId, companyId }: GetFinishedConversations["props"],
  ): Promise<GetFinishedConversations["response"]> {
    const response = await api.makeRequest(token, {
      path:
        "/attendances?attendant_code=" +
        attendantId +
        "&company_code=" +
        companyId +
        "&room_code=1",
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    const filtered = response.response.attendances?.filter(
      (attendance: Record<string, string>) =>
        attendance.status_attendance == "atendimento_encerrado",
    );

    const attendances = filtered.map((attendance: any) => ({
      messages: [],
      contactName: attendance.contactName,
      cpfcnpj: attendance.cpfcnpj,
      customerName: attendance.customer,
      customerId: attendance.customer_code,
      integrationType: attendance.integrationType,
      ticketId: attendance.ticket_code,
      username: attendance.username,
      quitting: null,
      sessionId: attendance.session_id,
    })) as FinishedAttendance[];

    return {
      attendances: attendances,
    };
  }

  async getConversations(
    api: GetConversations["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { companyId, attendant }: GetConversations["props"],
  ): Promise<GetConversations["response"]> {
    const response = await api.makeRequest(token, {
      path:
        "/attendances?attendant_code=" +
        attendant.id +
        "&company_code=" +
        companyId +
        "&room_code=1",
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    return {
      attendances:
        response?.response?.attendances
          .filter(
            (attendance: any) =>
              attendance.status_attendance !== "atendimento_encerrado",
          )
          .map((attendance: any) => ({
            company: {
              id: attendance.customer_code,
              name: attendance.customer,
            },
            room: {},
            name: attendance.username,
            department: {
              id: attendance.product_code,
              description: attendance.product_name,
            },
            attendant: {
              id: attendant.id,
              name: attendant.name || attendant.email,
            },
            sessionId: attendance.session_id,
            startConversationDate: attendance.registerDate,
          })) || [],
    };
  }

  async getContactAttendances(
    api: GetContactAttendances["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    { companyId, email, cellphone }: GetContactAttendances["props"],
  ): Promise<GetContactAttendances["response"]> {
    const sessionId = cellphone ? `SID${companyId}N${cellphone}` : false;

    const response = await api.makeRequest(token, {
      path:
        "/customer/attendance?roomId=1&companyId=" +
        companyId +
        "&email=" +
        email +
        // (sessionId ? `&sessionId=${sessionId}` : ""),
        "",
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    return {
      attendances:
        response.response?.customerInAttendance.map((attendance: any) => ({
          ...attendance,
          sessionId: attendance.session_id,
          productId: attendance.product_code,
        })) || [],
    };
  }

  async getAttendanceRates(
    api: GetAttendanceRates["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    props: GetAttendanceRates["props"],
  ): Promise<GetAttendanceRates["response"]> {
    const response = await api.makeRequest(token, {
      path: `/rate?companyId=${props.companyId}&ticketId=${props.ticketId}`,
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });

    return response.response;
  }

  subscribeActiveChatStartCustomer(
    socket: SubscribeActiveChatStartCustomer["socket"],
    props: SubscribeActiveChatStartCustomer["props"],
    callBack: SubscribeActiveChatStartCustomer["callBack"],
    once?: SubscribeActiveChatStartCustomer["once"],
  ) {
    const topic = `/chat/sala/1/company/${props.companyId}/customer/${props.customerId}/contact/${props.contactId || ""}`;

    const formatData = (data: { sessionId: string }) => {
      callBack({
        sessionId: data.sessionId,
      });
    };

    if (once) {
      socket.once(topic, formatData);
    } else {
      socket.on(topic, formatData);
    }
  }

  unsubscribeActiveChatStartCustomer(
    socket: SubscribeActiveChatStartCustomer["socket"],
    props: SubscribeActiveChatStartCustomer["props"],
  ) {
    const topic = `/chat/sala/1/company/${props.companyId}/customer/${props.customerId}/contact/${props.contactId || ""}`;
    socket.removeAllListeners(topic);
  }

  subscribeChatStartCustomer(
    socket: SubscribeChatStartCustomer["socket"],
    { companyId, customerId, contactId }: SubscribeChatStartCustomer["props"],
    callBack: SubscribeChatStartCustomer["callBack"],
    once?: SubscribeChatStartCustomer["once"],
  ) {
    const topic = `/chatStart/response/company/${companyId}/customer/${customerId}/contact/${contactId || ""}`;
    const formatData = (data: {
      message: string;
      productId: string;
      session_id: string;
      productName: string;
      hasError?: boolean;
    }) => {
      callBack({
        message: data.message,
        productId: parseInt(data.productId),
        sessionId: data.session_id,
        productDescription: data.productName,
        hasError: !!data.hasError,
      });
    };

    if (once) {
      socket.once(topic, formatData);
    } else {
      socket.on(topic, formatData);
    }
  }

  unsubscribeChatStartCustomer(
    socket: SubscribeChatStartCustomer["socket"],
    { companyId, customerId, contactId }: SubscribeChatStartCustomer["props"],
  ) {
    const topic = `/chatStart/response/company/${companyId}/customer/${customerId}/contact/${contactId || ""}`;
    socket.removeAllListeners(topic);
  }

  subscribeQueueChangesCustomer(
    socket: SubscribeQueueChangesCustomer["socket"],
    props: SubscribeQueueChangesCustomer["props"],
    callBack: SubscribeQueueChangesCustomer["callBack"],
    once?: SubscribeQueueChangesCustomer["once"],
  ) {
    const topic = `/chat/queue/${props.company.id}-1-${props.department.productId}`;

    const formatData = (data: any) => {
      const userInQueue = data.queue.find(
        (item: { session_id: string }) => item.session_id == props.sessionId,
      );
      callBack({ queuePosition: userInQueue?.position || 0 });
    };

    if (once) {
      socket.once(topic, formatData);
    } else {
      socket.on(topic, formatData);
    }
  }

  unsubscribeQueueChangesCustomer(
    socket: UnsubscribeQueueChangesCustomer["socket"],
    props: UnsubscribeQueueChangesCustomer["props"],
  ) {
    const topic = `/chat/queue/${props.company.id}-1-${props.department.productId}`;

    socket.removeListener(topic);
  }

  subscribeAttendanceStartCustomer(
    socket: SubscribeAttendanceStartCustomer["socket"],
    props: SubscribeAttendanceStartCustomer["props"],
    callBack: SubscribeAttendanceStartCustomer["callBack"],
    once?: SubscribeAttendanceStartCustomer["once"],
  ) {
    const topic = `/chat/sala/1/${props.sessionId}/attendance/start/response`;

    if (once) {
      socket.once(topic, callBack);
    } else {
      socket.on(topic, callBack);
    }
  }

  emitFinishAttendanceCustomer(
    socket: EmitFinishAttendanceCustomer["socket"],
    {
      attendantCode,
      companyAlias,
      companyId,
      sessionId,
    }: EmitFinishAttendanceCustomer["props"],
  ) {
    const topic = "/chat/quit";

    const payload = {
      action: "removeQueue",
      attendantCode: attendantCode,
      classification: "",
      company_alias: companyAlias,
      company_code: companyId,
      emitter: "client",
      identifiers: {
        sessionId: sessionId,
        customerSocketClientId: socket.id,
      },
      isAttendantCallingClient: null,
      quitForInactivity: null,
      room_code: 1,
      sessionId: sessionId,
      subject: "",
      type: "",
    };

    socket.emit(topic, payload);
  }

  async rateAttendance(
    api: RateAttendance["api"],
    token: GetToken,
    companyCnpj: CompanyCnpj,
    props: RateAttendance["props"],
  ) {
    await api.makeRequest(token, {
      path: "rate",
      method: "POST",
      body: {
        ...props,
      },
      headers: {
        "qyon-customer-cnpj": companyCnpj,
        "customer-cnpj": companyCnpj,
      },
    });
  }

  async getAttendanceStatus(
    api: GetAttendanceStatus["api"],
    props: GetAttendanceStatus["payload"],
  ) {
    const response = await api.makeRequest(null, {
      path: "attendance/stage/" + props.sessionId,
    });

    const productId = response.response.product_code;
    const stage = response.response.info_stage.stage;
    const queueData = response.response.info_stage.queue
      ? {
          queueId: response.response.info_stage.queue.queue_id,
          position: response.response.info_stage.queue.position,
        }
      : null;

    return {
      productId,
      stage,
      queueData,
    };
  }
}
