import { SVGProps } from "react"

export const MessageSelectDepartmentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M7.5 8a1 1 0 0 0 0 2h9a1 1 0 1 0 0-2h-9ZM6.5 13a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2h-9a1 1 0 0 1-1-1ZM7.5 16a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M1.5 12v10a1 1 0 0 0 1 1h10c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11Zm2 0a9 9 0 1 1 9 9h-9v-9Z"
      clipRule="evenodd"
    />
  </svg>
)
