import { atom, useAtom } from "jotai";
import { SetStoreValueAndFunction } from "../../domain/store";

type AlertType = "error" | "success" | "warning" | null;

type StoreValues = {
  type: AlertType;
  message: string | null;
  title: string | null;
};

const initialStoreValues: StoreValues = {
  type: null,
  message: null,
  title: null,
};

const typeAtom = atom<StoreValues["type"]>(initialStoreValues.type);
const messageAtom = atom<StoreValues["message"]>(initialStoreValues.message);
const titleAtom = atom<StoreValues["title"]>(initialStoreValues.title);

const setTypeAtom = atom(
  null,
  (_, set, value: SetStoreValueAndFunction<StoreValues["type"]>) => {
    set(typeAtom, value);
  },
);
const setMessageAtom = atom(
  null,
  (_, set, value: SetStoreValueAndFunction<StoreValues["message"]>) => {
    set(messageAtom, value);
  },
);
const setTitleAtom = atom(
  null,
  (_, set, value: SetStoreValueAndFunction<StoreValues["title"]>) => {
    set(titleAtom, value);
  },
);

export const useAlert = () => {
  const [type] = useAtom(typeAtom);
  const [message] = useAtom(messageAtom);
  const [title] = useAtom(titleAtom);

  const setType = useAtom(setTypeAtom)[1];
  const setMessage = useAtom(setMessageAtom)[1];
  const setTitle = useAtom(setTitleAtom)[1];

  return {
    type,
    message,
    title,
    setType,
    setMessage,
    setTitle,
  };
};
