import { BackIcon } from "@/assets/icons/back"
import { Label } from "@/components/ui/label"
import { useAttendance } from "@/modules/chat/application/store/attendance.store"

export function ConversationHeader() {

  const { currentConversation, setCurrentConversation, setCurrentFinishedAttendance, currentFinishedAttendance } = useAttendance()
  if (!currentConversation && !currentFinishedAttendance) return false

  const handleBackToList = () => {
    setCurrentConversation(null)
    setCurrentFinishedAttendance(null)
  }

  return (
    <div className='--ct-h-14 --ct-px-2 --ct-font-bold --ct-bg-primary --ct-text-white --ct-text-sm --ct-py-3'>
      <div className='--ct-flex --ct-items-center --ct-justify-center --ct-gap-4'>
        <Label className='--ct-truncate'>
          {
            currentConversation?.name || currentFinishedAttendance?.username
          }
        </Label>
        <div className='--ct-cursor-pointer' onClick={handleBackToList}>
          <BackIcon />
        </div>
      </div>
      <div className='--ct-flex --ct-items-center --ct-justify-center --ct-gap-4'>
        <Label className='--ct-truncate --ct-text-xs'>
          {
            currentConversation?.company.name
          }
        </Label>
      </div>
    </div>
  )
}
