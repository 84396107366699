import { Label } from "@/components/ui/label";

export function QueueListEmpty() {
  return (
    <div className='--ct-flex-1 --ct-w-full --ct-grid --ct-place-items-center'>
      <Label>
        Não há usuários na fila.
      </Label>
    </div>
  )
}
