import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/utils/utils"

const labelVariants = cva(
  "--ct-text-sm --ct-leading-none peer-disabled:--ct-cursor-not-allowed peer-disabled:--ct-opacity-70"
)

const Label = ({ className, ...props }: React.HtmlHTMLAttributes<HTMLLabelElement>) => (
  <label
    className={cn(labelVariants(), className)}
    {...props}
  />
)

export { Label }
