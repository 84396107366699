import { Avatar } from "@/components/ui/avatar"
import { Label } from "@/components/ui/label"
import { useSettings } from "@/modules/chat/application/store/settings.store"
import { Attendant } from "@/modules/chat/domain/attendant"
import { cn } from "@/utils/utils"

type OperatorRowProps = {
  attendant: Attendant
}

export function OperatorRow({ attendant }: OperatorRowProps) {

  const { allStatus } = useSettings()

  const avataFallBack = attendant.attendant.name.split(' ').map(name => name[0]).join('')

  const currentStatus = allStatus.find(status => status.id == attendant.statusAttendance)

  return (
    <div className='--ct-flex --ct-items-center --ct-gap-4'>
      <Avatar.Container className='h-[50px] w-[50px]'>
        <Avatar.Fallback>{avataFallBack.substring(0, 2)}</Avatar.Fallback>
      </Avatar.Container>
      <div className='--ct-flex-1 --ct-flex --ct-flex-col --ct-text-xs --ct-truncate --ct-justify-center --ct-pb-2 --ct-space-y-1'>
        <Label className='--ct-text-sm --ct-leading-8'>
          {
            attendant.attendant.name
          }
        </Label>
        <Label className='--ct-text-muted-foreground --ct-text-xs'>
          {
            currentStatus?.description || "Disponível"
          }
        </Label>
      </div>
      <div className='--ct-h-full --ct-grid --ct-place-items-center'>
        <span className={
          cn(
            '--ct-h-6 --ct-w-6 --ct-grid --ct-text-white --ct-place-items-center --ct-rounded-full --ct-text-xs --ct-font-bold',
            attendant.qtdAttendances > 0 ? '--ct-bg-primary ' : '--ct-bg-input'
          )
        }>
          {
            attendant.qtdAttendances
          }
        </span>
      </div>
    </div>
  )
}
